import { ObjectToDraw } from "./types";

class PreviewImageCache{
    static cache: { [key: string]: ObjectToDraw } = {};
    static cacheQueue: { [key: string]: boolean } = {};

    constructor(){
        
    }

    static loadImage(path: string, id: string){
        let dataPath = `${staticServer}${path}.json`;
        let imagePath = `${staticServer}${path}.png`;

        p5js.loadJSON(dataPath, (data) => {
            console.log(`Data for ${dataPath} loaded!`);

            p5js.loadImage(imagePath, (img) => {
                console.log(`Image for ${imagePath} loaded!`);

                let cachedData : ObjectToDraw = {
                    x : data.centerX,
                    y : data.centerY,
                    z : 0,
                    image: img
                };
    
                PreviewImageCache.cache[id] = cachedData;
            }, () => {
                // Error callback for loadImage
                console.error(`Failed to load image for ${imagePath}`);
            });
        }, () => {
            // Error callback for loadJSON
            console.error(`Failed to load JSON for ${dataPath}`);
        });
    }

    static getImageData(name: string): ObjectToDraw | null{
        if(PreviewImageCache.cache[name]){
            return PreviewImageCache.cache[name];
        }
        return null;
    }

    static imageExists(name: string): boolean{
        return !!PreviewImageCache.cache[name];
    }

    static renter(objectsToDraw: ObjectToDraw[], offsetX: number, offsetY: number, id: string){
        let cache = PreviewImageCache.cache[id];
        if (!cache) {
            return;
        }

        let objX = offsetX - cache.x;
        let objY = offsetY - cache.y;
        let objZ = offsetY + cache.z;
        objectsToDraw.push({ image: cache.image, x: objX, y: objY, z: objZ });
    }
}

export default PreviewImageCache;