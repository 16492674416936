import Chunk from "../game/chunk";
import GameMap from "../game/gameMap";
import { saveJSON } from "../helpers";
import MapEditorScreen from "../mapEditorScreen";
import { WallData } from "../types";
import Button from "../ui/button";
import DropDownList from "../ui/dropDownList";
import HorizontalSeparator from "../ui/horizontalSeparator";
import InfiniteSlider from "../ui/infiniteSlider";
import Label from "../ui/label";
import UIElement from "../ui/uiElement";
import VerticalView from "../ui/verticalView";

class WallTool implements UIElement {
    x: number = 0;
    y: number = 0;
    w: number = 80;
    h: number = 20;
    action: Function;

    selectedWall: WallData | null = null;
    newWall: WallData | null = null;
    selectedChunk: Chunk | null = null;
    
    // ------ UI ------
    ui: VerticalView = new VerticalView(0, 0, 10, 10);

    selectToolBtn = new Button(28, 28, "Select Wall");
    addWallBtn = new Button(28, 28, "Add Wall");
    removeWallBtn = new Button(28, 28, "Remove Wall");
    saveChunkBtn = new Button(28, 28, "Save Chunk");
    
    // ------ Properties ------
    properties: VerticalView = new VerticalView(0, 0, 10, 10);

    wallPosX = new InfiniteSlider("pos. X", 90);
    wallPosY = new InfiniteSlider("pos. Y", 90);
    wallWidth = new InfiniteSlider("width", 90);
    wallHeight = new InfiniteSlider("height", 90);
    drp_wallType = new DropDownList(90, ["wall", "portal", "damage", "trigger"], "Wall Type");

    // ------ Wall Data Options -------
    defaultWallView = new VerticalView(0, 0, 90, 100);
    
    wallDamageView = new VerticalView(0, 0, 90, 100);
    wallDamageSlider = new InfiniteSlider("damage", 90);
    
    wallPortalView = new VerticalView(0, 0, 90, 100);
    portalTargetX = new InfiniteSlider("pos. X", 90);
    portalTargetY = new InfiniteSlider("pos. Y", 90);
    portalWorldDrop = new DropDownList(90, ["world1", "world2", "world3"], "Teleport to:");
    portalAsk = new DropDownList(90, ["Ask", "Don't ask"], "Ask before teleport");
    
    wallTriggerView = new VerticalView(0, 0, 90, 100);
    triggerAction = new DropDownList(90, ["action1", "action2", "action3"], "Trigger Action:");
    triggerAsk = new DropDownList(90, ["Ask", "Don't Ask"], "Ask before trigger");
    
    selectedWallView: VerticalView = this.defaultWallView;

    constructor(private gameMap : GameMap, private editor: MapEditorScreen) {
        // ------ UI ------
        this.ui.horizontalAlignment = "LEFT";

        this.selectToolBtn.setImage("/assets/ui/editor/select");
        this.addWallBtn.setImage("/assets/ui/editor/add");
        this.removeWallBtn.setImage("/assets/ui/editor/delete");
        this.saveChunkBtn.setImage("/assets/ui/editor/save");

        this.selectToolBtn.activated = true;

        this.selectToolBtn.action = () => {
            console.log("Select Tool");
            this.selectToolBtn.activated = true;
            this.addWallBtn.activated = false;
            this.removeWallBtn.activated = false;
        }
        this.ui.addElement(this.selectToolBtn);
        
        this.addWallBtn.action = () => {
            console.log("Add Wall");
            this.selectToolBtn.activated = false;
            this.addWallBtn.activated = true;
            this.removeWallBtn.activated = false;
        }
        this.ui.addElement(this.addWallBtn);
        
        this.removeWallBtn.action = () => {
            console.log("Remove Wall");
            this.selectToolBtn.activated = false;
            this.addWallBtn.activated = false;
            this.removeWallBtn.activated = true;
            if(this.selectedWall && this.selectedChunk){
                this.selectedChunk.data.walls = this.selectedChunk.data.walls.filter(w => w !== this.selectedWall);
                this.saveChunk(this.selectedChunk);
                this.selectedWall = null;
                this.selectedChunk = null;
                this.properties.show = false;
                this.selectedWallView.show = false;
                console.log("Wall Removed");
            }
        }
        this.ui.addElement(this.removeWallBtn);

        this.saveChunkBtn.action = () => {
            if(this.selectedChunk){
                this.saveChunk(this.selectedChunk);
            }
        }
        this.ui.addElement(this.saveChunkBtn);

        // ------ Properties ------

        this.properties.horizontalAlignment = "LEFT";
        this.defaultWallView.horizontalAlignment = "LEFT";
        this.wallDamageView.horizontalAlignment = "LEFT";
        this.wallPortalView.horizontalAlignment = "LEFT";
        this.wallTriggerView.horizontalAlignment = "LEFT";

        this.properties.show = false;
        this.properties.addElement(new Label("Properties:"));

        this.wallPosX.action = () => {
            if(this.selectedWall){
                this.selectedWall.x = this.wallPosX.value;
                console.log("Wall X : ", this.selectedWall.x);
            }
        }
        this.properties.addElement(this.wallPosX);

        this.wallPosY.action = () => {
            if(this.selectedWall){
                this.selectedWall.y = this.wallPosY.value;
            }
        }
        this.properties.addElement(this.wallPosY);

        this.wallWidth.action = () => {
            if(this.selectedWall){
                this.selectedWall.w = this.wallWidth.value;
            }
        }
        this.properties.addElement(this.wallWidth);

        this.wallHeight.action = () => {
            if(this.selectedWall){
                this.selectedWall.h = this.wallHeight.value;
            }
        }
        this.properties.addElement(this.wallHeight);


        let wallTypeLabel = new Label("Wall Type:");
        wallTypeLabel.textSize = 10;
        this.properties.addElement(wallTypeLabel);

        this.drp_wallType.action = () => {
            if(this.selectedWall){
                this.selectedWall.type = this.drp_wallType.selectedOption;
                this.updatePropeeies();
                this.selectedWallView.show = true;
            }
        }
        this.properties.addElement(this.drp_wallType);

        // this.properties.addElement(new HorizontalSeparator(90)); // --------------------------------------------
        // this.defaultWallView.addElement(new Label("Wall Data:"));
        this.defaultWallView.addElement(new Label("Wall Data:"));
        this.defaultWallView.addElement(new HorizontalSeparator(90));
        let defaultWallLabel = new Label("No data available");
        defaultWallLabel.textSize = 10;
        this.defaultWallView.addElement(defaultWallLabel);

        this.selectedWallView = this.defaultWallView;
        this.selectedWallView.show = false;
        // this.properties.addElement(this.selectedWallView);
        
        // ------ Damage Data ------
        this.wallDamageView.addElement(new Label("Damage Data:"));
        this.wallDamageView.addElement(new HorizontalSeparator(90));
        let damageSlider = new InfiniteSlider("damage", 90);
        damageSlider.action = () => {
            if(this.selectedWall){
                this.selectedWall.damageData = {
                    damage: damageSlider.value
                }
            }
        }
        this.wallDamageView.addElement(damageSlider);

        // ------ Portal Data ------
        this.wallPortalView.addElement(new Label("Portal Data:"));
        this.wallPortalView.addElement(new HorizontalSeparator(90));
        let portalWorldLabel = new Label("Target World:");
        portalWorldLabel.textSize = 10;
        this.wallPortalView.addElement(portalWorldLabel);
        this.portalWorldDrop.action = () => {
            if(this.selectedWall){
                if(!this.selectedWall.portalData){
                    if(this.selectedWall.portalData){
                        this.selectedWall.portalData.world = this.portalWorldDrop.selectedOption;
                    }else{
                        this.selectedWall.portalData = {
                            world: this.portalWorldDrop.selectedOption,
                            tx: 0,
                            ty: 0,
                            ask: false
                        }
                    }
                }
            }
        }
        this.wallPortalView.addElement(this.portalWorldDrop);

        this.portalTargetX.action = () => {
            if(this.selectedWall){
                if(!this.selectedWall.portalData){
                    this.selectedWall.portalData = {
                        world: "world1",
                        tx: 0,
                        ty: 0,
                        ask: false
                    }
                }
                this.selectedWall.portalData.tx = this.portalTargetX.value;
            }
        }
        this.wallPortalView.addElement(this.portalTargetX);

        this.portalTargetY.action = () => {
            if(this.selectedWall){
                if(!this.selectedWall.portalData){
                    this.selectedWall.portalData = {
                        world: "world1",
                        tx: 0,
                        ty: 0,
                        ask: false
                    }
                }
                this.selectedWall.portalData.ty = this.portalTargetY.value;
            }
        }
        this.wallPortalView.addElement(this.portalTargetY);

        let portalAskLabel = new Label("Ask before teleport:");
        portalAskLabel.textSize = 10;
        this.wallPortalView.addElement(portalAskLabel);
        this.portalAsk.action = () => {
            if(this.selectedWall){
                if(!this.selectedWall.portalData){
                    this.selectedWall.portalData = {
                        world: "world1",
                        tx: 0,
                        ty: 0,
                        ask: false
                    }
                }
                this.selectedWall.portalData.ask = this.portalAsk.selectedOption === "true";
            }
        }
        this.wallPortalView.addElement(this.portalAsk);

        // ------ Trigger Data ------
        this.wallTriggerView.addElement(new Label("Trigger Data:"));
        this.wallTriggerView.addElement(new HorizontalSeparator(90));
        let triggerActionLabel = new Label("Trigger Action:");
        triggerActionLabel.textSize = 10;
        this.wallTriggerView.addElement(triggerActionLabel);
        this.triggerAction.action = () => {
            if(this.selectedWall){
                if(!this.selectedWall.triggerData){
                    this.selectedWall.triggerData = {
                        action: this.triggerAction.selectedOption,
                        ask: false
                    }
                }
                this.selectedWall.triggerData.action = this.triggerAction.selectedOption;
            }
        }
        this.wallTriggerView.addElement(this.triggerAction);

        let triggerAskLabel = new Label("Ask before trigger:");
        triggerAskLabel.textSize = 10;
        this.wallTriggerView.addElement(triggerAskLabel);
        this.triggerAsk.action = () => {
            if(this.selectedWall){
                if(!this.selectedWall.triggerData){
                    this.selectedWall.triggerData = {
                        action: "action1",
                        ask: false
                    }
                }
                this.selectedWall.triggerData.ask = this.triggerAsk.selectedOption === "true";
            }
        }
        this.wallTriggerView.addElement(this.triggerAsk);
    }

    updatePropeeies(): void {
        if(this.selectedWall){
            this.wallPosX.value = this.selectedWall.x;
            this.wallPosY.value = this.selectedWall.y;
            this.wallWidth.value = this.selectedWall.w;
            this.wallHeight.value = this.selectedWall.h;
            if(this.selectedWall.type !== "" && this.selectedWall.type){
                this.drp_wallType.selectedOption = this.selectedWall.type;
            }else{
                this.selectedWall.type = "No type";
            }

            if(this.selectedWall.type === "portal"){
                this.selectedWallView = this.wallPortalView;
                console.log("Wall proporties view changed to Portal");
            }else if(this.selectedWall.type === "damage"){
                this.selectedWallView = this.wallDamageView;
                console.log("Wall proporties view changed to Damage");
            }else if(this.selectedWall.type === "trigger"){
                this.selectedWallView = this.wallTriggerView;
                console.log("Wall proporties view changed to Trigger");
            }else{
                this.selectedWallView = this.defaultWallView;
                console.log("Wall proporties view changed to Default");
            }
            this.updateLayout();
        }
    }

    updateLayout(): void {
        this.ui.x = -buffer.width/2;
        this.ui.y = -buffer.height/2+this.editor.topBarHeight;
        this.ui.w = 38;
        this.ui.h = buffer.height-this.editor.topBarHeight;
        this.ui.updateLayout();

        this.properties.x = buffer.width/2 - 100;
        this.properties.y = -buffer.height/2+this.editor.topBarHeight;
        this.properties.w = 100;
        this.properties.h = 220;
        this.properties.updateLayout();

        if(this.selectedWallView){
            this.selectedWallView.x = buffer.width/2 - 100;
            this.selectedWallView.y = this.properties.y + this.properties.h;
            this.selectedWallView.w = 100;
            this.selectedWallView.h = buffer.height-this.editor.topBarHeight-200;
            this.selectedWallView.updateLayout();
        }
    }

    render() {
        // position in game coordinates
        let mPos = this.editor.getMousePosInGame(); 

        //render currently created wall
        if(this.newWall && this.selectedChunk){
            let wallW = Math.floor(mPos.x - this.selectedChunk.tileX * Chunk.CHUNK_SIZE) - this.newWall.x;
            let wallH = Math.floor(mPos.y - this.selectedChunk.tileY * Chunk.CHUNK_SIZE) - this.newWall.y;

            this.newWall.w = wallW;
            this.newWall.h = wallH;

            if(this.editor.snapToGrid){
                this.newWall.w = Math.round(wallW/16) * 16;
                this.newWall.h = Math.round(wallH/16) * 16;
            }

            let val = 128+(p5js.sin(p5js.frameCount/10)+1)*64;
            buffer.stroke(255, 0, 255, val);
            buffer.strokeWeight(2);
            buffer.fill(255, 0, 255, val/3);

            let chunkOnScreenX = (this.selectedChunk.tileX * Chunk.CHUNK_SIZE * this.editor.zoomLevel) - this.gameMap.posX * this.editor.zoomLevel;
            let chunkOnScreenY = (this.selectedChunk.tileY * Chunk.CHUNK_SIZE * this.editor.zoomLevel) - this.gameMap.posY * this.editor.zoomLevel;
            buffer.rect(
                chunkOnScreenX + this.newWall.x * this.editor.zoomLevel,
                chunkOnScreenY + this.newWall.y * this.editor.zoomLevel,
                this.newWall.w * this.editor.zoomLevel,
                this.newWall.h* this.editor.zoomLevel
            );
        }

        //show selected wall
        if(this.selectedWall && this.selectedChunk){
            let val = 128+(p5js.sin(p5js.frameCount/10)+1)*64;
            buffer.stroke(255, 255, 255, val);
            buffer.strokeWeight(2);
            buffer.fill(255, 255, 255, val/3);

            let chunkOnScreenX = (this.selectedChunk.tileX * Chunk.CHUNK_SIZE * this.editor.zoomLevel) - this.gameMap.posX * this.editor.zoomLevel;
            let chunkOnScreenY = (this.selectedChunk.tileY * Chunk.CHUNK_SIZE * this.editor.zoomLevel) - this.gameMap.posY * this.editor.zoomLevel;
            buffer.rect(
                chunkOnScreenX + this.selectedWall.x * this.editor.zoomLevel,
                chunkOnScreenY + this.selectedWall.y * this.editor.zoomLevel,
                this.selectedWall.w * this.editor.zoomLevel,
                this.selectedWall.h * this.editor.zoomLevel
            );
        }

        //render new wall preview
        if(this.addWallBtn.activated && !this.newWall){
            //get mouse position in screen coordinates

            let screenPos = this.editor.getGamePosOnScreen(mPos.x, mPos.y);
            if(this.editor.snapToGrid){
                screenPos = this.editor.getGamePosOnScreen(Math.round(mPos.x/16) * 16, Math.round(mPos.y/16) * 16);
            }
            
            buffer.stroke(255, 0, 0);
            buffer.strokeWeight(2);
            buffer.fill(255, 0, 0, 50);
            buffer.rect(screenPos.x, screenPos.y, 16, 16);
        }

        if(this.selectedChunk){
            let cx = this.selectedChunk.tileX * Chunk.CHUNK_SIZE;
            let cy = this.selectedChunk.tileY * Chunk.CHUNK_SIZE;
            let screenPos = this.editor.getGamePosOnScreen(cx, cy);

            buffer.stroke(255, 0, 0);
            buffer.strokeWeight(1);
            buffer.noFill();
            buffer.rect(screenPos.x, screenPos.y, Chunk.CHUNK_SIZE*this.editor.zoomLevel, Chunk.CHUNK_SIZE*this.editor.zoomLevel);
        }

        this.ui.render();
        this.properties.render();
        this.selectedWallView?.render();
    }

    renderTooltip(): boolean {
        this.ui.renderTooltip();
        this.properties.renderTooltip();
        this.selectedWallView?.renderTooltip();
        return false;
    }

    fixWall(wall: WallData): WallData {
        // Fix negative width
        if (wall.w < 0) {
            wall.x += wall.w; // Shift x by the width
            wall.w = -wall.w; // Make width positive
        }
    
        // Fix negative height
        if (wall.h < 0) {
            wall.y += wall.h; // Shift y by the height
            wall.h = -wall.h; // Make height positive
        }

        return wall;
    }

    saveChunk(chunk: Chunk){
        let path = `/assets/worlds/${this.gameMap.worldName}/chunk_${chunk.tileX}_${chunk.tileY}.json`;
        console.log("Saving Chunk : ", path);
        saveJSON(chunk.data, path);
    }

    mousePressed(): boolean {
        if(this.ui.mousePressed()){
            return true;
        }
        if(this.properties.mousePressed()){
            return true;
        }
        if(this.selectedWallView?.mousePressed()){
            return true;
        }

        if(p5js.mouseButton === p5js.LEFT){
            //get mouse position in chunk coordinates
            let mx = this.gameMap.posX + ((p5js.mouseX/pixelSize) - buffer.width/2) / this.editor.zoomLevel;
            let my = this.gameMap.posY + ((p5js.mouseY/pixelSize) - buffer.height/2) / this.editor.zoomLevel;
            let chunkX = Math.floor(mx / Chunk.CHUNK_SIZE);
            let chunkY = Math.floor(my / Chunk.CHUNK_SIZE);
            // let chunkOnScreenX = (chunkX * Chunk.CHUNK_SIZE * this.editor.zoomLevel) - this.gameMap.posX * this.editor.zoomLevel;
            // let chunkOnScreenY = (chunkY * Chunk.CHUNK_SIZE * this.editor.zoomLevel) - this.gameMap.posY * this.editor.zoomGoal;
            // console.log("Chunk Clicked : ", chunkX, chunkY);

            let chunk = this.gameMap.getChunk(chunkX, chunkY);
            if(chunk){
                // console.log("Selecting Wall");
                // console.log("Mouse : ", mx, my);
                //console.log("Position : ", chunkOnScreenX, chunkOnScreenY);
                if(this.selectToolBtn.activated || this.removeWallBtn.activated){
                    for(let wall of chunk.data.walls){
                        //console.log("Wall : ", wall);
                        //posX + wall.x * scale, posY + wall.y * scale, wall.w * scale, wall.h * scale
                        
                        let wallX = chunkX * Chunk.CHUNK_SIZE + wall.x;
                        let wallY = chunkY * Chunk.CHUNK_SIZE + wall.y;
                        let wallW = wall.w;
                        let wallH = wall.h;
                        // console.log("Wall : ", wallX, wallY, wallW, wallH);
                        if(mx > wallX && mx < wallX + wallW && my > wallY && my < wallY + wallH){
                            if(this.selectToolBtn.activated){
                                this.selectedWall = wall;
                                this.selectedChunk = chunk;
                                this.updatePropeeies();
                                this.properties.show = true;
                                this.selectedWallView.show = true;
                                console.log("Wall Selected");
                                return true;
                            }
                            
                            if(this.removeWallBtn.activated){
                                chunk.data.walls = chunk.data.walls.filter(w => w !== wall);
                                this.saveChunk(chunk);
                                this.selectedWall = null;
                                this.selectedChunk = null;
                                this.properties.show = false;
                                this.selectedWallView.show = false;
                                console.log("Wall Removed");
                                return true;
                            }
                        }
                    }
                    this.selectedWall = null;
                    this.selectedChunk = null;
                    this.properties.show = false;
                    this.selectedWallView.show = false;
                }   

                if(this.addWallBtn.activated){
                    let wallX = Math.floor(mx - chunkX * Chunk.CHUNK_SIZE);
                    let wallY = Math.floor(my - chunkY * Chunk.CHUNK_SIZE);
                    if(this.editor.snapToGrid){
                        wallX = Math.round(Math.floor(mx - chunkX * Chunk.CHUNK_SIZE)/16) * 16;
                        wallY = Math.round(Math.floor(my - chunkY * Chunk.CHUNK_SIZE)/16) * 16;
                    }
                    this.newWall = {
                        x: wallX,
                        y: wallY,
                        w: 16,
                        h: 16,
                        type: "wall",
                        portalData: null,
                        damageData: null,
                        triggerData: null
                    }
                    
                    //chunk.data.walls.push(this.newWall);
                    this.selectedChunk = chunk;
                    console.log("Wall Added");
                    return true;
                }

            }
        }

        return false;
    }

    mouseReleased(): boolean {
        
        if(this.newWall && this.selectedChunk){
            
            //round widht and height to 16
            if(this.editor.snapToGrid){
                this.newWall.w = Math.round(this.newWall.w / 16) * 16;
                this.newWall.h = Math.round(this.newWall.h / 16) * 16;
            }else{
                this.newWall.w = Math.round(this.newWall.w);
                this.newWall.h = Math.round(this.newWall.h);
            }
            
            this.newWall = this.fixWall(this.newWall);
            if(this.newWall.w > 0 && this.newWall.h > 0){
                this.selectedChunk.data.walls.push(this.newWall);
                this.selectedWall = this.newWall;
                this.properties.show = true;
                this.selectedWallView.show = true;
                this.updatePropeeies();
                this.newWall = null;
                this.saveChunk(this.selectedChunk);
            }
            this.newWall = null;
        }

        if(this.ui.mouseReleased()){
            return true;
        }

        if(this.properties.mouseReleased()){
            return true;
        }

        if(this.selectedWallView?.mouseReleased()){
            return true;
        }

        return false;
    }

    keyPressed(): boolean {

        if(p5js.keyCode === p5js.DELETE){
            if(this.selectedWall && this.selectedChunk){
                this.selectedChunk.data.walls = this.selectedChunk.data.walls.filter(w => w !== this.selectedWall);
                this.saveChunk(this.selectedChunk);
                this.selectedWall = null;
                this.selectedChunk = null;
                console.log("Wall Removed");
            }
        }

        if(this.ui.keyPressed()){
            return true;
        }

        if(this.properties.keyPressed()){
            return true;
        }

        if(this.selectedWallView?.keyPressed()){
            return true;
        }

        return false;
    }

    keyReleased(): boolean {
        if(this.ui.keyReleased()){
            return true;
        }

        if(this.properties.keyReleased()){
            return true;
        }
        
        if(this.selectedWallView?.keyReleased()){
            return true;
        }

        return false;
    }

    scroll(event: WheelEvent): boolean {
        if(this.ui.scroll(event)){
            return true;
        }

        if(this.properties.scroll(event)){
            return true;
        }

        if(this.selectedWallView?.scroll(event)){
            return true;
        }

        return false;
    }
}

export default WallTool;