class PathNode {

    explored: boolean = false;

    constructor(
        public x: number, 
        public y: number
    ) {}
}

export default PathNode;