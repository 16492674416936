import Chunk from "../game/chunk";
import GameMap from "../game/gameMap";
import GameObject from "../game/gameObject";
import { saveJSON } from "../helpers";
import MapEditorScreen from "../mapEditorScreen";
import { ObjectData, WallData } from "../types";
import Button from "../ui/button";
import InfiniteSlider from "../ui/infiniteSlider";
import Label from "../ui/label";
import ScrollGridView from "../ui/scrollGridView";
import TextInput from "../ui/textInput";
import UIElement from "../ui/uiElement";
import VerticalView from "../ui/verticalView";

class ObjectTool implements UIElement {
    x: number = 0;
    y: number = 0;
    w: number = 80;
    h: number = 20;
    action: Function;

    selectedChunk: Chunk | null = null;
    selectedObject: GameObject | null = null;
    objectToAdd: GameObject | null = null;
    
    // ------ UI ------
    ui: VerticalView = new VerticalView(0, 0, 10, 10);

    selectObjectBtn = new Button(28, 28, "Select Object");
    moveObjectBtn = new Button(28, 28, "Move Object");
    addObjectTool = new Button(28, 28, "Add Object");
    removeObjectBtn = new Button(28, 28, "Delete Object");
    saveChunkBtn = new Button(28, 28, "Save Chunk");
    
    // ------ Properties ------
    properties: VerticalView = new VerticalView(0, 0, 10, 10);
    te_objectName: TextInput = new TextInput(90, 20, "no value", "Object Name");
    sl_objectPosX: InfiniteSlider = new InfiniteSlider("Pos X", 90);
    sl_objectPosY: InfiniteSlider = new InfiniteSlider("Pos Y", 90);
    te_objectWidth: TextInput = new TextInput(90, 20, "no value", "Width");
    te_objectHeight: TextInput = new TextInput(90, 20, "no value", "Height");

    // ------ Add Object Folder ------
    objectFolder: ScrollGridView = new ScrollGridView(0, 0, 10, 10);

    constructor(private gameMap : GameMap, private editor: MapEditorScreen) {
        // ------ UI ------
        this.ui.horizontalAlignment = "LEFT";

        this.selectObjectBtn.setImage("/assets/ui/editor/select");
        this.moveObjectBtn.setImage("/assets/ui/editor/move");
        this.addObjectTool.setImage("/assets/ui/editor/add");
        this.removeObjectBtn.setImage("/assets/ui/editor/delete");
        this.saveChunkBtn.setImage("/assets/ui/editor/save");

        this.selectObjectBtn.activated = true;

        this.selectObjectBtn.action = () => {
            console.log("Select Object");
            this.selectObjectBtn.activated = true;
            this.addObjectTool.activated = false;
            this.removeObjectBtn.activated = false;
            this.moveObjectBtn.activated = false;

            this.objectFolder.show = false;

            this.updateProperties();
        }
        this.ui.addElement(this.selectObjectBtn);

        this.moveObjectBtn.action = () => {
            console.log("Move Object");
            this.selectObjectBtn.activated = false;
            this.addObjectTool.activated = false;
            this.removeObjectBtn.activated = false;
            this.moveObjectBtn.activated = true;

            this.properties.show = false;
            this.objectFolder.show = false;
            this.selectedChunk = null;
            this.selectedObject = null;
        }
        this.ui.addElement(this.moveObjectBtn);
        
        this.addObjectTool.action = () => {
            console.log("Add Object");
            this.selectObjectBtn.activated = false;
            this.addObjectTool.activated = true;
            this.removeObjectBtn.activated = false;
            this.moveObjectBtn.activated = false;

            this.properties.show = false;
            this.objectFolder.show = true;

            this.selectedChunk = null;
            this.selectedObject = null;
        }
        this.ui.addElement(this.addObjectTool);
        
        this.removeObjectBtn.action = () => {
            console.log("Delete Object");
            this.selectObjectBtn.activated = false;
            this.addObjectTool.activated = false;
            this.removeObjectBtn.activated = true;
            this.moveObjectBtn.activated = false;

            this.properties.show = false;
            this.objectFolder.show = false;
            this.selectedChunk = null;
            this.selectedObject = null;
        }
        this.ui.addElement(this.removeObjectBtn);

        this.saveChunkBtn.action = () => {
            console.log("Save Chunk");
            if(this.selectedChunk){
                this.saveChunk(this.selectedChunk);
            }
        }
        this.ui.addElement(this.saveChunkBtn);
        

        // ------ Properties ------

        this.properties.horizontalAlignment = "LEFT";
        this.properties.show = false;

        this.properties.addElement(new Label("Selected Object:"));
        this.te_objectName.locked = true;
        this.properties.addElement(this.te_objectName);

        this.sl_objectPosX.action = () => {
            if(this.selectedObject){
                this.selectedObject.data.x = this.sl_objectPosX.value;
            }
        }
        this.properties.addElement(this.sl_objectPosX);

        this.sl_objectPosY.action = () => {
            if(this.selectedObject){
                this.selectedObject.data.y = this.sl_objectPosY.value;
            }
        }
        this.properties.addElement(this.sl_objectPosY);

        this.properties.addElement(new Label("Width:"));
        this.te_objectWidth.locked = true;
        this.properties.addElement(this.te_objectWidth);

        this.properties.addElement(new Label("Height:"));
        this.te_objectHeight.locked = true;
        this.properties.addElement(this.te_objectHeight);
        

        // delete object button
        let deletObjectsButton = new Button(90, 20, "Delete Object");
        deletObjectsButton.action = () => {
            if(this.selectedChunk && this.selectedObject){
                let index = this.selectedChunk.objects.indexOf(this.selectedObject);
                if(index > -1){
                    this.selectedChunk.objects.splice(index, 1);
                    this.selectedChunk.data.objects.splice(index, 1);
                    this.selectedObject = null;
                    this.updateProperties();
                    this.saveChunk(this.selectedChunk);
                }
            }
        }
        this.properties.addElement(deletObjectsButton);

        // save wall button
        let saveWallButton = new Button(90, 20, "Save Chunk");
        saveWallButton.action = () => {
            if(this.selectedChunk){
                this.saveChunk(this.selectedChunk);
            }
        }
        this.properties.addElement(saveWallButton);

        // ------ Add Object Folder ------

        this.objectFolder.show = false;

        let objectList = ["brick_block", "grass", "info_sign", "plant_big", "plant_small", "portal", "rock", "spike1", "spike2", "tree1", "tree2", "tree3", "tree4", "tree5", "tree6", "tree7", "tree_glitched"];

        for(let i = 0; i < objectList.length; i++){
            let objectName = objectList[i];
            let objectData : ObjectData = {x: 0, y: 0, name: objectName};
            let object = new GameObject(objectData);
            let objectButton = new Button(40, 40, objectName);
            objectButton.setImage(`/assets/objects/${objectName}`);

            objectButton.action = () => {
                this.objectToAdd = object;
                //deactivate all buttons in the view
                for(let j = 0; j < this.objectFolder.elements.length; j++){
                    let button = this.objectFolder.elements[j] as Button;
                    button.activated = false;
                }
                objectButton.activated = true;
            }
            this.objectFolder.addElement(objectButton);
        }
    }

    updateProperties(){
        if(this.selectedObject){
            this.te_objectName.text = this.selectedObject.data.name;
            this.sl_objectPosX.value = this.selectedObject.data.x;
            this.sl_objectPosY.value = this.selectedObject.data.y;
            this.te_objectWidth.text = this.selectedObject.getAnimData().width.toString();
            this.te_objectHeight.text = this.selectedObject.getAnimData().height.toString();
            this.properties.show = true;
        }else{
            this.properties.show = false;
        }
    }

    updateLayout(): void {
        this.ui.x = -buffer.width/2;
        this.ui.y = -buffer.height/2+this.editor.topBarHeight;
        this.ui.w = 38;
        this.ui.h = buffer.height-this.editor.topBarHeight;
        this.ui.updateLayout();

        //right side panel
        this.properties.x = buffer.width/2 - 100;
        this.properties.y = -buffer.height/2+this.editor.topBarHeight;
        this.properties.w = 100;
        this.properties.h = buffer.height-this.editor.topBarHeight;
        this.properties.updateLayout();

        //right side panel
        this.objectFolder.x = buffer.width/2 - 185;
        this.objectFolder.y = -buffer.height/2+this.editor.topBarHeight;
        this.objectFolder.w = 185;
        this.objectFolder.h = buffer.height-this.editor.topBarHeight;
        this.objectFolder.updateLayout();
    }

    render() {
        let mx = this.gameMap.posX + ((p5js.mouseX/pixelSize) - buffer.width/2) / this.editor.zoomLevel;
        let my = this.gameMap.posY + ((p5js.mouseY/pixelSize) - buffer.height/2) / this.editor.zoomLevel;


        if(this.selectedChunk && this.selectedObject){
            buffer.stroke(255, 0, 255);
            buffer.strokeWeight(1);
            buffer.fill(255, 0, 255, 50);
            this.drawRectangleOnObject(this.selectedObject, this.selectedChunk);
        }

        //highlight all objects in the chunk
        if(this.selectObjectBtn.activated || this.removeObjectBtn.activated || this.moveObjectBtn.activated){
            let chunkX = Math.floor(mx / Chunk.CHUNK_SIZE);
            let chunkY = Math.floor(my / Chunk.CHUNK_SIZE);
            let chunk = this.gameMap.getChunk(chunkX, chunkY);
            if(chunk){
                let objects = chunk.objects;
                for(let i = 0; i < objects.length; i++){
                    let obj = objects[i];

                    if(this.selectedObject === obj){
                        continue;
                    }
                    buffer.stroke(255, 100);
                    buffer.strokeWeight(1);
                    buffer.noFill();
                    this.drawRectangleOnObject(obj, chunk);
                }
            }
        }

        if((this.addObjectTool.activated || this.moveObjectBtn.activated) && this.objectToAdd){
            //render preview of the object
            
            if(this.editor.snapToGrid){
                let pos = this.editor.getMousePosInGame();
                let posX = p5js.round(pos.x / 16) * 16;
                let posY = p5js.round(pos.y / 16) * 16;
                let screenPos = this.editor.getGamePosOnScreen(posX, posY);
                this.objectToAdd.renderPreview(screenPos.x, screenPos.y, this.editor.zoomLevel);
            }else{
                let posX = p5js.floor(p5js.mouseX/pixelSize - buffer.width/2);
                let posY = p5js.floor(p5js.mouseY/pixelSize - buffer.height/2);
                this.objectToAdd.renderPreview(posX, posY, this.editor.zoomLevel);
            }
            
        }

        if(this.selectedChunk){
            let cx = this.selectedChunk.tileX * Chunk.CHUNK_SIZE;
            let cy = this.selectedChunk.tileY * Chunk.CHUNK_SIZE;
            let screenPos = this.editor.getGamePosOnScreen(cx, cy);

            buffer.stroke(255, 0, 0);
            buffer.strokeWeight(1);
            buffer.noFill();
            buffer.rect(screenPos.x, screenPos.y, Chunk.CHUNK_SIZE*this.editor.zoomLevel, Chunk.CHUNK_SIZE*this.editor.zoomLevel);
        }

        this.ui.render();
        this.properties.render();
        this.objectFolder.render();
    }

    drawRectangleOnObject(obj: GameObject, chunk: Chunk){
        if(!chunk || !obj || !obj.getAnimData()){
            return;
        }
        let cx = chunk.tileX * Chunk.CHUNK_SIZE;
        let cy = chunk.tileY * Chunk.CHUNK_SIZE;
        let screenPos = this.editor.getGamePosOnScreen(cx, cy);
        let width = obj.getAnimData().width * this.editor.zoomLevel;
        let height = obj.getAnimData().height * this.editor.zoomLevel;
        let centerX = obj.getAnimData().centerX;
        let centerY = obj.getAnimData().centerY;
        let posX = screenPos.x + (obj.data.x - centerX) * this.editor.zoomLevel;
        let posY = screenPos.y + (obj.data.y - centerY) * this.editor.zoomLevel;

        buffer.rect( posX, posY, width, height);
    }


    renderTooltip(): boolean {
        this.ui.renderTooltip();
        this.properties.renderTooltip();
        this.objectFolder.renderTooltip();
        return false
    }

    fixWall(wall: WallData): WallData {
        // Fix negative width
        if (wall.w < 0) {
            wall.x += wall.w; // Shift x by the width
            wall.w = -wall.w; // Make width positive
        }
    
        // Fix negative height
        if (wall.h < 0) {
            wall.y += wall.h; // Shift y by the height
            wall.h = -wall.h; // Make height positive
        }

        return wall;
    }

    saveChunk(chunk: Chunk){
        let path = `/assets/worlds/${this.gameMap.worldName}/chunk_${chunk.tileX}_${chunk.tileY}.json`;
        chunk.isDefault = false;
        console.log("Saving Chunk : ", path);
        saveJSON(chunk.data, path);
    }

    mousePressed(): boolean {
        if(this.ui.mousePressed()){
            return true;
        }

        if(this.properties.mousePressed()){
            return true;
        }

        if(this.objectFolder.mousePressed()){
            return true;
        }

        if(p5js.mouseButton === p5js.LEFT){
            //get mouse position in chunk coordinates
            let mx = this.gameMap.posX + ((p5js.mouseX/pixelSize) - buffer.width/2) / this.editor.zoomLevel;
            let my = this.gameMap.posY + ((p5js.mouseY/pixelSize) - buffer.height/2) / this.editor.zoomLevel;
            let chunkX = Math.floor(mx / Chunk.CHUNK_SIZE);
            let chunkY = Math.floor(my / Chunk.CHUNK_SIZE);

            let chunk = this.gameMap.getChunk(chunkX, chunkY);
            
            if(chunk){
                let posOnTileX = p5js.floor(mx - chunkX * Chunk.CHUNK_SIZE);
                let posOnTileY = p5js.floor(my - chunkY * Chunk.CHUNK_SIZE);

                //select or delete object
                if(this.selectObjectBtn.activated || this.removeObjectBtn.activated || this.moveObjectBtn.activated){
                    let objects = chunk.objects;
                    for(let i = 0; i < objects.length; i++){
                        let obj = objects[i];

                        let width = obj.getAnimData().width;
                        let height = obj.getAnimData().height;
                        let centerX = obj.getAnimData().centerX;
                        let centerY = obj.getAnimData().centerY;
                        let posX = obj.data.x - centerX;
                        let posY = obj.data.y - centerY;

                        if(posOnTileX > posX && posOnTileX < posX + width && posOnTileY > posY && posOnTileY < posY + height){
                            if(this.removeObjectBtn.activated || this.moveObjectBtn.activated){
                                let index = chunk.objects.indexOf(obj);
                                if(index > -1){
                                    chunk.objects.splice(index, 1);
                                    chunk.data.objects.splice(index, 1);
                                    this.selectedObject = null;
                                    this.updateProperties();
                                    this.saveChunk(chunk);
                                    if(this.moveObjectBtn.activated){
                                        console.log("Move Object: ", obj);
                                        this.objectToAdd = obj;
                                    }
                                }
                                break;
                            }else if (this.selectObjectBtn.activated){
                                if(this.selectedObject === obj){
                                    continue;
                                }
                                this.selectedObject = obj;
                                this.selectedChunk = chunk;
                                this.updateProperties();
                                console.log("Selected Object: ", this.selectedObject);
                                break;
                            }
                        }
                    }
                }else if(this.addObjectTool.activated){
                    if(this.objectToAdd){
                        if(this.editor.snapToGrid){
                            posOnTileX = p5js.round(posOnTileX / 16) * 16;
                            posOnTileY = p5js.round(posOnTileY / 16) * 16;
                        }
                        let objectData : ObjectData = {x: posOnTileX, y: posOnTileY, name: this.objectToAdd.data.name};
                        let object = new GameObject(objectData);
                        chunk.objects.push(object);
                        chunk.data.objects.push(objectData);
                        this.saveChunk(chunk);
                    }
                }
            }

            //debug, select first object
            // if(this.selectedChunk && this.selectedChunk.objects.length > 0){
            //     this.selectedObject = this.selectedChunk.objects[0];
            //     this.updateProperties();
            //     console.log("Selected Object : ", this.selectedObject);
            // }

            //this.updateProperties();
            //console.log("Selected Chunk : ", this.selectedChunk);
        }

        return false;
    }

    mouseReleased(): boolean {
        //place down dragged object 
        if(this.moveObjectBtn.activated && this.objectToAdd){
            let mPos = this.editor.getMousePosInGame();
            let chunkX = Math.floor(mPos.x / Chunk.CHUNK_SIZE);
            let chunkY = Math.floor(mPos.y / Chunk.CHUNK_SIZE);
            let chunk = this.gameMap.getChunk(chunkX, chunkY);
            if(chunk){
                let posOnTileX = p5js.floor(mPos.x - chunkX * Chunk.CHUNK_SIZE);
                let posOnTileY = p5js.floor(mPos.y - chunkY * Chunk.CHUNK_SIZE);

                if(this.editor.snapToGrid){
                    posOnTileX = p5js.round(posOnTileX / 16) * 16;
                    posOnTileY = p5js.round(posOnTileY / 16) * 16;
                }
                this.objectToAdd.data.x = posOnTileX;
                this.objectToAdd.data.y = posOnTileY;

                chunk.objects.push(this.objectToAdd);
                chunk.data.objects.push(this.objectToAdd.data);

                this.selectedObject = this.objectToAdd;
                this.objectToAdd = null;
                
                this.updateProperties();
                this.saveChunk(chunk);
            }
        }

        if(this.ui.mouseReleased()){
            return true;
        }

        if(this.properties.mouseReleased()){
            return true;
        }

        if(this.objectFolder.mouseReleased()){
            return true;
        }

        return false;
    }

    keyPressed(): boolean {
        if(this.ui.keyPressed()){
            return true;
        }

        if(this.properties.keyPressed()){
            return true;
        }

        if(this.objectFolder.keyPressed()){
            return true;
        }

        if(p5js.keyCode === p5js.DELETE){
            if(this.selectedChunk && this.selectedObject){
                let index = this.selectedChunk.objects.indexOf(this.selectedObject);
                if(index > -1){
                    this.selectedChunk.objects.splice(index, 1);
                    this.selectedChunk.data.objects.splice(index, 1);
                    this.selectedObject = null;
                    this.updateProperties();
                    this.saveChunk(this.selectedChunk);
                }
            }
        }

        return false;
    }

    keyReleased(): boolean {
        if(this.ui.keyReleased()){
            return true;
        }

        if(this.properties.keyReleased()){
            return true;
        }

        if(this.objectFolder.keyReleased()){
            return true;
        }

        return false;
    }

    scroll(event: WheelEvent): boolean {
        if(this.ui.scroll(event)){
            return true;
        }

        if(this.properties.scroll(event)){
            return true;
        }

        if(this.objectFolder.scroll(event)){
            return true;
        }

        return false;
    }
}

export default ObjectTool;