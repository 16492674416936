import { ObjectData } from './../types';
import P5 from 'p5';
import { ChunkData, ObjectToDraw} from "../types";
import GameObject from "./gameObject";
import { mouseInArea, sliceImageToArray } from '../helpers';
import PreviewImageCache from '../previewImageCache';

class Chunk {
    static CHUNK_SIZE = 512;
    static tileCache: { [key: string]: any } = {};
    static tileCacheQueue: { [key: string]: boolean } = {};

    static waterImages: P5.Image[];
    static lavaImages: P5.Image[];
    static liquidsLoaded: boolean = false;

    static showEditorGrid: boolean = true;

    world: string;
    tileX: number;
    tileY: number;
    data: ChunkData;
    isDefault: boolean = false;

    objects: GameObject[] = [];

    constructor(world: string, tileX: number, tileY: number, data: ChunkData){
        this.world = world;
        this.tileX = tileX;
        this.tileY = tileY;
        this.data = data;
        if(this.data.tileImage){
            this.laodTileImage(this.data.tileImage);
        }
        this.unpackData();

        if(!Chunk.liquidsLoaded){
            Chunk.liquidsLoaded = true;
            if(!Chunk.waterImages){
                p5js.loadImage(`${staticServer}/assets/tiles/water.png`, (img) => {
                    console.log("Water image loaded!");
                    Chunk.waterImages = sliceImageToArray(img, 128, 128);
                });
            }
            if(!Chunk.lavaImages){
                p5js.loadImage(`${staticServer}/assets/tiles/lava.png`, (img) => {
                    console.log("Lava image loaded!");
                    Chunk.lavaImages = sliceImageToArray(img, 128, 128);
                });
            }
        }
    }

    unpackData(){
        for(let objectData of this.data.objects){
            let obj = new GameObject(objectData);
            this.objects.push(obj);
        }
    }

    laodTileImage(name: string){
        if(Chunk.tileCache[name] || Chunk.tileCacheQueue[name] || name == null){
            return;
        }

        Chunk.tileCacheQueue[name] = true; // Mark as loading
        let path = `${staticServer}/assets/tiles/${name}.png`;
        p5js.loadImage(path, (img) => {
            console.log(`Tile image ${path} loaded!`);
            Chunk.tileCache[name] = img;
            delete Chunk.tileCacheQueue[name]; // Remove loading mark
        }, () => {
            // Error callback for loadImage
            delete Chunk.tileCacheQueue[name]; // Remove loading mark on error
            console.error(`Failed to load image ${path}`);
        });
    }

    render(objectsToDraw: ObjectToDraw[], offsetX: number, offsetY: number){
        let posX = this.tileX * Chunk.CHUNK_SIZE - offsetX;
        let posY = this.tileY * Chunk.CHUNK_SIZE - offsetY;

        //render water animation
        if(this.data.liquid === 'water' && Chunk.waterImages){
            // the tiles is 512x512 so we need to render 4x4 of them
            for(let i=0; i<4; i++){
                for(let j=0; j<4; j++){
                    let img = Chunk.waterImages[p5js.int(p5js.frameCount / 4.0) % Chunk.waterImages.length];
                    buffer.image(img, posX + i * 128, posY + j * 128);
                }
            }
        }

        //render lava animation
        if(this.data.liquid === 'lava' && Chunk.lavaImages){
            // the tiles is 512x512 so we need to render 4x4 of them
            for(let i=0; i<4; i++){
                for(let j=0; j<4; j++){
                    let img = Chunk.lavaImages[p5js.int(p5js.frameCount / 4.0) % Chunk.lavaImages.length];
                    buffer.image(img, posX + i * 128, posY + j * 128);
                }
            }
        }

        let img = Chunk.tileCache[this.data.tileImage];
        if(img){
            buffer.image(img, posX, posY, Chunk.CHUNK_SIZE, Chunk.CHUNK_SIZE);
        }

        //render objects
        for(let obj of this.objects){
            obj.render(objectsToDraw, posX, posY);
        }

        if(showDebug){
            for(let wall of this.data.walls){
                if(wall.type === 'wall'){
                    buffer.fill(0, 0, 0, 80);
                    buffer.stroke(0, 0, 0, 100);
                }else if(wall.type === 'portal'){
                    buffer.fill(255, 0, 255, 80);
                    buffer.stroke(255, 0, 255, 100);
                }else if(wall.type === 'damage'){
                    buffer.fill(255, 0, 0, 80);
                    buffer.stroke(255, 0, 0, 100);
                }else if(wall.type === 'trigger'){
                    buffer.fill(0, 255, 0, 80);
                    buffer.stroke(0, 255, 0, 100);
                }else{
                    buffer.fill(255, 255, 255, 50);
                    buffer.stroke(255, 255, 255, 100);
                }
                buffer.strokeWeight(1);
                buffer.rect(posX + wall.x, posY + wall.y, wall.w, wall.h);
            }


            buffer.stroke(255, 50);
            buffer.strokeWeight(1);
            buffer.noFill();
            buffer.rect(posX, posY, Chunk.CHUNK_SIZE, Chunk.CHUNK_SIZE);

            buffer.fill(255);
            buffer.textSize(10);
            buffer.stroke(0);
            buffer.strokeWeight(3);
            buffer.textAlign(buffer.LEFT, buffer.TOP);
            buffer.text(`${this.tileX}, ${this.tileY}`, posX+5, posY+5);
        }
    }

    renderEditorMap(objectsToDraw: ObjectToDraw[], offsetX: number, offsetY: number, scale: number){
        let posX = this.tileX * Chunk.CHUNK_SIZE * scale - offsetX * scale;
        let posY = this.tileY * Chunk.CHUNK_SIZE * scale - offsetY * scale;

        //render water but just a blue rectangle
        if(this.data.liquid === 'water'){
            buffer.fill(50, 50, 180);
            buffer.noStroke();
            buffer.rect(posX, posY, Chunk.CHUNK_SIZE * scale, Chunk.CHUNK_SIZE * scale);
        }

        //render lava but just a red rectangle
        if(this.data.liquid === 'lava'){
            buffer.fill(255, 0, 0);
            buffer.noStroke();
            buffer.rect(posX, posY, Chunk.CHUNK_SIZE * scale, Chunk.CHUNK_SIZE * scale);
        }

        //render tile image
        let img = Chunk.tileCache[this.data.tileImage];
        if(img){
            buffer.image(img, posX, posY, Chunk.CHUNK_SIZE * scale, Chunk.CHUNK_SIZE * scale);
        }else{
            // buffer.fill(50);
            // buffer.rect(posX, posY, Chunk.CHUNK_SIZE * scale, Chunk.CHUNK_SIZE * scale);
        }

        //render walls
        for(let wall of this.data.walls){
            if(wall.type === 'wall'){
                buffer.fill(0, 0, 0, 80);
                buffer.stroke(0, 0, 0, 100);
            }else if(wall.type === 'portal'){
                buffer.fill(255, 0, 255, 80);
                buffer.stroke(255, 0, 255, 100);
            }else if(wall.type === 'damage'){
                buffer.fill(255, 0, 0, 80);
                buffer.stroke(255, 0, 0, 100);
            }else if(wall.type === 'trigger'){
                buffer.fill(0, 255, 0, 80);
                buffer.stroke(0, 255, 0, 100);
            }else{
                buffer.fill(255, 255, 255, 50);
                buffer.stroke(255, 255, 255, 100);
            }

            buffer.strokeWeight(1);
            buffer.rect(posX + wall.x * scale, posY + wall.y * scale, wall.w * scale, wall.h * scale);

            if(scale === 1){
                buffer.fill(255);
                buffer.noStroke();
                buffer.textSize(10);
                buffer.textAlign(buffer.LEFT, buffer.TOP);

                if(wall.type === 'portal'){
                    if(wall.portalData){
                        buffer.text(`${wall.portalData.world}\n(${wall.portalData.tx}, ${wall.portalData.ty})\nAsk: ${wall.portalData.ask}`, posX + wall.x + 5, posY + wall.y + 5);
                    }else{
                        buffer.text("ERROR\nNo data!", posX + wall.x + 5, posY + wall.y + 5);
                    }
                }else if(wall.type === 'damage'){
                    if(wall.damageData){
                        buffer.text(`Damage: ${wall.damageData.damage}`, posX + wall.x + 5, posY + wall.y + 5);
                    }else{
                        buffer.text("ERROR\nNo data!", posX + wall.x + 5, posY + wall.y + 5);
                    }
                }else if(wall.type === 'trigger'){
                    if(wall.triggerData){
                        buffer.text(`Action: ${wall.triggerData.action}\nAsk: ${wall.triggerData.ask}`, posX + wall.x + 5, posY + wall.y + 5);
                    }else{
                        buffer.text("ERROR\nNo data!", posX + wall.x + 5, posY + wall.y + 5);
                    }
                }
            }
        }

        

        //render objects if scale is 1
        if(!this.isDefault){
            if(scale === 1){
                //render objects
                for(let obj of this.objects){
                    obj.render(objectsToDraw, posX, posY);
                }

                for(let entityData of this.data.entities){
                    if(PreviewImageCache.imageExists(entityData.name)){
                        let imgData = PreviewImageCache.getImageData(entityData.name);
                        if(imgData != null){
                            let px = (posX + entityData.x) - imgData.x;
                            let py = (posY + entityData.y) - imgData.y;
                            let pz = (posY + entityData.y) + imgData.z;
                            //buffer.image(imgData.image, px, py, imgData.image.width * scale, imgData.image.height * scale);
                            objectsToDraw.push({image: imgData.image, x: px, y: py, z: pz });

                            buffer.noFill();
                            buffer.stroke(0, 255, 0);
                            buffer.strokeWeight(1);
                            buffer.ellipse(posX + entityData.x, posY + entityData.y, imgData.image.width, imgData.image.height/2);
                        }
                    }else{
                        buffer.noFill();
                        buffer.stroke(0, 255, 0);
                        buffer.strokeWeight(1);
                        buffer.ellipse(posX + entityData.x, posY + entityData.y, 50, 25);

                        buffer.fill(0, 255, 0);
                        buffer.noStroke();
                        buffer.textSize(10);
                        buffer.textAlign(buffer.CENTER, buffer.CENTER);
                        buffer.text(entityData.name, posX + entityData.x * scale, posY + entityData.y * scale);
                    }
                }
                
            }else{
                //render objects
                for(let obj of this.objects){
                    obj.renderEditor(posX, posY, scale);
                }

                //render entities spawn points
                buffer.noFill();
                buffer.stroke(0, 255, 0);
                buffer.strokeWeight(1);
                for(let entityData of this.data.entities){
                    buffer.ellipse(posX + entityData.x * scale, posY + entityData.y * scale, 50*scale, 25*scale);
                }
            }
        }else{
            // display default text in the center
            if(scale >= 0.25){
                buffer.fill(255);
                buffer.textSize(15);
                buffer.noStroke();
                buffer.textAlign(buffer.CENTER, buffer.CENTER);
                buffer.text("Default", posX + Chunk.CHUNK_SIZE/2 * scale, posY + Chunk.CHUNK_SIZE/2 * scale);
            }
        }

        //render grid
        buffer.stroke(255, 100);
        buffer.strokeWeight(1);
        buffer.noFill();
        buffer.rect(posX, posY, Chunk.CHUNK_SIZE * scale, Chunk.CHUNK_SIZE * scale);

        if(scale >= 0.25){
            buffer.fill(255);
            buffer.textSize(10);
            buffer.stroke(0);
            buffer.strokeWeight(3);
            buffer.textAlign(buffer.LEFT, buffer.TOP);
            buffer.text(`${this.tileX}, ${this.tileY}`, posX+5, posY+5);
        }

        if(scale == 1 && Chunk.showEditorGrid){
            if(mouseInArea(posX, posY, Chunk.CHUNK_SIZE * scale, Chunk.CHUNK_SIZE * scale)){
                //render 16x16 grid
                buffer.stroke(255, 100);
                buffer.strokeWeight(1);
                buffer.noFill();
                for(let i=1; i<32; i++){
                    for(let j=1; j<32; j++){
                        buffer.point(posX + i * 16 * scale, posY + j * 16 * scale);
                    }
                }
            }
        }
    }
}

export default Chunk;