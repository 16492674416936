import P5 from 'p5';
import { ObjectAnimData, ObjectData, ObjectToDraw } from '../types';
import { sliceImageToArray } from '../helpers';

class GameObject{
    static cache: { [key: string]: ObjectAnimData } = {};
    static cacheQueue: { [key: string]: boolean } = {};

    data: ObjectData;
    name: string;
    objectLoadedAction: Function;

    constructor(data: ObjectData){
        //console.log(`GameObject "${data.name}" created`);
        this.data = data;
        this.name = data.name;
        this.load(data.name);
    }

    load(name: string){
        let pathToLoad = `/assets/objects/${name}`;
        if(!GameObject.cache[name] && !GameObject.cacheQueue[name]){
            GameObject.cacheQueue[name] = true;

            p5js.loadJSON(`${staticServer}${pathToLoad}.json`, (data) => {
                console.log(`Data for ${staticServer}${pathToLoad}.json loaded!`);

                p5js.loadImage(`${staticServer}${pathToLoad}.png`, (img) => {
                    console.log(`Image for ${staticServer}${pathToLoad}.png loaded!`);

                    let cachedData : ObjectAnimData;

                    if(data.animated){
                        cachedData = {
                            centerX: data.center.x,
                            centerY: data.center.y,
                            width: data.boundary.w,
                            height: data.boundary.h,
                            zindex: data.zindex,
                            images: sliceImageToArray(img, data.boundary.w, data.boundary.h),
                            animated: true
                        };
                    }else{
                        cachedData = {
                            centerX: data.center.x,
                            centerY: data.center.y,
                            width: data.boundary.w,
                            height: data.boundary.h,
                            zindex: data.zindex,
                            images: [img],
                            animated: false
                        };
                    }

                    if(this.objectLoadedAction){
                        this.objectLoadedAction();
                    }

                    GameObject.cache[name] = cachedData;
                    delete GameObject.cacheQueue[name]; // Remove loading mark
                }, () => {
                    // Error callback for loadImage
                    delete GameObject.cacheQueue[name]; // Remove loading mark on error
                    console.error(`Failed to load image for ${staticServer}${pathToLoad}.png`);
                });
            }, () => {
                // Error callback for loadJSON
                delete GameObject.cacheQueue[name]; // Remove loading mark on error
                console.error(`Failed to load JSON for ${staticServer}${pathToLoad}.json`);
            });
        }

        if(GameObject.cache[name]){
            if(this.objectLoadedAction){
                this.objectLoadedAction();
            }
        }
    }

    render(objectsToDraw: ObjectToDraw[], offsetX: number, offsetY: number) {
        let cache = GameObject.cache[this.data.name];
        if (!cache) {
            return;
        }

        let frame = 0;
        if(cache.animated){
            frame = p5js.int(p5js.frameCount / 4.0) % cache.images.length;
        }

        let objX = offsetX + this.data.x - cache.centerX;
        let objY = offsetY + this.data.y - cache.centerY;
        let objZ = offsetY + this.data.y + cache.zindex;
        if(cache.images[frame]){
            objectsToDraw.push({ image: cache.images[frame], x: objX, y: objY, z: objZ });
        }
    }

    renderEditor(offsetX: number, offsetY: number, scale: number){
        let cache = GameObject.cache[this.data.name];
        if (!cache) {
            return;
        }

        let objX = offsetX + this.data.x * scale - cache.centerX * scale;
        let objY = offsetY + this.data.y * scale - cache.centerY * scale;
        if(cache.images[0]){
            buffer.image(cache.images[0], objX, objY, cache.width * scale, cache.height * scale);
        }
    }

    renderPreview(x: number, y: number, scale: number){
        let cache = GameObject.cache[this.data.name];
        if (!cache) {
            return;
        }

        let objX = x - cache.centerX * scale;
        let objY = y - cache.centerY * scale;
        if(cache.images[0]){
            buffer.tint(255, 255, 255, 127);
            buffer.image(cache.images[0], objX, objY, cache.width * scale, cache.height * scale);
            buffer.noTint();
        }
    }

    getPreviewImage(){
        let cache = GameObject.cache[this.data.name];
        if (!cache) {
            return;
        }
        return cache.images[0];
    }

    getAnimData(){
        return GameObject.cache[this.data.name];
    }
}

export default GameObject;