// import { UIElementAlignment } from "../types";
import UIElement from "./uiElement";

class ScrollGridView implements UIElement {
    x: number;
    y: number;
    w: number;
    h: number;

    spacing: number = 5;
    action: Function; //not used
    elements: UIElement[] = [];

    scrollPosition: number = 0;

    maxScrollPosition: number = 0;

    show: boolean = true;

    constructor(x: number, y: number, w: number, h: number) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
    }

    addElement(element: UIElement): void {
        this.elements.push(element);
    }

    getBiggestObjectSize(): {w: number, h: number}{
        let biggestW = 0;
        let biggestH = 0;

        for(let element of this.elements){
            if(element.w > biggestW){
                biggestW = element.w;
            }
            if(element.h > biggestH){
                biggestH = element.h;
            }
        }

        return {w: biggestW, h: biggestH};
    }

    updateLayout(): void {
        // arrange elements in a grid
        let biggestSize = this.getBiggestObjectSize();

        let cols = Math.floor(this.w / (biggestSize.w + this.spacing));
        let rows = Math.floor(this.h / (biggestSize.h + this.spacing));

        let currentX = this.x + this.spacing;
        let currentY = this.y + this.spacing + this.scrollPosition;

        let currentCol = 0;
        let currentRow = 0;

        for(let element of this.elements){
            element.x = currentX;
            element.y = currentY;

            currentCol++;
            if(currentCol >= cols){
                currentCol = 0;
                currentRow++;
                currentX = this.x + this.spacing;
                currentY += biggestSize.h + this.spacing;
            }else{
                currentX += biggestSize.w + this.spacing;
            }
        }

        this.maxScrollPosition = currentY + biggestSize.h * 10;

        for(let element of this.elements){
            element.updateLayout();
        }
    }
    
    render(): void {
        if(!this.show){
            return;
        }
        buffer.fill(200);
        buffer.stroke(0);
        buffer.strokeWeight(1);
        buffer.rect(this.x, this.y, this.w, this.h);

        for(let element of this.elements){
            element.render();
        }
    }

    renderTooltip(): boolean {
        if(!this.show){
            return false;
        }
        for(let element of this.elements){
            if(element.renderTooltip()){
                return true;
            }
        }
        return false;
    }

    mouseInArea(): boolean{
        let mx = p5js.mouseX/pixelSize - buffer.width/2;
        let my = p5js.mouseY/pixelSize - buffer.height/2;

        if(mx > this.x && mx < this.x + this.w && my > this.y && my < this.y + this.h){
            return true;
        }

        return false;
    }

    mousePressed(): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.mousePressed()){
                return true;
            }
        }

        if(this.mouseInArea()){
            return true;
        }

        return false;
    }

    mouseReleased(): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.mouseReleased()){
                return true;
            }
        }

        if(this.mouseInArea()){
            return true;
        }

        return false;
    }

    keyPressed(): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.keyPressed()){
                return true;
            }
        }
        return false;
    }

    keyReleased(): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.keyReleased()){
                return true;
            }
        }
        return false;
    }

    scroll(event: any): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.scroll(event)){
                return true;
            }
        }

        if(this.mouseInArea()){
            if(event.deltaY > 0){
                this.scrollPosition += 50;
            }else{
                this.scrollPosition -= 50;
            }

            if(this.scrollPosition > 0){
                this.scrollPosition = 0;
            }

            if(this.scrollPosition < -this.maxScrollPosition){
                this.scrollPosition = -this.maxScrollPosition;
            }

            this.updateLayout();

            return true;
        }

        return false;
    }
}

export default ScrollGridView;