import { IScreen } from './iScreen';
import GameMap from './game/gameMap';
import { ObjectToDraw } from './types';
import { getCookie, setCookie} from './helpers';

class GameScreen implements IScreen{
    gameMap: GameMap;


    frameTimeGraph: number[] = [];
    gameSaveCookie: string | null;

    timeToSave: number = p5js.millis() + 1000;
    saveInterval: number = 2000;
    cookieLoaded: boolean = false;

    constructor(){
        //this.player = new Player(this.p5);
        this.gameMap = new GameMap();
        this.gameMap.loadWorld('world', 256, 256);

        this.gameSaveCookie = getCookie('player');
        // if(this.gameSaveCookie){
        //     console.log('Player position loaded from cookie');
        //     let [x, y] = this.gameSaveCookie.split('_');
        //     player.x = parseInt(x);
        //     player.y = parseInt(y);
        // }
    }

    setup(){

    }

    windowResized(): void {
        player.windowResized();
    }

    draw(){
        buffer.background(0);

        // create a list to store objects that will be drawn later
        let objectsToDraw: ObjectToDraw[] = [];

        player.update(this.gameMap);
        this.gameMap.update();
        for(let entity of entities){
            entity.update();
        }

        this.gameMap.render(objectsToDraw);
        player.render(objectsToDraw);
        for(let entity of entities){
            entity.render(objectsToDraw);
        }

        for(let particle of particles){
            particle.update();
            particle.render(objectsToDraw);
        }
        
        // Draw all objects in the sorted order
        objectsToDraw.sort((a, b) => a.z - b.z);
        for(let obj of objectsToDraw) {
            buffer.image(obj.image, obj.x, obj.y);
        }

        for(let particle of particles){
            particle.renderOver();
        }
        
        player.renderUI();


        //debug, show all touch points with id
        if(p5js.touches.length > 0){
            buffer.fill(255);
            buffer.noStroke();
            buffer.textSize(20);
            for(let i = 0; i < p5js.touches.length; i++){
                let touch = p5js.touches[i] as any;
                let tx = touch.x/pixelSize-buffer.width/2;
                let ty = touch.y/pixelSize-buffer.height/2;
                buffer.ellipse(tx, ty, 5, 5);
                buffer.text(i, tx+10, ty);
            }
        }


        if(showDebug){
            let debugText = `Frame rate: ${p5js.frameRate().toFixed(2)} fps\n`;
            debugText += `Frame render time: ${renderTimeLast.toFixed(2)} / 16ms\n`;
            debugText += `PixelSize: ${pixelSize}\n`;
            debugText += player.getDebugText();
            debugText += this.gameMap.getDebugText();
            debugText += `Entities: ${entities.length}\n`;

            buffer.textAlign(buffer.LEFT, buffer.TOP);
            buffer.fill(255);
            buffer.textSize(11);
            buffer.noStroke();
            buffer.text(debugText, -buffer.width/2+10, -buffer.height/2+10);

            //show frame time graph
            buffer.strokeWeight(1);
            for(let i = 0; i < this.frameTimeGraph.length; i++){
                if(this.frameTimeGraph[i] > 16){
                    buffer.stroke(255, 0, 0);
                }else{
                    buffer.stroke(0, 255, 0);
                }
                buffer.line(-buffer.width/2+i, buffer.height/2, -buffer.width/2+i, buffer.height/2-this.frameTimeGraph[i]);
            }

            //show max frame time (16ms)
            buffer.stroke(255);
            buffer.line(-buffer.width/2, buffer.height/2-16, -buffer.width/2+200, buffer.height/2-16);

            //get max frame time from graph
            let maxFrameTime = Math.max(...this.frameTimeGraph);

            //show line at max frame time
            buffer.stroke(255, 0, 0);
            buffer.line(-buffer.width/2, buffer.height/2-maxFrameTime, -buffer.width/2+200, buffer.height/2-maxFrameTime);

            buffer.fill(255, 0, 0);
            buffer.noStroke();
            buffer.textAlign(buffer.LEFT, buffer.BOTTOM);
            buffer.text(maxFrameTime.toFixed(2) + 'ms', -buffer.width/2+2, buffer.height/2-maxFrameTime-2);

            this.frameTimeGraph.push(renderTimeLast);
            if(this.frameTimeGraph.length > 200){
                this.frameTimeGraph.shift();
            }
        }
        this.deleteDead();


        // save game state every 10 seconds
        //if(p5js.millis() > this.timeToSave){
            //this.timeToSave = p5js.millis() + this.saveInterval;
            //setCookie('player', `${player.x}_${player.y}`, 365);
            //console.log('Game saved');
        //}

        
    } 

    deleteDead(){
        //use filter to remove dead entities
        entities = entities.filter(entity => !entity.isToBeRemoved());

        //use filter to remove dead particles
        particles = particles.filter(particle => !particle.isToBeRemoved());
    }

    keyPressed(){
        if(p5js.keyCode === p5js.ESCAPE){
            currentScreen = "mainMenu";
            iScreen = screens[currentScreen];
            //iScreen = new MainMenuScreen(p5js);
            return;
        }else if(p5js.key === '?'){
            showDebug = !showDebug;
        }

        //console.log(`key: ${p5js.key}, keyCode: ${p5js.keyCode}`);

        player.keyPressed();
    }   

    keyReleased(){
        player.keyReleased();
    }

    mousePressed(){
        player.mousePressed();
    }

    mouseReleased(){
        player.mouseReleased();
    }

    touchStarted(){
        player.touchStarted();
    }

    touchEnded(){
        player.touchEnded();
    }

    touchMoved(): void {
        player.touchMoved();
    }

    mouseWheel(event: any): void {
        player.mouseWheel(event);
    }

}

export default GameScreen;