import UIElement from "./uiElement";

class HorizontalSeparator implements UIElement {
    x: number;
    y: number;
    w: number;
    h: number;
    action: Function;

    constructor(w: number) {
        this.x = 0;
        this.y = 0;
        this.w = w;
        this.h = 10;
        this.updateLayout();
    }

    updateLayout(): void {
       
    }

    render(): void {
        //draw separator line 
        buffer.stroke(100);
        buffer.strokeWeight(1);
        buffer.line(this.x + 5, this.y + this.h/2, this.x + this.w - 5, this.y + this.h/2);
    }

    renderTooltip(): boolean {
        return false;
    }

    mousePressed(): boolean {
        return false;
    }

    mouseReleased(): boolean {
        return false;
    }

    keyPressed(): boolean {
        return false;
    }

    keyReleased(): boolean {
        return false;
    }

    scroll(event: WheelEvent): boolean {
        return false;
    }
}

export default HorizontalSeparator;