import P5 from 'p5';
import { ObjectToDraw, SpriteAnimData } from './types';

class SpriteAnimationRenderer{
    static cache: { [key: string]: SpriteAnimData } = {};
    static cacheQueue: { [key: string]: boolean } = {};

    animationOffset : number;
    path : string;
    repeats : boolean = true;
    playing : boolean = true;
    frame : number = 0;
    frameTime : number = 64; // 64ms per frame
    timeOfLastFrame : number = 0;

    constructor(){
        this.animationOffset = p5js.random(1000);
    }

    load(pathToLoad : string){
        this.path = pathToLoad;
        if(!SpriteAnimationRenderer.cache[pathToLoad] && !SpriteAnimationRenderer.cacheQueue[pathToLoad]){
            SpriteAnimationRenderer.cacheQueue[pathToLoad] = true;

            p5js.loadJSON(`${staticServer}${pathToLoad}.json`, (data) => {
                console.log(`Data for ${staticServer}${pathToLoad}.json loaded!`);

                p5js.loadImage(`${staticServer}${pathToLoad}.png`, (img) => {
                    console.log(`Image for ${staticServer}${pathToLoad}.png loaded!`);

                    let images : P5.Image[][] = [];
                            // Load the sprites
                            for (let i = 0; i < data.rotations; i++) {
                                images[i] = [];
                                for (let j = 0; j < data.frames; j++) {
                                    // Get frame from spritesheet
                                    let x = j * data.frameWidth;
                                    let y = i * data.frameHeight;
                                    images[i][j] = img.get(x, y, data.frameWidth, data.frameHeight);
                                    // console.log(`Frame loaded!, x ${x} y ${y} w ${frameWidth} h ${frameHeight}`);
                                }
                            }

                    let cachedData : SpriteAnimData = {
                        centerX : data.centerX,
                        centerY : data.centerY,
                        frameWidth : data.frameWidth,
                        frameHeight : data.frameHeight,
                        framesCount : data.frames,
                        rotationCount : data.rotations,
                        images: images
                    };
    
                    SpriteAnimationRenderer.cache[pathToLoad] = cachedData;

                    delete SpriteAnimationRenderer.cacheQueue[pathToLoad]; // Remove loading mark
                }, () => {
                    // Error callback for loadImage
                    delete SpriteAnimationRenderer.cacheQueue[pathToLoad]; // Remove loading mark on error
                    console.error(`Failed to load image for ${staticServer}${pathToLoad}.png`);
                });
            }, () => {
                // Error callback for loadJSON
                delete SpriteAnimationRenderer.cacheQueue[pathToLoad]; // Remove loading mark on error
                console.error(`Failed to load JSON for ${staticServer}${pathToLoad}.json`);
            });
        }
        // else{
        //     if(SpriteAnimationRenderer.cacheQueue[pathToLoad]){
        //         console.log(`Data for ${pathToLoad} is already loading!`);
        //     }
        //     if(SpriteAnimationRenderer.cache[pathToLoad]){
        //         console.log(`Data for ${pathToLoad} already loaded!`);
        //     }
        // }
    }

    start(pos: number = 0){
        this.playing = true;
        this.frame = pos;
        this.timeOfLastFrame = p5js.millis();
    }

    stop(){
        this.playing = false;
    }

    render(objectsToDraw: ObjectToDraw[], x: number, y: number, rotation: number) {
        

        let cache = SpriteAnimationRenderer.cache[this.path];
        if (!cache) {
            return;
        }

        //calculate the current frame
        if(this.playing && this.frameTime){
            if(this.timeOfLastFrame + this.frameTime < p5js.millis()){
                this.timeOfLastFrame = p5js.millis();
                this.frame++;
                if(this.frame >= SpriteAnimationRenderer.cache[this.path].framesCount){
                    if(this.repeats){
                        this.frame = 0;
                    }
                    else{
                        this.playing = false;
                        this.frame = SpriteAnimationRenderer.cache[this.path].framesCount - 1;
                    }
                }
            }
        }


        rotation = rotation % cache.rotationCount;
        //let currentFrame = p5js.floor(((p5js.frameCount / 4.0) + this.animationOffset) % cache.framesCount);
        let currentImage = cache.images[rotation][this.frame];
        objectsToDraw.push({ image: currentImage, x: x - cache.centerX, y: y - cache.centerY, z: y });
    }

    getFrame(rotation: number, frame: number) : P5.Image | null{
        let cache = this.getData();
        if (!cache) {
            return null;
        }
        rotation = rotation % cache.rotationCount;
        return cache.images[rotation][frame];
    }

    getData() : SpriteAnimData | null{
        return SpriteAnimationRenderer.cache[this.path];
    }
}

export default SpriteAnimationRenderer;