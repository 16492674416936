import { mouseInArea } from "../helpers";
import UIElement from "./uiElement";

class InfiniteSlider implements UIElement{
    x: number;
    y: number;
    w: number;
    h: number;
    action: Function;
    tooltipText: string;

    inputText: string = "";
    value: number;
    focused: boolean = false;

    ALLOWED_CHARS = "0123456789";

    constructor(tooltipText: string, width: number) {
        this.tooltipText = tooltipText;
        this.x = 0;
        this.y = 0;
        this.w = width;
        this.h = 30;
        this.value = 0;
        this.inputText = "0";
    }

    updateLayout(): void {
        
    }

    render(): void {
        buffer.fill(0);
        buffer.textSize(10);
        buffer.noStroke();
        buffer.textAlign(buffer.LEFT, buffer.TOP);
        buffer.text(this.tooltipText, this.x, this.y);

        //draw the slider area
        if(this.focused){
            buffer.fill(0);
        }else{
            buffer.fill(150);
        }

        buffer.rect(this.x, this.y + 15, this.w, 15);

        //subtract button
        buffer.fill(100);
        buffer.rect(this.x, this.y + 15, 15, 15);

        //add button
        buffer.fill(100);
        buffer.rect(this.x + this.w - 15, this.y + 15, 15, 15);

        

        buffer.textAlign(buffer.CENTER, buffer.CENTER);
        if(this.focused){
            buffer.fill(255);
            buffer.text(this.inputText, this.x, this.y + 15, this.w, 15);
        }else{
            buffer.fill(0);
            buffer.text(this.value.toString(), this.x, this.y + 15, this.w, 15);
        }

        //blinking cursor
        if(this.focused && p5js.frameCount % 60 < 30){
            let pos = buffer.textWidth(this.inputText)/2 + 3;
            if(pos < this.w - 4){
                buffer.stroke(255);
                buffer.strokeWeight(2);
                buffer.line(this.x + this.w/2 + pos, this.y + 17, this.x + this.w/2 + pos, this.y + this.h - 2);
            }
        }
        

        //draw the + - text on buttons
        buffer.fill(255);
        buffer.textSize(20);
        buffer.noStroke();
        buffer.textAlign(buffer.CENTER, buffer.CENTER);
        buffer.text("-", this.x + 7, this.y + 15 + 7);
        buffer.text("+", this.x + this.w - 7, this.y + 15 + 7);
    }

    renderTooltip(): boolean {
        if(mouseInArea(this.x, this.y, this.w, this.h)){
            let posX = p5js.int((p5js.mouseX/pixelSize) - (buffer.width/2)) + 5;
            let posY = p5js.int((p5js.mouseY/pixelSize) - (buffer.height/2)) + 5;
            buffer.stroke(255);
            buffer.strokeWeight(1);
            buffer.fill(0, 200);
            buffer.textSize(10);
            let w = buffer.textWidth(`${this.tooltipText}: ${this.value.toString()}`) + 10;
            buffer.rect(posX, posY, w, 20);
            buffer.noStroke();
            buffer.fill(255);
            buffer.textAlign(buffer.LEFT, buffer.CENTER);
            buffer.text(`${this.tooltipText}: ${this.value.toString()}`, posX+5, posY+10);
            return true;
        }
        return false;
    }


    mousePressed(): boolean {
        
        if(mouseInArea(this.x, this.y + 15, 15, 15)){
            this.value--;
            this.focused = false;
            this.action();
            return true;
        }

        if(mouseInArea(this.x + this.w - 15, this.y + 15, 15, 15)){
            this.value++;
            this.focused = false;
            this.action();
            return true;
        }

        if(mouseInArea(this.x + 15, this.y, this.w - 30, this.h)){
            this.focused = true;
            //this.inputText = this.value.toString();
            this.inputText = "";
            return true;
        }

        this.focused = false;

        return false;
    }

    mouseReleased(): boolean {
        return false;
    }

    keyPressed(): boolean {
        //debug keycodes for testing
        console.log(`keyCode: ${p5js.keyCode}, key: ${p5js.key}, keyIsDown: ${p5js.keyIsDown(17)}`);

        if(this.focused){
            if(p5js.keyCode === 46 || (p5js.keyCode === 8 && p5js.keyIsDown(17))){ //delete key to erase the text
                this.inputText = "";
            }else if(p5js.keyCode === 8){ //backspace
                this.inputText = this.inputText.substring(0, this.inputText.length - 1);
            }else if(p5js.keyCode === 13){ //enter
                this.focused = false;
                console.log(this.inputText);
                this.value = parseInt(this.inputText);
                if(this.action){
                    this.action();
                }
            }else if(p5js.keyCode === 86 && p5js.keyIsDown(17)){ //ctrl + v //paste text from clipboard
                navigator.clipboard.readText().then(clipText => {
                    this.inputText += clipText;
                });
            }else if(p5js.keyCode === 67 && p5js.keyIsDown(17)){ //ctrl + c //copy text to clipboard
                navigator.clipboard.writeText(this.inputText); 
            }else if(p5js.keyCode === 88 && p5js.keyIsDown(17)){ //ctrl + x //cut text to clipboard
                navigator.clipboard.writeText(this.inputText); 
                this.inputText = "";
            }else{
                //accept only allowed characters, allow one character at a time
                if(this.ALLOWED_CHARS.includes(p5js.key)){
                    this.inputText += p5js.key;
                }
            }
            return true;
        }

        return false;
    }

    keyReleased(): boolean {
        return false;
    }

    scroll(event: any): boolean {
        if(mouseInArea(this.x, this.y, this.w, this.h)){
            if(event.delta > 0){
                this.value -= 8;
                this.action();
            }else{
                this.value += 8;
                this.action();
            }
        }
        return false;
    }
}

export default InfiniteSlider;