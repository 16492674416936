import Chicken from "../entities/chicken";
import Entity from "../entities/entity";
import OtherPlayer from "../entities/otherPlayer";
import TestEntity from "../entities/testEntity";
import { ObjectToDraw } from "../types";
import Chunk from "./chunk";
import ChunkFactory from "./chunkFactory";

class GameMap{
    chunks: {[key: string]: Chunk} = {};
    chunksQueue: {[key: string]: boolean} = {};

    worldName: string = '';
    posX: number = 0;
    posY: number = 0;
    renderDistance: number = 1;
    unloadChunkDistance: number = 2;

    worldDefaultData: any = null;
    unpackChunks: boolean = true;

    constructor(){
        
    }

    loadWorld(worldName: string, targetX: number, targetY: number){
        this.worldName = worldName;
        this.posX = targetX;
        this.posY = targetY;
        this.chunks = {};
        this.chunksQueue = {};
        entities = [];
        this.loadWorldDefaultData(worldName);
    }

    loadWorldDefaultData(world: string){
        p5js.loadJSON(`${staticServer}/assets/worlds/${world}/world.json`, (data) => {
            console.log(`Data for world.json loaded!`);
            console.log(data);
            this.worldDefaultData = data;
        }, () => {
            console.error(`Failed to load JSON for world.json`);
            this.worldDefaultData = {
                "defaultTile" : "empty",
                "naturalObjects" : [],
                "naturalEntities" : []}
        });
    }

    loadVisibleChunks(){
        let currentChunk = {x: p5js.floor(this.posX / Chunk.CHUNK_SIZE), y: p5js.floor(this.posY / Chunk.CHUNK_SIZE)};
        
        for (let i = -this.renderDistance; i <= this.renderDistance; i++) {
            for (let j = -this.renderDistance; j <= this.renderDistance; j++) {
                let chunkX = currentChunk.x + i;
                let chunkY = currentChunk.y + j;
                this.loadChunk(this.worldName, chunkX, chunkY);
            }
        }
    }

    unloadDistantChunks(){
        let currentChunk = {x: p5js.floor(this.posX / Chunk.CHUNK_SIZE), y: p5js.floor(this.posY / Chunk.CHUNK_SIZE)};
        let keysToRemove: {world: string, x: number, y: number}[] = [];
      
        for (let key in this.chunks) {
            let [worldName, chunkXs, chunkYs] = key.split('_');
            let chunkX = parseInt(chunkXs);
            let chunkY = parseInt(chunkYs);
            if (p5js.abs(chunkX - currentChunk.x) > this.unloadChunkDistance || p5js.abs(chunkY - currentChunk.y) > this.unloadChunkDistance) {
                keysToRemove.push({world: worldName, x: chunkX, y: chunkY});
            }
        }

        keysToRemove.forEach(key => {
            console.log("Unloading chunk", key);
            this.unloadChunk(key.x, key.y);
        });
    }

    unloadChunk(tileX: number, tileY: number){
        let chunkName = `${this.worldName}_${tileX}_${tileY}`;

        //remove entities in that area
        let posX = tileX * Chunk.CHUNK_SIZE;
        let posY = tileY * Chunk.CHUNK_SIZE;
        for(let i = 0; i < entities.length; i++){
            let entity = entities[i];
            if(entity.x >= posX && entity.x < posX + Chunk.CHUNK_SIZE && entity.y >= posY && entity.y < posY + Chunk.CHUNK_SIZE){
                entities.splice(i, 1);
                i--;
            }
        }

        if(this.chunks[chunkName]){
            delete this.chunks[chunkName];
        }
    }

    loadChunk(world: string, tileX: number, tileY: number){
        let chunkName = `${world}_${tileX}_${tileY}`;
        
        //check if chunk is already loaded
        if(this.chunks[chunkName] || this.chunksQueue[chunkName]){
            return;
        }

        this.chunksQueue[chunkName] = true; // Mark as loading

        let path = `${staticServer}/assets/worlds/${this.worldName}/chunk_${tileX}_${tileY}.json`;

        p5js.loadJSON(path, (data) => {
            console.log(`Data for chunk ${path} loaded!`);
            this.chunks[chunkName] = new Chunk(this.worldName, tileX, tileY, data);
            delete this.chunksQueue[chunkName]; // Remove loading mark
            this.unpackChunk(this.chunks[chunkName]);
        }, () => {
            console.error(`Failed to load JSON for chunk ${path}`);
            this.chunks[chunkName] = ChunkFactory.generateDefaultChunk(this.worldDefaultData, this.worldName, tileX, tileY);
            delete this.chunksQueue[chunkName]; // Remove loading mark
            this.unpackChunk(this.chunks[chunkName]);
        });
    }

    unpackChunk(chunk: Chunk){
        if(!this.unpackChunks){
            return;
        }
        //we are only interested in entities
        for(let entityData of chunk.data.entities){
            let entity: Entity | null = null;
            let posX = chunk.tileX * Chunk.CHUNK_SIZE + entityData.x;
            let posY = chunk.tileY * Chunk.CHUNK_SIZE + entityData.y;

            if(entityData.name == 'chicken'){
                entity = new Chicken(this, posX, posY);
            }else if (entityData.name == 'testEntity'){
                entity = new TestEntity(this, posX, posY);
            }else if (entityData.name == 'otherPlayer'){
                entity = new OtherPlayer(posX, posY);
            }

            if(entity && !this.entityExists(entity.getHash())){
                entities.push(entity);
            }
        }
    }

    entityExists(hash: string){
        for(let entity of entities){
            if(entity.getHash() == hash){
                return true;
            }
        }
        return false;
    }

    getChunk(tileX: number, tileY: number): Chunk | null{
        let chunkName = `${this.worldName}_${tileX}_${tileY}`;
        if(!this.chunks[chunkName]){
            return null;
        }
        return this.chunks[chunkName];
    }

    getCurrentChunk(): Chunk | null{
        return this.getChunk(p5js.floor(this.posX / Chunk.CHUNK_SIZE), p5js.floor(this.posY / Chunk.CHUNK_SIZE));
    }

    update(){
        //console.log('Updating map');
        this.loadVisibleChunks();
        this.unloadDistantChunks();
    }

    render(objectsToDraw: ObjectToDraw[]){
        for(let chunkName in this.chunks){
            this.chunks[chunkName].render(objectsToDraw, this.posX, this.posY);
        }
    }

    renderEditorMap(objectsToDraw: ObjectToDraw[], scale: number){
        for(let chunkName in this.chunks){
            this.chunks[chunkName].renderEditorMap(objectsToDraw, this.posX, this.posY, scale);
        }
    }

    getDebugText(){
        return `Chunks: ${Object.keys(this.chunks).length}\n`;
    }
}

export default GameMap;