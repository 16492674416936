import { sliceImageToArray } from "../helpers";
import Player from "../game/player";
import { ObjectToDraw } from "../types";
import Particle from "./Particle";
import P5 from 'p5';

class Smoke implements Particle {

    x: number;
    y: number;
    ix: number[] = [];
    iy: number[] = [];
    ivx: number[] = [];
    ivy: number[] = [];
    in: number[] = [];
    count: number;

    timeToDie: number;

    isDead: boolean = false;
    static image: P5.Image[];

    constructor(sx: number, sy: number, count: number){
        this.x = sx;
        this.y = sy;
        this.count = count;
        this.loadImage();
        this.timeToDie = p5js.millis() + 1000;

        for(let i=0; i<this.count; i++){
            this.ix.push(p5js.random(-32, 32));
            this.iy.push(p5js.random(-32, 0));
            this.ivx.push(p5js.random(-.5, .5));
            this.ivy.push(p5js.random(-.5, -1));
            this.in.push(p5js.int(p5js.random(0, 2)));
        }
    }

    loadImage(){
        if(!Smoke.image){
            let path = '/assets/particles/smoke';
            p5js.loadImage(`${staticServer}${path}.png`, (img) => {
                Smoke.image = sliceImageToArray(img, 32, 32);
                console.log('Smoke image loaded');
            });
        }
    }

    update(){
        if(p5js.millis() > this.timeToDie){
            this.kill();
            return;
        }

        for(let i=0; i<this.count; i++){
            this.ix[i] += this.ivx[i];
            this.iy[i] += this.ivy[i];
        }
    }

    render(objectsToDraw: ObjectToDraw[]){
        let posOnScreen = {x: this.x-player.x, y: this.y-player.y};

        if(!Smoke.image){
            return;
        }

        
        

        for(let i=0; i<this.count; i++){
            let px = p5js.int(posOnScreen.x + this.ix[i]);
            let py = p5js.int(posOnScreen.y + this.iy[i]);

            let imgIndex = p5js.int(p5js.map(p5js.millis(), this.timeToDie-1000, this.timeToDie, 0, Smoke.image.length));
            imgIndex = imgIndex + this.in[i];
            if(imgIndex >= Smoke.image.length){
                imgIndex = Smoke.image.length-1;
            }

            objectsToDraw.push({
                image: Smoke.image[imgIndex],
                x: px-16,
                y: py-16,
                z: py-16,
            });
        }
    }

    renderOver(): void {
        
    }

    isToBeRemoved(): boolean {
        return this.isDead;
    }

    kill(){
        this.isDead = true;
    }
}

export default Smoke;