import { randomArrayElementRNG, randomIntRNG } from "../helpers";
import { EntityData, ObjectData } from "../types";
import Chunk from "./chunk";
import seedrandom from 'seedrandom';

class ChunkFactory{
    //this method generates new chynk based on default world description
    //Example of default world description:
    // {
    // 	"defaultTile" : "1",
    // 	"water" : false,
    // 	"naturalObjects" : [
    // 		"tree1",
    // 		"tree2",
    // 		"tree3",
    // 		"rock", 
    // 		"grass",
    // 		"plant_small",
    // 		"plant_big"
    // 	],
    // 	"naturalEntities" : [
    // 		"chicken", "bird", "butterfly"
    // 	]
    // }

    static generateDefaultChunk(data: any, world:string, x: number, y: number): Chunk{
        let rng = seedrandom(`${world}_${x}_${y}`);
        

        //generate 10 random objects
        let objects: ObjectData[] = [];
        for(let i = 0; i < 10; i++){
            let objectData = {
                x: randomIntRNG(rng, 0, Chunk.CHUNK_SIZE),
                y: randomIntRNG(rng, 0, Chunk.CHUNK_SIZE),
                name: randomArrayElementRNG(rng, data.naturalObjects)
            }
            objects.push(objectData);
        }

        //generate 1 random entities
        let entities: EntityData[] = [];
        for(let i = 0; i < 1; i++){
            let entityData = {
                x: randomIntRNG(rng, 0, Chunk.CHUNK_SIZE),
                y: randomIntRNG(rng, 0, Chunk.CHUNK_SIZE),
                name: randomArrayElementRNG(rng, data.naturalEntities)
            }
            entities.push(entityData);
        }

        let chunkData = {
            tileImage: data.defaultTile,
            liquid: data.liquid,
            objects: objects,
            entities: entities,
            walls: [],
            portals: []
        };

        let chunk = new Chunk(world, x, y, chunkData);
        chunk.isDefault = true;
        
        return chunk;
    }
}

export default ChunkFactory;