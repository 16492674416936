import { IScreen } from './iScreen';

class OptionsScreen implements IScreen{

    constructor(){
        
    }

    setup(){
        
    }

    windowResized(): void {
        
    }

    draw(){
        buffer.background(0);

        buffer.textAlign(buffer.CENTER, buffer.CENTER);
        buffer.fill(255);
        buffer.textSize(15);
        buffer.noStroke();
        buffer.text("Options", 0, -buffer.height/2+30);
    }

    keyPressed(){
        if(p5js.keyCode === p5js.ESCAPE){
            currentScreen = "mainMenu";
            iScreen = screens[currentScreen];
            //iScreen = new MainMenuScreen(p5js);
            return;
        }
    }

    keyReleased(){
        
    }

    mousePressed(){
        
    }

    mouseReleased(){
        
    }

    touchStarted(){
        
    }

    touchEnded(){
        
    }

    touchMoved(): void {
        
    }

    mouseWheel(event: any): void {
        
    }

}

export default OptionsScreen;