import UIElement from "./uiElement";

class Label implements UIElement {
    x: number;
    y: number;
    w: number;
    h: number;
    action: Function;

    textSize: number = 12;
    text: string;

    constructor(text: string) {
        this.text = text;
        this.x = 0;
        this.y = 0;
        this.w = 20;
        this.h = 20;
        this.updateLayout();
    }

    updateLayout(): void {
        //calculate the width and height of the text
        buffer.textSize(this.textSize);
        this.w = buffer.textWidth(this.text) + 4;
        this.h = this.textSize + 4;
    }

    render(): void {
        buffer.fill(0);
        buffer.textSize(this.textSize);
        buffer.noStroke();
        buffer.textAlign(buffer.CENTER, buffer.BOTTOM);
        buffer.text(this.text, this.x, this.y, this.w, this.h);

        //draw line at the bottom
        // buffer.stroke(0);
        // buffer.strokeWeight(1);
        // buffer.line(this.x, this.y + this.h, this.x + this.w, this.y + this.h);
    }

    renderTooltip(): boolean {
        return false;
    }

    mousePressed(): boolean {
        return false;
    }

    mouseReleased(): boolean {
        return false;
    }

    keyPressed(): boolean {
        return false;
    }

    keyReleased(): boolean {
        return false;
    }

    scroll(event: WheelEvent): boolean {
        return false;
    }
}

export default Label;