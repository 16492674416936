import UIElement from "./uiElement";

class TextInput implements UIElement{
    x: number;
    y: number;
    w: number;
    h: number;
    action: Function;
    textSize: number = 12;
    text: string = "";
    tooltip: string = "";
    focused: boolean = false;
    locked: boolean = false;

    ALLOWED_CHARS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_=+[]{}|;:,.<>?/`~ ";

    constructor(w: number, h: number, text: string = "", tooltip : string = "Text input"){
        this.x = 0;
        this.y = 0;
        this.w = w;
        this.h = h;
        this.text = text;
        this.tooltip = tooltip;
    }

    updateLayout(): void {
        
    }

    render(): void {
        if(this.focused){
            buffer.fill(0);
        }else{  
            buffer.fill(80);
        }
        
        buffer.stroke(0);
        buffer.strokeWeight(1);
        buffer.rect(this.x, this.y, this.w, this.h);
        if(this.locked){
            buffer.fill(150);
        }else{
            buffer.fill(255);
        }
        buffer.textSize(this.textSize);
        buffer.noStroke();
        buffer.textAlign(buffer.LEFT, buffer.BOTTOM);
        buffer.text(this.text, this.x+2, this.y, this.w-4, this.h);

        //blinking cursor
        if(this.focused && !this.locked && p5js.frameCount % 60 < 30){
            let pos = buffer.textWidth(this.text) + 3;
            if(pos < this.w - 4){
                buffer.stroke(255);
                buffer.strokeWeight(2);
                buffer.line(this.x + pos, this.y + 2, this.x + pos, this.y + this.h - 2);
            }
        }
    }

    renderTooltip(): boolean {
        if(this.mouseInArea(this.x, this.y, this.w, this.h)){
            let posX = p5js.int((p5js.mouseX/pixelSize) - (buffer.width/2)) + 5;
            let posY = p5js.int((p5js.mouseY/pixelSize) - (buffer.height/2)) + 5;
            buffer.stroke(255);
            buffer.strokeWeight(1);
            buffer.fill(0, 200);
            buffer.textSize(10);
            let w = buffer.textWidth(this.tooltip) + 10;
            buffer.rect(posX, posY, w, 20);
            buffer.noStroke();
            buffer.fill(255);
            buffer.textAlign(buffer.LEFT, buffer.CENTER);
            buffer.text(this.tooltip, posX+5, posY+10);
            return true;
        }
        return false;
    }

    mousePressed(): boolean {
        if(!this.locked && this.mouseInArea(this.x, this.y, this.w, this.h)){
            this.focused = true;
            return true;
        }
        this.focused = false;
        return false;
    }

    mouseReleased(): boolean {
        return false;
    }

    keyPressed(): boolean {
        //debug keycodes for testing
        console.log(`keyCode: ${p5js.keyCode}, key: ${p5js.key}, keyIsDown: ${p5js.keyIsDown(17)}`);

        if(!this.locked && this.focused){
            if(p5js.keyCode === 46 || (p5js.keyCode === 8 && p5js.keyIsDown(17))){ //delete key to erase the text
                this.text = "";
            }else if(p5js.keyCode === 8){ //backspace
                this.text = this.text.substring(0, this.text.length - 1);
            }else if(p5js.keyCode === 13){ //enter
                this.focused = false;
                console.log(this.text);
                if(this.action){
                    this.action();
                }
            }else if(p5js.keyCode === 86 && p5js.keyIsDown(17)){ //ctrl + v //paste text from clipboard
                navigator.clipboard.readText().then(clipText => {
                    this.text += clipText;
                });
            }else if(p5js.keyCode === 67 && p5js.keyIsDown(17)){ //ctrl + c //copy text to clipboard
                navigator.clipboard.writeText(this.text); 
            }else if(p5js.keyCode === 88 && p5js.keyIsDown(17)){ //ctrl + x //cut text to clipboard
                navigator.clipboard.writeText(this.text); 
                this.text = "";
            }else{
                //accept only allowed characters, allow one character at a time
                if(this.ALLOWED_CHARS.includes(p5js.key)){
                    this.text += p5js.key;
                }
            }
            return true;
        }

        return false;
    }

    keyReleased(): boolean {
        return false;
    }

    scroll(event: WheelEvent): boolean {
        return false;
    }

    mouseInArea(x: number, y: number, w: number, h: number): boolean{
        let mx = p5js.mouseX/pixelSize - buffer.width/2;
        let my = p5js.mouseY/pixelSize - buffer.height/2;

        if(mx > x && mx < x + w && my > y && my < y + h){
            return true;
        }

        return false;
    }

}

export default TextInput;