// import { UIElementAlignment } from "../types";
import UIElement from "./uiElement";

class HorizontalView implements UIElement {
    x: number;
    y: number;
    w: number;
    h: number;

    spacing: number = 5;
    action: Function; //not used
    elements: UIElement[] = [];

    horizontalAlignment: string = "LEFT";
    verticalAlignment: string = "CENTER";

    show: boolean = true;
    background: boolean = true;

    constructor(x: number, y: number, w: number, h: number) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
    }

    addElement(element: UIElement): void {
        this.elements.push(element);
    }

    updateLayout(): void {
        if(this.horizontalAlignment === "LEFT"){
            let currentX = this.x + this.spacing;
            for(let element of this.elements){
                element.x = currentX;
                if(this.verticalAlignment === "TOP"){
                    element.y = this.y + this.spacing;
                }else if(this.verticalAlignment === "BOTTOM"){
                    element.y = this.y + this.h - element.h - this.spacing;
                }else if(this.verticalAlignment === "CENTER"){
                    element.y = this.y + (this.h - element.h)/2;
                }
                currentX += element.w + this.spacing;
            }
        }else if(this.horizontalAlignment === "RIGHT"){
            let currentX = this.x + this.w - this.spacing;
            for(let i = this.elements.length-1; i >= 0; i--){
                let element = this.elements[i];
                element.x = currentX - element.w;
                if(this.verticalAlignment === "TOP"){
                    element.y = this.y + this.spacing;
                }else if(this.verticalAlignment === "BOTTOM"){
                    element.y = this.y + this.h - element.h - this.spacing;
                }else if(this.verticalAlignment === "CENTER"){
                    element.y = this.y + (this.h - element.h)/2;
                }
                currentX -= element.w + this.spacing;
            }
        }else if(this.horizontalAlignment === "CENTER"){
            let totalWidth = 0;
            for(let element of this.elements){
                totalWidth += element.w + this.spacing;
            }
            let currentX = this.x + (this.w - totalWidth)/2;
            for(let element of this.elements){
                element.x = currentX;
                if(this.verticalAlignment === "TOP"){
                    element.y = this.y + this.spacing;
                }else if(this.verticalAlignment === "BOTTOM"){
                    element.y = this.y + this.h - element.h - this.spacing;
                }else if(this.verticalAlignment === "CENTER"){
                    element.y = this.y + (this.h - element.h)/2;
                }
                currentX += element.w + this.spacing;
            }
        }

        for(let element of this.elements){
            element.updateLayout();
        }
    }
    
    render(): void {
        if(!this.show){
            return;
        }

        if(this.background){
            buffer.fill(200);
            buffer.stroke(0);
            buffer.strokeWeight(1);
            buffer.rect(this.x, this.y, this.w, this.h);
        }

        for(let element of this.elements){
            element.render();
        }
    }

    renderTooltip(): boolean {
        if(!this.show){
            return false;
        }
        for(let element of this.elements){
            if(element.renderTooltip()){
                return true;
            }
        }
        return false;
    }

    mouseInArea(): boolean{
        let mx = p5js.mouseX/pixelSize - buffer.width/2;
        let my = p5js.mouseY/pixelSize - buffer.height/2;

        if(mx > this.x && mx < this.x + this.w && my > this.y && my < this.y + this.h){
            return true;
        }

        return false;
    }

    mousePressed(): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.mousePressed()){
                return true;
            }
        }

        if(this.mouseInArea()){
            return true;
        }

        return false;
    }

    mouseReleased(): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.mouseReleased()){
                return true;
            }
        }

        if(this.mouseInArea()){
            return true;
        }

        return false;
    }

    keyPressed(): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.keyPressed()){
                return true;
            }
        }
        return false;
    }

    keyReleased(): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.keyReleased()){
                return true;
            }
        }
        return false;
    }

    scroll(event: WheelEvent): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.scroll(event)){
                return true;
            }
        }

        if(this.mouseInArea()){
            return true;
        }

        return false;
    }
}

export default HorizontalView;