import { ObjectToDraw } from '../types';
import Entity from './entity';
import SpriteAnimationRenderer from '../spriteAnimationRenderer';
import Smoke from '../particles/smoke';
import TextPartilce from '../particles/textParticle';
import PathAi from '../pathFinding/pathAi';
import GameMap from '../game/gameMap';
import { angleToDirection, directionToAngle } from '../helpers';

class OtherPlayer implements Entity {
    NAME = 'otherPlayer';
    hash: string;

    x: number;
    y: number;
    vx: number;
    vy: number;
    gx: number;
    gy: number;
    currentDirection: number;

    isDead: boolean;
    speed: number;

    health: number;
    score: number;
    
    isRunning: boolean;
    isShooting: boolean;

    animIdle: SpriteAnimationRenderer;
    animRunning: SpriteAnimationRenderer;
    animShooting: SpriteAnimationRenderer;
    animShootingHold: SpriteAnimationRenderer;
    animRunningAndShooting: SpriteAnimationRenderer;
    animRunningAndShootingHold: SpriteAnimationRenderer;

    shootHoldStartTime: number;

    waypointX : number;
    waypointY : number;
    waypointShow : boolean;

    skin = "piti";

    constructor(sx: number, sy: number, hash: string | null = null) {
        if(hash){
            this.hash = hash;
        }else{
            this.hash = `otherPlayer_${sx}_${sy}`;
        }
        this.x = sx;
        this.y = sy;
        this.health = 100;
        this.gx = sx;
        this.gy = sy;
        this.vx = sx;
        this.vy = sy;
        this.speed = 4;
        this.currentDirection = 0;
        this.isDead = false;

        
        
        this.animIdle = new SpriteAnimationRenderer();
        this.animIdle.load(`/assets/player/${this.skin}/idle`);

        this.animRunning = new SpriteAnimationRenderer();
        this.animRunning.load(`/assets/player/${this.skin}/running`);

        this.animShooting = new SpriteAnimationRenderer();
        this.animShooting.load(`/assets/player/${this.skin}/shooting`);
        this.animShooting.repeats = false;

        this.animShootingHold = new SpriteAnimationRenderer();
        this.animShootingHold.load(`/assets/player/${this.skin}/shootingHold`);

        this.animRunningAndShooting = new SpriteAnimationRenderer();
        this.animRunningAndShooting.load(`/assets/player/${this.skin}/runningAndShooting`);
        this.animRunningAndShooting.repeats = false;

        this.animRunningAndShootingHold = new SpriteAnimationRenderer();
        this.animRunningAndShootingHold.load(`/assets/player/${this.skin}/runningAndHoldingShot`);
    }

    init() {
        console.log(`Player ${this.hash} initialised!`);
    }

    update() {
        // this.vx += (this.gx - this.vx) * 0.1;
        // this.vy += (this.gy - this.vy) * 0.1;
        // this.x = p5js.floor(this.vx);
        // this.y = p5js.floor(this.vy);

        if(p5js.dist(this.x, this.y, this.gx, this.gy) > 10){
            if(this.x > this.gx){
                this.x -= this.speed;
            }else if(this.x < this.gx){
                this.x += this.speed;
            }
        
            if(this.y > this.gy){
                this.y -= this.speed;
            }else if(this.y < this.gy){
                this.y += this.speed;
            }
        }else{
            this.x = this.gx;
            this.y = this.gy;
        }
    }

    render(objectsToDraw: ObjectToDraw[]) {
        let rX = p5js.floor(this.x);
        let rY = p5js.floor(this.y);
        let posOnScreen = {x: rX-player.x, y: rY-player.y};

        if(posOnScreen.x < -buffer.width/2-64 || posOnScreen.x > buffer.width/2+64 || posOnScreen.y < -buffer.height/2-64 || posOnScreen.y > buffer.height/2+64){
            return;
        }

        buffer.noStroke();
        buffer.fill(0, 100);
        buffer.ellipse(rX-player.x, rY-player.y, 50, 30);

        if(this.isShooting){
            if(!this.isRunning){
                if(this.animShooting.playing){
                    this.animShooting.render(objectsToDraw, rX-player.x, rY-player.y, this.currentDirection);
                    this.animRunningAndShooting.frame = this.animShooting.frame;
                }else{
                    this.animShootingHold.render(objectsToDraw, rX-player.x, rY-player.y, this.currentDirection);
                }
            }else{
                if(this.animRunningAndShooting.playing){
                    this.animRunningAndShooting.render(objectsToDraw, rX-player.x, rY-player.y, this.currentDirection);
                    this.animShooting.frame = this.animRunningAndShooting.frame;
                }else{
                    this.animRunningAndShootingHold.render(objectsToDraw, rX-player.x, rY-player.y, this.currentDirection);
                }
            }
        }else{
            if(this.isRunning){
                this.animRunning.render(objectsToDraw, rX-player.x, rY-player.y, this.currentDirection);
            }else{
                this.animIdle.render(objectsToDraw, rX-player.x, rY-player.y, this.currentDirection);
            }
        }
    }

    isToBeRemoved() {
        return this.isDead;
    }

    kill() {
        this.isDead = true;
    }

    hit(){
        this.health -= 0;
        particles.push(new TextPartilce(this.x, this.y, '-0hp'));
        if(this.health <= 0){
            this.health = 0;
            particles.push(new Smoke(this.x, this.y, 10));
            this.kill();
        }else{
            particles.push(new Smoke(this.x, this.y, 3));
        }
    }

    getHash(): string {
        return this.hash;
    }
}

export default OtherPlayer;