import P5 from 'p5';
import UIElement from './uiElement';
import { mouseInArea } from '../helpers';

class DropDownList implements UIElement{
    x: number = 0;
    y: number = 0;
    w: number = 80;
    h: number = 20;

    toolTip: string = 'Select an option';
    selectedOption: string = '';
    options: string[] = [];
    activated: boolean = false;
    action: Function;

    constructor(w: number, options: string[], toolTip: string) {
        this.w = w;
        this.toolTip = toolTip;
        this.options = options;
        if(options.length > 0){
            this.selectedOption = options[0];
        }else{
            this.selectedOption = 'No options';
        }
    }

    updateLayout(): void {
        
    }

    render() {
        
        buffer.noStroke();
        buffer.fill(180);
        buffer.rect(this.x, this.y, this.w, this.h);

        if(mouseInArea(this.x+this.w-20, this.y, 20, this.h)){
            buffer.fill(150);
        }else{
            buffer.fill(100);
        }
        buffer.rect(this.x+this.w-20, this.y, 20, this.h);


        buffer.fill(0);
        buffer.textAlign(buffer.LEFT, buffer.CENTER);
        buffer.textSize(10);
        buffer.noStroke();
        buffer.text(this.selectedOption, this.x + 10, this.y + this.h/2);

        buffer.textAlign(buffer.CENTER, buffer.CENTER);
        buffer.text("V", this.x + this.w - 10, this.y + this.h/2);

        
    }

    renderTooltip(){
        if(this.activated){
            for(let i = 0; i < this.options.length; i++){
                let y = this.y + this.h + i * this.h;
                if(mouseInArea(this.x, y, this.w, this.h)){
                    buffer.fill(180);
                }else{
                    buffer.fill(150);
                }
                buffer.rect(this.x, y, this.w, this.h);
                if(this.selectedOption == this.options[i]){
                    buffer.fill(0);
                }else{
                    buffer.fill(80);
                }
                buffer.textSize(10);
                buffer.textAlign(buffer.LEFT, buffer.CENTER);
                buffer.text(this.options[i], this.x + 10, y + this.h/2);
            }
            return true;
        }

        if(mouseInArea(this.x, this.y, this.w, this.h)){
            let posX = p5js.int((p5js.mouseX/pixelSize) - (buffer.width/2)) + 5;
            let posY = p5js.int((p5js.mouseY/pixelSize) - (buffer.height/2)) + 5;
            buffer.stroke(255);
            buffer.strokeWeight(1);
            buffer.fill(0, 200);
            buffer.textSize(10);
            let w = buffer.textWidth(this.toolTip) + 10;
            buffer.rect(posX, posY, w, 20);
            buffer.noStroke();
            buffer.fill(255);
            buffer.textAlign(buffer.LEFT, buffer.CENTER);
            buffer.text(this.toolTip, posX+5, posY+10);
            return true;
        }
        return false;
    }

    mousePressed() : boolean {
        let mx = p5js.mouseX/pixelSize - buffer.width/2;
        let my = p5js.mouseY/pixelSize - buffer.height/2;

        if(mouseInArea(this.x, this.y, this.w, this.h) && p5js.mouseIsPressed){
            console.log(`Drop down menu "${this.toolTip}" expanded`);
            this.activated = !this.activated;
            return true;
        }

        if(this.activated){
            for(let i = 0; i < this.options.length; i++){
                let y = this.y + this.h + i * this.h;
                if(mouseInArea(this.x, y, this.w, this.h) && p5js.mouseIsPressed){
                    this.selectedOption = this.options[i];
                    console.log(`Option "${this.selectedOption}" selected`);
                    this.activated = false;
                    if(this.action){
                        this.action();
                    }
                    return true;
                }
            }
        }

        this.activated = false;

        return false;
    }

    mouseReleased(): boolean {
        return false;
    }

    keyPressed(): boolean {
        return false;
    }

    keyReleased(): boolean {
        return false;
    }

    scroll(event: WheelEvent): boolean {
        return false;
    }

    pressed() : boolean {
        return false;
    }
}

export default DropDownList;