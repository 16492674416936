import Chunk from "../game/chunk";
import GameMap from "../game/gameMap";
import { saveJSON } from "../helpers";
import MapEditorScreen from "../mapEditorScreen";
import { WallData } from "../types";
import Button from "../ui/button";
import DropDownList from "../ui/dropDownList";
import InfiniteSlider from "../ui/infiniteSlider";
import Label from "../ui/label";
import PopupWindow from "../ui/popupWindow";
import TextInput from "../ui/textInput";
import UIElement from "../ui/uiElement";
import VerticalView from "../ui/verticalView";

class ChunkTool implements UIElement {
    x: number = 0;
    y: number = 0;
    w: number = 80;
    h: number = 20;
    action: Function;

    selectedChunk: Chunk | null = null;
    copiedChunk: Chunk | null = null;
    
    // ------ UI ------
    ui: VerticalView = new VerticalView(0, 0, 10, 10);

    selectTool = new Button(28, 28, "Select Chunk");
    addTemplate = new Button(28, 28, "Chunk templates");
    removeChunk = new Button(28, 28, "Delete Chunk Objects");
    copyChunk = new Button(28, 28, "Copy Chunk (Ctrl+C)");
    pasteChunk = new Button(28, 28, "Paste Chunk (Ctrl+V)");
    saveChunkBtn = new Button(28, 28, "Save Chunk");
    
    // ------ Properties ------
    properties: VerticalView = new VerticalView(0, 0, 10, 10);
    te_tileImage: TextInput = new TextInput(90, 20);
    te_liquid: DropDownList = new DropDownList(90, ["empty", "water", "lava"], "Select chunk liquid");

    constructor(private gameMap : GameMap, private editor: MapEditorScreen) {
        // ------ UI ------
        this.ui.horizontalAlignment = "LEFT";

        this.selectTool.setImage("/assets/ui/editor/select");
        this.addTemplate.setImage("/assets/ui/editor/add");
        this.removeChunk.setImage("/assets/ui/editor/delete");
        this.copyChunk.setImage("/assets/ui/editor/copy");
        this.pasteChunk.setImage("/assets/ui/editor/paste");
        this.saveChunkBtn.setImage("/assets/ui/editor/save");
        this.selectTool.activated = true;

        this.selectTool.action = () => {
            console.log("Select Chunk");
            this.selectTool.activated = true;
            this.addTemplate.activated = false;
            this.removeChunk.activated = false;
        }
        this.ui.addElement(this.selectTool);
        
        this.addTemplate.action = () => {
            console.log("Add Template");
            this.selectTool.activated = false;
            this.addTemplate.activated = true;
            this.removeChunk.activated = false;
        }
        this.ui.addElement(this.addTemplate);
        
        this.removeChunk.action = () => {
            this.editor.popupWindow = new PopupWindow("Delete Chunk Objects", "Are you sure you want to delete all objects in this chunk?\nThis action cannot be undone.");
            this.editor.popupWindow.action = () => {
                this.deleteChunkObjects();
            }
        }
        this.ui.addElement(this.removeChunk);

        this.copyChunk.action = () => {
            this.copyChunkObjects();
        }
        this.ui.addElement(this.copyChunk);

        this.pasteChunk.action = () => {
            this.pasteChunkObjects();
        }
        this.ui.addElement(this.pasteChunk);

        this.saveChunkBtn.action = () => {
            if(this.selectedChunk){
                this.saveChunk(this.selectedChunk);
            }
        }
        this.ui.addElement(this.saveChunkBtn);


        // ------ Properties ------

        this.properties.horizontalAlignment = "LEFT";
        this.properties.addElement(new Label("Tile image:"));

        this.te_tileImage.action = () => {
            if(this.selectedChunk){
                console.log("New tile image: ", this.te_tileImage.text);
                this.selectedChunk.data.tileImage = this.te_tileImage.text;
                this.selectedChunk.laodTileImage(this.selectedChunk.data.tileImage);
                this.saveChunk(this.selectedChunk);
            }
        }
        this.properties.addElement(this.te_tileImage);

        this.properties.addElement(new Label("Liquid:"));
        this.te_liquid.action = () => {
            if(this.selectedChunk){
                console.log("New liquid: ", this.te_liquid.selectedOption);
                this.selectedChunk.data.liquid = this.te_liquid.selectedOption;
                this.saveChunk(this.selectedChunk);
            }
        }
        this.properties.addElement(this.te_liquid);

        // ----------------------------
        // let deletObjectsButton = new Button(90, 20, "Delete Objects");
        // deletObjectsButton.action = () => {
        //     if(this.selectedChunk){
        //         this.selectedChunk.objects = [];
        //         this.selectedChunk.data.objects = [];
        //         this.selectedChunk.data.walls = [];
        //         this.selectedChunk.data.entities = [];
        //         this.saveChunk(this.selectedChunk);
        //     }
        // }
        // this.properties.addElement(deletObjectsButton);

        // // ----------------------------
        // let saveWallButton = new Button(90, 20, "Save Chunk");
        // saveWallButton.action = () => {
        //     if(this.selectedChunk){
        //         this.saveChunk(this.selectedChunk);
        //     }
        // }
        // this.properties.addElement(saveWallButton);
    }

    updateProperties(){
        if(this.selectedChunk){
            this.te_tileImage.text = this.selectedChunk.data.tileImage;
            if(!this.selectedChunk.data.liquid){
                this.selectedChunk.data.liquid = "";
            }
            this.te_liquid.selectedOption = this.selectedChunk.data.liquid;
        }
    }

    updateLayout(): void {
        this.ui.x = -buffer.width/2;
        this.ui.y = -buffer.height/2+this.editor.topBarHeight;
        this.ui.w = 38;
        this.ui.h = buffer.height-this.editor.topBarHeight;
        this.ui.updateLayout();

        this.properties.x = buffer.width/2 - 100;
        this.properties.y = -buffer.height/2+this.editor.topBarHeight;
        this.properties.w = 100;
        this.properties.h = buffer.height-this.editor.topBarHeight;
        this.properties.updateLayout();
    }

    render() {
        let mx = this.gameMap.posX + ((p5js.mouseX/pixelSize) - buffer.width/2) / this.editor.zoomLevel;
        let my = this.gameMap.posY + ((p5js.mouseY/pixelSize) - buffer.height/2) / this.editor.zoomLevel;


        if(this.selectedChunk){
            let cx = this.selectedChunk.tileX * Chunk.CHUNK_SIZE;
            let cy = this.selectedChunk.tileY * Chunk.CHUNK_SIZE;
            let screenPos = this.editor.getGamePosOnScreen(cx, cy);

            buffer.stroke(255, 0, 0);
            buffer.strokeWeight(1);
            buffer.noFill();
            buffer.rect(screenPos.x, screenPos.y, Chunk.CHUNK_SIZE*this.editor.zoomLevel, Chunk.CHUNK_SIZE*this.editor.zoomLevel);
        }

        this.ui.render();
        this.properties.render();
        
    }

    renderTooltip(): boolean {
        this.ui.renderTooltip();
        this.properties.renderTooltip();
        return false;
    }

    fixWall(wall: WallData): WallData {
        // Fix negative width
        if (wall.w < 0) {
            wall.x += wall.w; // Shift x by the width
            wall.w = -wall.w; // Make width positive
        }
    
        // Fix negative height
        if (wall.h < 0) {
            wall.y += wall.h; // Shift y by the height
            wall.h = -wall.h; // Make height positive
        }

        return wall;
    }

    saveChunk(chunk: Chunk){
        let path = `/assets/worlds/${this.gameMap.worldName}/chunk_${chunk.tileX}_${chunk.tileY}.json`;
        chunk.isDefault = false;
        console.log("Saving Chunk : ", path);
        saveJSON(chunk.data, path);
    }

    mousePressed(): boolean {
        if(this.ui.mousePressed()){
            return true;
        }

        if(this.properties.mousePressed()){
            return true;
        }

        if(p5js.mouseButton === p5js.LEFT && this.selectTool.activated){
            //get mouse position in chunk coordinates
            let mx = this.gameMap.posX + ((p5js.mouseX/pixelSize) - buffer.width/2) / this.editor.zoomLevel;
            let my = this.gameMap.posY + ((p5js.mouseY/pixelSize) - buffer.height/2) / this.editor.zoomLevel;
            let chunkX = Math.floor(mx / Chunk.CHUNK_SIZE);
            let chunkY = Math.floor(my / Chunk.CHUNK_SIZE);

            this.selectedChunk = this.gameMap.getChunk(chunkX, chunkY);
            this.updateProperties();
            console.log("Selected Chunk : ", this.selectedChunk);
        }

        return false;
    }

    mouseReleased(): boolean {
        
        

        if(this.ui.mouseReleased()){
            return true;
        }

        if(this.properties.mouseReleased()){
            return true;
        }

        return false;
    }

    deleteChunkObjects(){
        if(this.selectedChunk){
            this.selectedChunk.data.entities = [];
            this.selectedChunk.data.objects = [];
            this.selectedChunk.data.walls = [];
            // this.selectedChunk.data.tileImage = "";
            this.selectedChunk.objects = [];
            this.selectedChunk.data.liquid = "empty";
            this.saveChunk(this.selectedChunk);
            this.updateProperties();
            console.log("Chunk deleted");
        }
    }

    copyChunkObjects(){
        if(this.selectedChunk){
            this.copiedChunk = this.selectedChunk;
            console.log("Chunk copied");
        }
    }

    pasteChunkObjects(){
        if(this.copiedChunk && this.selectedChunk){
            let chunkDataCopy = structuredClone(this.copiedChunk.data);
            let chunkCopy = new Chunk(this.gameMap.worldName, this.selectedChunk.tileX, this.selectedChunk.tileY, chunkDataCopy);
            console.log("Chunk copy: ", chunkCopy);
            let chunkName = `${chunkCopy.world}_${chunkCopy.tileX}_${chunkCopy.tileY}`;
            this.gameMap.chunks[chunkName] = chunkCopy;
            this.selectedChunk = this.gameMap.getChunk(chunkCopy.tileX, chunkCopy.tileY);
            this.updateProperties();
            if(this.selectedChunk){
                this.saveChunk(this.selectedChunk);
            }
            console.log("Chunk pasted");
        }
    }

    keyPressed(): boolean {

        //copy chunk
        if(p5js.keyCode === 67 && p5js.keyIsDown(17) && this.selectedChunk){
            this.copyChunkObjects();
        }

        //paste chunk
        if(p5js.keyCode === 86 && p5js.keyIsDown(17) && this.copiedChunk && this.selectedChunk){
            this.pasteChunkObjects();
        }

        //delete chunk
        if(p5js.keyCode === 46 && this.selectedChunk){
            this.deleteChunkObjects();
        }

        if(this.ui.keyPressed()){
            return true;
        }

        if(this.properties.keyPressed()){
            return true;
        }

        return false;
    }

    keyReleased(): boolean {
        if(this.ui.keyReleased()){
            return true;
        }

        if(this.properties.keyReleased()){
            return true;
        }

        return false;
    }

    scroll(event: WheelEvent): boolean {
        if(this.ui.scroll(event)){
            return true;
        }

        if(this.properties.scroll(event)){
            return true;
        }

        return false;
    }
}

export default ChunkTool;