import GameMap from './game/gameMap';
import { IScreen } from './iScreen';
import { ObjectToDraw } from './types';
import Button from './ui/button';
import Chunk from './game/chunk';
import HorizontalView from './ui/horizontalView';
import UIElement from './ui/uiElement';
import WallTool from './editor/wallTool';
import GameScreen from './gameScreen';
import ChunkTool from './editor/chunkTool';
import ObjectTool from './editor/objectTool';
import WorldSelectTool from './editor/worldSelectTool';
import PopupWindow from './ui/popupWindow';
import VerticalSeparator from './ui/verticalSeparator';
import EntityTool from './editor/entityTool';

class MapEditorScreen implements IScreen{
    posX: number = 256;
    posY: number = 256;
    zoomLevel: number = 1;
    zoomGoal: number = 1;
    zoomLevels: number[] = [0.05, 0.1, 0.25, 0.5, 1];
    gameMap: GameMap = new GameMap();

    goUp: boolean = false;
    goDown: boolean = false;
    goLeft: boolean = false;
    goRight: boolean = false;
    speed: number = 5;
    topBarHeight: number = 38;
    snapToGrid: boolean = true;

    selectedTool: UIElement | null = null;
    
    //Tools
    worldSelectTool: WorldSelectTool | null = null;
    chunkTool: ChunkTool | null = null;
    objectTool: ObjectTool | null = null;
    wallTool: WallTool | null = null;
    entityTool: EntityTool | null = null;

    //tool buttons
    worldSelectBtn = new Button(28, 28, "Select World");
    chunkBtn = new Button(28, 28, "Chunk Tools");
    objectBtn = new Button(28, 28, "Object Tools");
    wallBtn = new Button(28, 28, "Area Tools");
    entityBtn = new Button(28, 28, "Entity Tools");

    //UI Elements
    topBar: HorizontalView = new HorizontalView(0, 0, 64, 64);
    popupWindow: PopupWindow | null = null;

    constructor(){
        this.speed = 5/this.zoomGoal;
        this.gameMap.unpackChunks = false;
        this.gameMap.loadWorld("world", this.posX, this.posY);
        this.gameMap.renderDistance = 5;
        this.gameMap.unloadChunkDistance = 15;
    }

    setup(){
        this.worldSelectTool = new WorldSelectTool(this.gameMap, this);
        this.worldSelectTool.updateLayout();
        this.wallTool = new WallTool(this.gameMap, this);
        this.wallTool.updateLayout();
        this.chunkTool = new ChunkTool(this.gameMap, this);
        this.chunkTool.updateLayout();
        this.objectTool = new ObjectTool(this.gameMap, this);
        this.objectTool.updateLayout();
        this.entityTool = new EntityTool(this.gameMap, this);
        this.entityTool.updateLayout();

        //World Tools
        this.worldSelectBtn.setImage("/assets/ui/editor/world");
        this.worldSelectBtn.action = () => {
            this.worldSelectBtn.activated = false;
            this.chunkBtn.activated = false;
            this.objectBtn.activated = false;
            this.wallBtn.activated = false;
            this.entityBtn.activated = false;
        
            this.selectedTool = this.worldSelectTool;
            this.worldSelectBtn.activated = true;
        }
        this.topBar.addElement(this.worldSelectBtn);

        //Chunk Tools
        this.chunkBtn.setImage("/assets/ui/editor/chunk");
        this.chunkBtn.action = () => {
            this.worldSelectBtn.activated = false;
            this.chunkBtn.activated = false;
            this.objectBtn.activated = false;
            this.wallBtn.activated = false;
            this.entityBtn.activated = false;

            this.selectedTool = this.chunkTool;
            this.chunkBtn.activated = true;
        }
        this.topBar.addElement(this.chunkBtn);

        //Object Tools
        this.objectBtn.setImage("/assets/ui/editor/objects");
        this.objectBtn.action = () => {
            this.worldSelectBtn.activated = false;
            this.chunkBtn.activated = false;
            this.objectBtn.activated = false;
            this.wallBtn.activated = false;
            this.entityBtn.activated = false;

            this.selectedTool = this.objectTool;
            this.objectBtn.activated = true;
        }
        this.topBar.addElement(this.objectBtn);

        //Wall Tools
        this.wallBtn.setImage("/assets/ui/editor/area");
        this.wallBtn.action = () => {
            this.worldSelectBtn.activated = false;
            this.chunkBtn.activated = false;
            this.objectBtn.activated = false;
            this.wallBtn.activated = false;
            this.entityBtn.activated = false;

            this.selectedTool = this.wallTool;
            this.wallBtn.activated = true;
        }
        this.topBar.addElement(this.wallBtn);

        //Entity Tools
        this.entityBtn.setImage("/assets/ui/editor/npc");
        this.entityBtn.action = () => {
            this.worldSelectBtn.activated = false;
            this.chunkBtn.activated = false;
            this.objectBtn.activated = false;
            this.wallBtn.activated = false;
            this.entityBtn.activated = false;

            this.selectedTool = this.entityTool;
            this.entityBtn.activated = true;
        }
        this.topBar.addElement(this.entityBtn);

        this.topBar.addElement(new VerticalSeparator(35));


        //Grid Button
        let gridButton = new Button(28, 28, "Show Grid");
        gridButton.setImage("/assets/ui/editor/grid");
        gridButton.activated = Chunk.showEditorGrid;
        gridButton.action = () => {
            Chunk.showEditorGrid = !Chunk.showEditorGrid;
            gridButton.activated = Chunk.showEditorGrid;
        }
        this.topBar.addElement(gridButton);

        //snapToGrid Button
        let snapButton = new Button(28, 28, "Snap to Grid");
        snapButton.activated = this.snapToGrid;
        snapButton.setImage("/assets/ui/editor/magnet");
        snapButton.action = () => {
            this.snapToGrid = !this.snapToGrid;
            snapButton.activated = this.snapToGrid;
        }
        this.topBar.addElement(snapButton);

        //Run game button
        let playButton = new Button(28, 28, "Run Game");
        playButton.setImage("/assets/ui/editor/play");
        playButton.action = () => {
            currentScreen = "game";
            iScreen = screens[currentScreen];
            // reload world in game screen (.gameMap.loadWorld('world', 256, 256);)
            let gameScreen = iScreen as GameScreen;
            gameScreen.gameMap.loadWorld(this.gameMap.worldName, this.posX, this.posY);
            player.x = this.posX;
            player.y = this.posY;

        }
        this.topBar.addElement(playButton);

        this.selectedTool = this.wallTool;
        this.wallBtn.activated = true;
        //update the layout of the top bar
        this.topBar.updateLayout();
    }

    windowResized(): void {
        if(this.topBar){
            //this.testView = new HorizontalView(-buffer.width/2, -buffer.height/2, buffer.width, 64);
            this.topBar.x = -buffer.width/2;
            this.topBar.y = -buffer.height/2;
            this.topBar.w = buffer.width;
            this.topBar.h = this.topBarHeight;
            this.topBar.updateLayout();
        }

        this.worldSelectTool?.updateLayout();
        this.wallTool?.updateLayout();
        this.chunkTool?.updateLayout();
        this.objectTool?.updateLayout();
        this.entityTool?.updateLayout();

        this.popupWindow?.updateLayout();
    }

    draw(){
        buffer.background(0);

        this.zoomLevel += (this.zoomGoal - this.zoomLevel) * 0.2;
        //if zoom level is close enough to the goal, set it to the goal
        if(Math.abs(this.zoomLevel - this.zoomGoal) < 0.001){
            this.zoomLevel = this.zoomGoal;
        }

        if(this.goUp){
            this.posY -= this.speed;
        }
        if(this.goDown){
            this.posY += this.speed;
        }
        if(this.goLeft){
            this.posX -= this.speed;
        }
        if(this.goRight){
            this.posX += this.speed;
        }

        //update the game map position
        this.gameMap.posX = this.posX;
        this.gameMap.posY = this.posY;

        this.gameMap.update();

        let objectsToDraw = [] as ObjectToDraw[];

        this.gameMap.renderEditorMap(objectsToDraw, this.zoomLevel);

        // Draw all objects in the sorted order
        objectsToDraw.sort((a, b) => a.z - b.z);
        for(let obj of objectsToDraw) {
            buffer.image(obj.image, obj.x, obj.y);
        }

        //draw cursor at cetner
        buffer.fill(255);
        buffer.noStroke();
        buffer.ellipse(0, 0, 5, 5);

        //display position and zoom level
        buffer.fill(255);
        buffer.noStroke();
        buffer.textAlign(buffer.CENTER, buffer.BOTTOM);
        buffer.textSize(10);
        let mPos = this.getMousePosInGame();
        buffer.text("Position: " + p5js.floor(mPos.x) + ", " + p5js.floor(mPos.y), 0, buffer.height/2-15);
        buffer.text("Zoom: " + (this.zoomGoal * 100) + "%", 0, buffer.height/2-5);


        //draw test view
        this.selectedTool?.render();
        this.topBar.render();
        this.popupWindow?.render();

        this.topBar.renderTooltip();
        this.selectedTool?.renderTooltip();
        this.popupWindow?.renderTooltip();
    }

    getMousePosInGame(): {x: number, y: number}{
        let mx = this.posX + ((p5js.mouseX/pixelSize) - buffer.width/2) / this.zoomLevel;
        let my = this.posY + ((p5js.mouseY/pixelSize) - buffer.height/2) / this.zoomLevel;
        return {x: mx, y: my};
    }

    getGamePosOnScreen(x: number, y: number): {x: number, y: number}{
        let mx = (x - this.posX) * this.zoomLevel;
        let my = (y - this.posY) * this.zoomLevel;
        return {x: mx, y: my};
    }

    keyPressed(){
        if(this.popupWindow?.keyPressed()){
            return;
        }

        if(this.topBar.keyPressed()){
            return;
        }

        if(this.selectedTool?.keyPressed()){
            return;
        }

        if(p5js.keyCode === p5js.ESCAPE){
            currentScreen = "mainMenu";
            iScreen = screens[currentScreen];
            //iScreen = new MainMenuScreen(p5js);
            return;
        }else if(p5js.keyCode === p5js.UP_ARROW || p5js.key.toLowerCase() === 'w'){
            this.goUp = true;
        }else if(p5js.keyCode === p5js.DOWN_ARROW || p5js.key.toLowerCase() === 's'){
            this.goDown = true;
        }else if(p5js.keyCode === p5js.LEFT_ARROW || p5js.key.toLowerCase() === 'a'){
            this.goLeft = true;
        }else if(p5js.keyCode === p5js.RIGHT_ARROW || p5js.key.toLowerCase() === 'd'){
            this.goRight = true;
        }

        //zoom in and out with = and -
        if(p5js.key === '='){
            let index = this.zoomLevels.indexOf(this.zoomGoal);
            if(index < this.zoomLevels.length-1){
                this.zoomGoal = this.zoomLevels[index+1];
                this.speed = 5/this.zoomGoal;
            }
        }else if(p5js.key === '-'){
            let index = this.zoomLevels.indexOf(this.zoomGoal);
            if(index > 0){
                this.zoomGoal = this.zoomLevels[index-1];
                this.speed = 5/this.zoomGoal;
            }
        }
    }

    keyReleased(){
        if(this.popupWindow?.keyReleased()){
            return;
        }

        if(this.topBar.keyReleased()){
            return;
        }

        if(this.selectedTool?.keyReleased()){
            return;
        }

        if(p5js.keyCode === p5js.UP_ARROW || p5js.key.toLowerCase() === 'w'){
            this.goUp = false;
        }else if(p5js.keyCode === p5js.DOWN_ARROW || p5js.key.toLowerCase() === 's'){
            this.goDown = false;
        }else if(p5js.keyCode === p5js.LEFT_ARROW || p5js.key.toLowerCase() === 'a'){
            this.goLeft = false;
        }else if(p5js.keyCode === p5js.RIGHT_ARROW || p5js.key.toLowerCase() === 'd'){
            this.goRight = false;
        }
    }

    mousePressed(){
        if(this.popupWindow?.mousePressed()){
            return;
        }

        if(this.topBar.mousePressed()){
            return;
        }

        if(this.selectedTool?.mousePressed()){
            return;
        }
    }

    mouseReleased(){
        if(this.popupWindow?.mouseReleased()){
            return;
        }

        if(this.topBar.mouseReleased()){
            return;
        }

        if(this.selectedTool?.mouseReleased()){
            return;
        }
    }

    touchStarted(){
        this.mousePressed();
    }

    touchEnded(){
        this.mouseReleased();
    }

    touchMoved(): void {
        
    }

    mouseWheel(event: any): void {
        if(this.popupWindow?.scroll(event)){
            return;
        }

        if(this.topBar.scroll(event)){
            return;
        }

        if(this.selectedTool?.scroll(event)){
            return;
        }

        if(event.delta > 0){
            let index = this.zoomLevels.indexOf(this.zoomGoal);
            if(index > 0){
                this.zoomGoal = this.zoomLevels[index-1];
                this.speed = 5/this.zoomGoal;
            }
        }else{
            let index = this.zoomLevels.indexOf(this.zoomGoal);
            if(index < this.zoomLevels.length-1){
                this.zoomGoal = this.zoomLevels[index+1];
                this.speed = 5/this.zoomGoal;
            }
        }
    }

}

export default MapEditorScreen;