import { ObjectToDraw, SpriteAnimData } from './../types';
import P5 from 'p5';
import Entity from './entity';

class Bullet implements Entity {
    NAME = 'bullet';
    hash: string;
    x: number;
    y: number;
    oldX: number;
    oldY: number
    vx: number = 0;
    vy: number = 0;
    speed: number;
    isDead: boolean;
    spawnTime: number;
    static image: P5.Image;

    constructor(sx: number, sy: number, angle: number, speed: number) {
        this.hash = `bullet_${sx}_${sy}`;
        this.x = sx;
        this.y = sy;
        this.oldX = this.x-player.x;
        this.oldY = this.y-player.y;
        this.vx = Math.cos(angle) * speed;
        this.vy = Math.sin(angle) * speed;

        this.speed = speed;
        this.isDead = false;
        this.spawnTime = p5js.millis();
        this.loadImage();
    }

    loadImage() {
        if(!Bullet.image){
            p5js.loadImage(`${staticServer}/assets/entities/bullet.png`, (img) => {
                Bullet.image = img;
            });
        }
    }

    init() {
        console.log('Chicken initialized');
    }

    update() {
        if(this.isDead){
            return;
        }

        this.x += this.vx;
        this.y += this.vy;
        //particles.push(new Smoke(p5js, player, this.x, this.y, 1));

        // check if bullet collides with other entities
        for(let entity of entities){
            if(entity != this && p5js.dist(this.x, this.y, entity.x, entity.y) < 32){
                entity.hit();
                this.kill();
            }
        }

        if(p5js.millis() - this.spawnTime > 1000){
            this.kill();
        }
    }

    render(objectsToDraw: ObjectToDraw[]) {
        let posOnScreen = {x: this.x-player.x, y: this.y-player.y};

        if(posOnScreen.x < -buffer.width/2-64 || posOnScreen.x > buffer.width/2+64 || posOnScreen.y < -buffer.height/2-64 || posOnScreen.y > buffer.height/2+64){
            return;
        }

        buffer.stroke(255);
        buffer.strokeWeight(2);
        buffer.line(this.oldX, this.oldY, posOnScreen.x, posOnScreen.y);

        if(Bullet.image){
            objectsToDraw.push({image: Bullet.image, x: posOnScreen.x-8, y: posOnScreen.y-8, z: posOnScreen.y+40});
        }

        this.oldX = posOnScreen.x;
        this.oldY = posOnScreen.y;
    }

    isToBeRemoved() {
        return this.isDead;
    }

    kill() {
        this.isDead = true;
    }

    hit(){

    }

    getHash() {
        return this.hash;
    }

    getPreviewImage(): P5.Image | null {
        return Bullet.image;
    }

    getPreviewData() : SpriteAnimData | null {
        return {
            centerX: 8,
            centerY: 8,
            frameWidth: 16,
            frameHeight: 16,
            framesCount: 1,
            rotationCount: 1,
            images: [[Bullet.image]]
        };
    }
}

export default Bullet;