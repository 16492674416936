import P5 from 'p5';
import UIElement from './uiElement';
import { mouseInArea } from '../helpers';

class Button implements UIElement{
    x: number = 0;
    y: number = 0;
    w: number = 80;
    h: number = 20;
    text: string = 'Button';
    image: P5.Image;
    wasPressed: boolean = false;
    action: Function;
    activated: boolean = false;

    constructor(w: number, h: number, text: string) {
        this.w = w;
        this.h = h;
        this.text = text;
    }

    updateLayout(): void {
        
    }

    setImage(asset: string){
        let path = `${staticServer}${asset}.png`;
        p5js.loadImage(path, (img) => {
            this.image = img;
        });
    }

    render() {
        // if(this.activated){
        //     buffer.stroke(255, 0, 0);
        //     buffer.strokeWeight(2);
        // }else{
        //     buffer.noStroke();
        // }

        buffer.stroke(150);
        buffer.strokeWeight(1);

        if(mouseInArea(this.x, this.y, this.w, this.h)){
            buffer.fill(255);
        }else{
            if(this.activated){
                buffer.fill(96, 104, 230);
            }else{
                buffer.fill(180);
            }
        }

        buffer.rect(this.x, this.y, this.w, this.h);
        buffer.fill(0);

        if(this.image){
            // if the image is bigger than the button
            if(this.image.width > this.w || this.image.height > this.h){
                buffer.image(this.image, this.x, this.y, this.w, this.h);
            }else{
                // position the image in the center of the button
                let imgX = this.x + (this.w - this.image.width)/2;
                let imgY = this.y + (this.h - this.image.height)/2;
                buffer.image(this.image, imgX, imgY);
            }
        }else{
            buffer.textAlign(buffer.CENTER, buffer.CENTER);
            buffer.textSize(10);
            buffer.noStroke();
            buffer.text(this.text, this.x + this.w/2, this.y + this.h/2);
        }

        
    }

    renderTooltip(){
        if(mouseInArea(this.x, this.y, this.w, this.h)){
            let posX = p5js.int((p5js.mouseX/pixelSize) - (buffer.width/2)) + 5;
            let posY = p5js.int((p5js.mouseY/pixelSize) - (buffer.height/2)) + 5;
            buffer.stroke(255);
            buffer.strokeWeight(1);
            buffer.fill(0, 200);
            buffer.textSize(10);
            let w = buffer.textWidth(this.text) + 10;
            buffer.rect(posX, posY, w, 20);
            buffer.noStroke();
            buffer.fill(255);
            buffer.textAlign(buffer.LEFT, buffer.CENTER);
            buffer.text(this.text, posX+5, posY+10);
            return true;
        }
        return false;
    }

    mousePressed() : boolean {
        let mx = p5js.mouseX/pixelSize - buffer.width/2;
        let my = p5js.mouseY/pixelSize - buffer.height/2;

        if(mouseInArea(this.x, this.y, this.w, this.h) && p5js.mouseIsPressed){
            console.log(`Button ${this.text} pressed`);
            if(this.action){
                this.action();
            }else{
                
                this.wasPressed = true;
            }
            return true;
        }

        return false;
    }

    mouseReleased(): boolean {
        return false;
    }

    keyPressed(): boolean {
        return false;
    }

    keyReleased(): boolean {
        return false;
    }

    scroll(event: WheelEvent): boolean {
        return false;
    }

    pressed() : boolean {
        if(this.wasPressed){
            this.wasPressed = false;
            return true;
        }
        return false;
    }
}

export default Button;