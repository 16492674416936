import Chunk from "../game/chunk";
import GameMap from "../game/gameMap";
import { saveJSON } from "../helpers";
import MapEditorScreen from "../mapEditorScreen";
import PreviewImageCache from "../previewImageCache";
import { EntityData, WallData } from "../types";
import Button from "../ui/button";
import InfiniteSlider from "../ui/infiniteSlider";
import Label from "../ui/label";
import ScrollGridView from "../ui/scrollGridView";
import TextInput from "../ui/textInput";
import UIElement from "../ui/uiElement";
import VerticalView from "../ui/verticalView";

class EntityTool implements UIElement {
    x: number = 0;
    y: number = 0;
    w: number = 80;
    h: number = 20;
    action: Function;

    selectedChunk: Chunk | null = null;
    selectedEntity: EntityData | null = null;
    entityToAdd: EntityData | null = null;
    
    // ------ UI ------
    ui: VerticalView = new VerticalView(0, 0, 10, 10);

    selectEntityBtn = new Button(28, 28, "Select Entity");
    moveEntityBtn = new Button(28, 28, "Move Object");
    addEntityTool = new Button(28, 28, "Add Object");
    removeEntityBtn = new Button(28, 28, "Delete Object");
    saveChunkBtn = new Button(28, 28, "Save Chunk");
    
    // ------ Properties ------
    properties: VerticalView = new VerticalView(0, 0, 10, 10);
    te_objectName: TextInput = new TextInput(90, 20, "no value", "Entity Name");
    sl_objectPosX: InfiniteSlider = new InfiniteSlider("Pos X", 90);
    sl_objectPosY: InfiniteSlider = new InfiniteSlider("Pos Y", 90);

    // ------ Add Object Folder ------
    objectFolder: ScrollGridView = new ScrollGridView(0, 0, 10, 10);



    constructor(private gameMap : GameMap, private editor: MapEditorScreen) {
        // ------ Images ------
        PreviewImageCache.loadImage("/assets/entities/chicken/preview", "chicken");
        PreviewImageCache.loadImage("/assets/entities/testEntity/preview", "testEntity");
        PreviewImageCache.loadImage("/assets/entities/coin/preview", "coin");

        // ------ UI ------
        this.ui.horizontalAlignment = "LEFT";

        this.selectEntityBtn.setImage("/assets/ui/editor/select");
        this.moveEntityBtn.setImage("/assets/ui/editor/move");
        this.addEntityTool.setImage("/assets/ui/editor/add");
        this.removeEntityBtn.setImage("/assets/ui/editor/delete");
        this.saveChunkBtn.setImage("/assets/ui/editor/save");

        this.selectEntityBtn.activated = true;

        this.selectEntityBtn.action = () => {
            console.log("Select Entity");
            this.selectEntityBtn.activated = true;
            this.addEntityTool.activated = false;
            this.removeEntityBtn.activated = false;
            this.moveEntityBtn.activated = false;

            this.objectFolder.show = false;

            this.updateProperties();
        }
        this.ui.addElement(this.selectEntityBtn);

        this.moveEntityBtn.action = () => {
            console.log("Move Entity");
            this.selectEntityBtn.activated = false;
            this.addEntityTool.activated = false;
            this.removeEntityBtn.activated = false;
            this.moveEntityBtn.activated = true;

            this.properties.show = false;
            this.objectFolder.show = false;
            this.selectedChunk = null;
            this.selectedEntity = null;
        }
        this.ui.addElement(this.moveEntityBtn);
        
        this.addEntityTool.action = () => {
            console.log("Add Entity");
            this.selectEntityBtn.activated = false;
            this.addEntityTool.activated = true;
            this.removeEntityBtn.activated = false;
            this.moveEntityBtn.activated = false;

            this.properties.show = false;
            this.objectFolder.show = true;

            this.selectedChunk = null;
            this.selectedEntity = null;
        }
        this.ui.addElement(this.addEntityTool);
        
        this.removeEntityBtn.action = () => {
            console.log("Delete Entity");
            this.selectEntityBtn.activated = false;
            this.addEntityTool.activated = false;
            this.removeEntityBtn.activated = true;
            this.moveEntityBtn.activated = false;

            //this.properties.show = false;
            this.objectFolder.show = false;
            // this.selectedChunk = null;
            // this.selectedEntity = null;
        }
        this.ui.addElement(this.removeEntityBtn);

        this.saveChunkBtn.action = () => {
            console.log("Save Chunk");
            if(this.selectedChunk){
                this.saveChunk(this.selectedChunk);
            }
        }
        this.ui.addElement(this.saveChunkBtn);
        

        // ------ Properties ------

        this.properties.horizontalAlignment = "LEFT";
        this.properties.show = false;

        this.properties.addElement(new Label("Selected Entity:"));
        this.te_objectName.locked = true;
        this.properties.addElement(this.te_objectName);

        this.sl_objectPosX.action = () => {
            if(this.selectedEntity){
                this.selectedEntity.x = this.sl_objectPosX.value;
            }
        }
        this.properties.addElement(this.sl_objectPosX);

        this.sl_objectPosY.action = () => {
            if(this.selectedEntity){
                this.selectedEntity.y = this.sl_objectPosY.value;
            }
        }
        this.properties.addElement(this.sl_objectPosY);
        

        // delete object button
        let deletObjectsButton = new Button(90, 20, "Delete Entity");
        deletObjectsButton.action = () => {
            if(this.selectedChunk && this.selectedEntity){
                let index = this.selectedChunk.data.entities.indexOf(this.selectedEntity);
                if(index > -1){
                    this.selectedChunk.data.entities.splice(index, 1);
                    this.selectedEntity = null;
                    this.updateProperties();
                    this.saveChunk(this.selectedChunk);
                }
            }
        }
        this.properties.addElement(deletObjectsButton);

        // save wall button
        let saveWallButton = new Button(90, 20, "Save Chunk");
        saveWallButton.action = () => {
            if(this.selectedChunk){
                this.saveChunk(this.selectedChunk);
            }
        }
        this.properties.addElement(saveWallButton);

        // ------ Add Object Folder ------

        this.objectFolder.show = false;

        let entityList = ["testEntity", "chicken", "coin"];

        for(let i = 0; i < entityList.length; i++){
            let entityName = entityList[i];
            let entityData : EntityData = {x: 0, y: 0, name: entityName};
            //let object = new GameObject(objectData);
            let objectButton = new Button(40, 40, entityName);
            objectButton.setImage(`/assets/entities/${entityName}/preview`);

            objectButton.action = () => {
                this.entityToAdd = entityData;
                //deactivate all buttons in the view
                for(let j = 0; j < this.objectFolder.elements.length; j++){
                    let button = this.objectFolder.elements[j] as Button;
                    button.activated = false;
                }
                objectButton.activated = true;
            }
            this.objectFolder.addElement(objectButton);
        }
    }

    updateProperties(){
        if(this.selectedEntity){
            this.te_objectName.text = this.selectedEntity.name;
            this.sl_objectPosX.value = this.selectedEntity.x;
            this.sl_objectPosY.value = this.selectedEntity.y;
            this.properties.show = true;
        }else{
            this.properties.show = false;
        }
    }

    updateLayout(): void {
        this.ui.x = -buffer.width/2;
        this.ui.y = -buffer.height/2+this.editor.topBarHeight;
        this.ui.w = 38;
        this.ui.h = buffer.height-this.editor.topBarHeight;
        this.ui.updateLayout();

        //right side panel
        this.properties.x = buffer.width/2 - 100;
        this.properties.y = -buffer.height/2+this.editor.topBarHeight;
        this.properties.w = 100;
        this.properties.h = buffer.height-this.editor.topBarHeight;
        this.properties.updateLayout();

        //right side panel
        this.objectFolder.x = buffer.width/2 - 185;
        this.objectFolder.y = -buffer.height/2+this.editor.topBarHeight;
        this.objectFolder.w = 185;
        this.objectFolder.h = buffer.height-this.editor.topBarHeight;
        this.objectFolder.updateLayout();
    }

    render() {
        let mx = this.gameMap.posX + ((p5js.mouseX/pixelSize) - buffer.width/2) / this.editor.zoomLevel;
        let my = this.gameMap.posY + ((p5js.mouseY/pixelSize) - buffer.height/2) / this.editor.zoomLevel;


        if(this.selectedChunk && this.selectedEntity){
            buffer.stroke(255, 0, 255);
            buffer.strokeWeight(1);
            buffer.fill(255, 0, 255, 50);
            this.drawRectangleOnEntity(this.selectedEntity, this.selectedChunk);
        }

        //highlight all objects in the chunk
        if(this.selectEntityBtn.activated || this.removeEntityBtn.activated || this.moveEntityBtn.activated){
            let chunkX = Math.floor(mx / Chunk.CHUNK_SIZE);
            let chunkY = Math.floor(my / Chunk.CHUNK_SIZE);
            let chunk = this.gameMap.getChunk(chunkX, chunkY);
            if(chunk){
                let entities = chunk.data.entities;
                for(let i = 0; i < entities.length; i++){
                    let ent = entities[i];

                    if(this.selectedEntity === ent){
                        continue;
                    }
                    buffer.stroke(255, 100);
                    buffer.strokeWeight(1);
                    buffer.noFill();
                    this.drawRectangleOnEntity(ent, chunk);
                }
            }
        }

        if((this.addEntityTool.activated || this.moveEntityBtn.activated) && this.entityToAdd){
            //render preview of the object
            let pos = this.editor.getMousePosInGame();
            let posX = p5js.floor(pos.x);
            let posY = p5js.floor(pos.y);
            if(this.editor.snapToGrid){
                posX = p5js.round(pos.x / 16) * 16;
                posY = p5js.round(pos.y / 16) * 16;
            }
            let screenPos = this.editor.getGamePosOnScreen(posX, posY);
            let entPrevData = PreviewImageCache.getImageData(this.entityToAdd.name);
            if(entPrevData){
                let px = screenPos.x - entPrevData.x * this.editor.zoomLevel;
                let py = screenPos.y - entPrevData.y * this.editor.zoomLevel;
                buffer.tint(255, 255, 255, 127);
                buffer.image(entPrevData.image, px, py, entPrevData.image.width * this.editor.zoomLevel, entPrevData.image.height * this.editor.zoomLevel);
                buffer.noTint();
            }else{
                buffer.fill(0, 0, 0, 127);
                buffer.ellipse(screenPos.x, screenPos.y, 32 * this.editor.zoomLevel, 16 * this.editor.zoomLevel);
            }
        }

        if(this.selectedChunk){
            let cx = this.selectedChunk.tileX * Chunk.CHUNK_SIZE;
            let cy = this.selectedChunk.tileY * Chunk.CHUNK_SIZE;
            let screenPos = this.editor.getGamePosOnScreen(cx, cy);

            buffer.stroke(255, 0, 0);
            buffer.strokeWeight(1);
            buffer.noFill();
            buffer.rect(screenPos.x, screenPos.y, Chunk.CHUNK_SIZE*this.editor.zoomLevel, Chunk.CHUNK_SIZE*this.editor.zoomLevel);
        }

        this.ui.render();
        this.properties.render();
        this.objectFolder.render();
    }

    drawRectangleOnEntity(ent: EntityData, chunk: Chunk){
        if(!chunk || !ent){
            return;
        }

        let cx = chunk.tileX * Chunk.CHUNK_SIZE;
        let cy = chunk.tileY * Chunk.CHUNK_SIZE;
        let screenPos = this.editor.getGamePosOnScreen(cx, cy);
        if(PreviewImageCache.imageExists(ent.name)){
            let data = PreviewImageCache.getImageData(ent.name);
            if(data){
                let width = data.image.width * this.editor.zoomLevel;
                let height = data.image.height * this.editor.zoomLevel;
                let centerX = data.x;
                let centerY = data.y;
                let posX = screenPos.x + (ent.x - centerX) * this.editor.zoomLevel;
                let posY = screenPos.y + (ent.y - centerY) * this.editor.zoomLevel;
                buffer.rect(posX, posY, width, height);
            }
        }else{
            let size = 32;
            let posX = screenPos.x + (ent.x - size/2) * this.editor.zoomLevel;
            let posY = screenPos.y + (ent.y - size/2) * this.editor.zoomLevel;
            buffer.rect(posX, posY, size * this.editor.zoomLevel, size * this.editor.zoomLevel);
        }
    }


    renderTooltip(): boolean {
        this.ui.renderTooltip();
        this.properties.renderTooltip();
        this.objectFolder.renderTooltip();
        return false
    }

    fixWall(wall: WallData): WallData {
        // Fix negative width
        if (wall.w < 0) {
            wall.x += wall.w; // Shift x by the width
            wall.w = -wall.w; // Make width positive
        }
    
        // Fix negative height
        if (wall.h < 0) {
            wall.y += wall.h; // Shift y by the height
            wall.h = -wall.h; // Make height positive
        }

        return wall;
    }

    saveChunk(chunk: Chunk){
        let path = `/assets/worlds/${this.gameMap.worldName}/chunk_${chunk.tileX}_${chunk.tileY}.json`;
        chunk.isDefault = false;
        console.log("Saving Chunk : ", path);
        saveJSON(chunk.data, path);
    }

    mousePressed(): boolean {
        if(this.ui.mousePressed()){
            return true;
        }

        if(this.properties.mousePressed()){
            return true;
        }

        if(this.objectFolder.mousePressed()){
            return true;
        }

        if(p5js.mouseButton === p5js.LEFT){
            //get mouse position in chunk coordinates
            let mx = this.gameMap.posX + ((p5js.mouseX/pixelSize) - buffer.width/2) / this.editor.zoomLevel;
            let my = this.gameMap.posY + ((p5js.mouseY/pixelSize) - buffer.height/2) / this.editor.zoomLevel;
            let chunkX = Math.floor(mx / Chunk.CHUNK_SIZE);
            let chunkY = Math.floor(my / Chunk.CHUNK_SIZE);

            let chunk = this.gameMap.getChunk(chunkX, chunkY);
            
            if(chunk){
                let posOnTileX = p5js.floor(mx - chunkX * Chunk.CHUNK_SIZE);
                let posOnTileY = p5js.floor(my - chunkY * Chunk.CHUNK_SIZE);

                //select or delete object
                if(this.selectEntityBtn.activated || this.removeEntityBtn.activated || this.moveEntityBtn.activated){
                    let entities = chunk.data.entities;
                    for(let i = 0; i < entities.length; i++){
                        let ent = entities[i];

                        // let width = obj.getAnimData().width;
                        // let height = obj.getAnimData().height;
                        // let centerX = obj.getAnimData().centerX;
                        // let centerY = obj.getAnimData().centerY;
                        let posX = ent.x;// - centerX;
                        let posY = ent.y;// - centerY;

                        //if(posOnTileX > posX && posOnTileX < posX + width && posOnTileY > posY && posOnTileY < posY + height){
                        if(p5js.dist(posOnTileX, posOnTileY, posX, posY) < 16){
                            if(this.removeEntityBtn.activated || this.moveEntityBtn.activated){
                                let index = chunk.data.entities.indexOf(ent);
                                if(index > -1){
                                    chunk.data.entities.splice(index, 1);
                                    this.selectedEntity = null;
                                    this.updateProperties();
                                    this.saveChunk(chunk);
                                    if(this.moveEntityBtn.activated){
                                        console.log("Move Object: ", ent);
                                        this.entityToAdd = ent;
                                    }
                                }
                                break;
                            }else if (this.selectEntityBtn.activated){
                                if(this.selectedEntity === ent){
                                    continue;
                                }
                                this.selectedEntity = ent;
                                this.selectedChunk = chunk;
                                this.updateProperties();
                                console.log("Selected Object: ", this.selectedEntity);
                                break;
                            }
                        }
                    }
                }else if(this.addEntityTool.activated){
                    if(this.entityToAdd){
                        if(this.editor.snapToGrid){
                            posOnTileX = p5js.round(posOnTileX / 16) * 16;
                            posOnTileY = p5js.round(posOnTileY / 16) * 16;
                        }
                        let entityData : EntityData = {x: posOnTileX, y: posOnTileY, name: this.entityToAdd.name};
                        chunk.data.entities.push(entityData);
                        this.saveChunk(chunk);
                    }
                }
            }

            //debug, select first object
            // if(this.selectedChunk && this.selectedChunk.objects.length > 0){
            //     this.selectedObject = this.selectedChunk.objects[0];
            //     this.updateProperties();
            //     console.log("Selected Object : ", this.selectedObject);
            // }

            //this.updateProperties();
            //console.log("Selected Chunk : ", this.selectedChunk);
        }

        return false;
    }

    mouseReleased(): boolean {
        //place down dragged object 
        if(this.moveEntityBtn.activated && this.entityToAdd){
            let mPos = this.editor.getMousePosInGame();
            let chunkX = Math.floor(mPos.x / Chunk.CHUNK_SIZE);
            let chunkY = Math.floor(mPos.y / Chunk.CHUNK_SIZE);
            let chunk = this.gameMap.getChunk(chunkX, chunkY);
            if(chunk){
                let posOnTileX = p5js.floor(mPos.x - chunkX * Chunk.CHUNK_SIZE);
                let posOnTileY = p5js.floor(mPos.y - chunkY * Chunk.CHUNK_SIZE);

                if(this.editor.snapToGrid){
                    posOnTileX = p5js.round(posOnTileX / 16) * 16;
                    posOnTileY = p5js.round(posOnTileY / 16) * 16;
                }
                this.entityToAdd.x = posOnTileX;
                this.entityToAdd.y = posOnTileY;

                chunk.data.entities.push(this.entityToAdd);
                //chunk.data.objects.push(this.objectToAdd.data);

                this.selectedEntity = this.entityToAdd;
                this.entityToAdd = null;
                
                this.updateProperties();
                this.saveChunk(chunk);
            }
        }

        if(this.ui.mouseReleased()){
            return true;
        }

        if(this.properties.mouseReleased()){
            return true;
        }

        if(this.objectFolder.mouseReleased()){
            return true;
        }

        return false;
    }

    keyPressed(): boolean {
        if(this.ui.keyPressed()){
            return true;
        }

        if(this.properties.keyPressed()){
            return true;
        }

        if(this.objectFolder.keyPressed()){
            return true;
        }

        if(p5js.keyCode === p5js.DELETE){
            if(this.selectedChunk && this.selectedEntity){
                let index = this.selectedChunk.data.entities.indexOf(this.selectedEntity);
                if(index > -1){
                    this.selectedChunk.data.entities.splice(index, 1);
                    this.selectedEntity = null;
                    this.updateProperties();
                    this.saveChunk(this.selectedChunk);
                }
            }
        }

        return false;
    }

    keyReleased(): boolean {
        if(this.ui.keyReleased()){
            return true;
        }

        if(this.properties.keyReleased()){
            return true;
        }

        if(this.objectFolder.keyReleased()){
            return true;
        }

        return false;
    }

    scroll(event: WheelEvent): boolean {
        if(this.ui.scroll(event)){
            return true;
        }

        if(this.properties.scroll(event)){
            return true;
        }

        if(this.objectFolder.scroll(event)){
            return true;
        }

        return false;
    }
}

export default EntityTool;