import { ObjectToDraw, SpriteAnimData } from './../types';
import P5 from 'p5';
import Entity from './entity';
import SpriteAnimationRenderer from '../spriteAnimationRenderer';
import Player from '../game/player';
import Smoke from '../particles/smoke';
import TextPartilce from '../particles/textParticle';
import GameMap from '../game/gameMap';

class Chicken implements Entity {
    NAME = 'chicken';
    hash: string;
    x: number;
    y: number;
    vx: number = 0;
    vy: number = 0;
    gx: number;
    gy: number;
    speed: number;
    currentDirection: number;
    state: string;
    oldState: string;
    startWalkingTime: number;
    endRunningTime: number;
    isDead: boolean;
    animIdle: SpriteAnimationRenderer;
    animRunning: SpriteAnimationRenderer;
    animWalking: SpriteAnimationRenderer;
    health: number;

    constructor(private gameMap: GameMap, sx: number, sy: number) {
        this.hash = `chicken_${sx}_${sy}`;
        this.x = sx;
        this.y = sy;
        this.health = 100;
        this.gx = 0;
        this.gy = 0;
        this.speed = 5;
        this.currentDirection = 7;
        this.state = 'idle';
        this.startWalkingTime = p5js.millis();
        this.isDead = false;
        
        this.animIdle = new SpriteAnimationRenderer();
        this.animIdle.load('/assets/entities/chicken/idle');

        this.animRunning = new SpriteAnimationRenderer();
        this.animRunning.load('/assets/entities/chicken/running');

        this.animWalking = new SpriteAnimationRenderer();
        this.animWalking.load('/assets/entities/chicken/walking');
    }

    init() {
        console.log('Chicken initialized');
    }

    update() {
        let tmpX = this.x;
        let tmpY = this.y;

        let distance = p5js.dist(player.x, player.y, this.x, this.y)
        if(distance < 100 || this.endRunningTime > p5js.millis()){
            this.state = 'running';
        }else if(distance > 200 && this.state == 'running'){
            this.state = 'walking';
            this.gx = this.x + p5js.int(p5js.random(-256, 256));
            this.gy = this.y + p5js.int(p5js.random(-256, 256));
        }

        if(this.state == 'running'){
            let angle = p5js.atan2(player.y-this.y, player.x-this.x);
            this.vx = p5js.int(-p5js.cos(angle) * this.speed);
            this.vy = p5js.int(-p5js.sin(angle) * this.speed);
            this.currentDirection = (p5js.int(p5js.map(p5js.atan2(this.vy, this.vx), -p5js.PI, p5js.PI, 0, 7))+3) % 8;
        }else if(this.state == 'walking'){
            let distanceToGoal = p5js.dist(this.x, this.y, this.gx, this.gy);

            if (distanceToGoal > 20) {
                // Reset velocities
                this.vx = 0;
                this.vy = 0;

                // Check x direction
                if (Math.abs(this.x - this.gx) < 5) {
                    this.vx = 0;
                } else if (this.x < this.gx) {
                    this.vx = 1;
                } else {
                    this.vx = -1;
                }

                // Check y direction
                if (Math.abs(this.y - this.gy) < 5) {
                    this.vy = 0;
                } else if (this.y < this.gy) {
                    this.vy = 1;
                } else {
                    this.vy = -1;
                }

                // Calculate the current direction
                this.currentDirection = (p5js.int(p5js.map(p5js.atan2(this.vy, this.vx), -p5js.PI, p5js.PI, 0, 7)) + 3) % 8;

                // Update position
                this.x += this.vx;
                this.y += this.vy;
            }else{
                this.startWalkingTime = p5js.int(p5js.millis() + p5js.random(5000, 20000));
                this.state = 'idle';
                this.vx = 0;
                this.vy = 0;
            }
        }else if (this.state == 'idle'){
            this.vx = 0;
            this.vy = 0;

            if(p5js.millis() > this.startWalkingTime){
                this.state = 'walking';
                this.gx = this.x + p5js.int(p5js.random(-100, 100));
                this.gy = this.y + p5js.int(p5js.random(-100, 100));
            }
        }

        //check if the mouse is over the chicken
        // let posX = p5js.int((p5js.mouseX/pixelSize) - (buffer.width/2));
        // let posY = p5js.int((p5js.mouseY/pixelSize) - (buffer.height/2));

        // if(p5js.dist(posX, posY, this.x-player.x, this.y-player.y) < 30){
        //     if(p5js.mouseIsPressed){
        //         player.score += 10;
        //         this.isDead = true;
        //     }
        // }

        tmpX += this.vx;
        tmpY += this.vy;

        if(true){ //TODO, check if the mob is colliding with obstacle
            this.x = tmpX;
            this.y = tmpY;
        }

        if(this.oldState != this.state){
            if(this.state == 'running'){
                //particles.push(new Smoke(p5js, player, this.x, this.y, 3));
            }

        }
        this.oldState = this.state;
    }

    render(objectsToDraw: ObjectToDraw[]) {
        let posOnScreen = {x: this.x-player.x, y: this.y-player.y};

        if(posOnScreen.x < -buffer.width/2-64 || posOnScreen.x > buffer.width/2+64 || posOnScreen.y < -buffer.height/2-64 || posOnScreen.y > buffer.height/2+64){
            return;
        }

        if(showDebug){
            buffer.stroke(255);
            buffer.strokeWeight(1);
            buffer.line(this.x-player.x, this.y-player.y, this.gx-player.x, this.gy-player.y);

            buffer.noStroke();
            buffer.fill(255, 0, 0);
            buffer.ellipse(this.gx-player.x, this.gy-player.y, 10, 10);

            buffer.fill(255);
            buffer.textAlign(p5js.CENTER, p5js.TOP);
            let time = p5js.int((this.startWalkingTime-p5js.millis())/1000);
            if(time < 0){
                time = 0;
            }
            buffer.text(this.state + ": " + time + "s", this.x-player.x, this.y-player.y+10);
        }

        buffer.noStroke();
        buffer.fill(0, 100);
        buffer.ellipse(this.x-player.x, this.y-player.y, 30, 15);

        
        if(this.state == 'running'){
            this.animRunning.render(objectsToDraw, this.x-player.x, this.y-player.y, this.currentDirection);
        }else if(this.state == 'walking'){
            this.animWalking.render(objectsToDraw, this.x-player.x, this.y-player.y, this.currentDirection);
        }else if (this.state == 'idle'){
            this.animIdle.render(objectsToDraw, this.x-player.x, this.y-player.y, this.currentDirection);
        }
    }

    isToBeRemoved() {
        return this.isDead;
    }

    kill() {
        this.isDead = true;
    }

    hit(){
        this.health -= 50;
        particles.push(new TextPartilce(this.x, this.y, '-50hp'));
        if(this.health <= 0){
            this.health = 0;
            particles.push(new Smoke(this.x, this.y, 10));
            this.kill();
        }else{
            particles.push(new Smoke(this.x, this.y, 3));
        }

        //make the chicken run away
        this.state = 'running';
        this.endRunningTime = p5js.millis() + 2000;
    }

    getHash() {
        return this.hash;
    }
}

export default Chicken;