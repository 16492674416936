import Chunk from "../game/chunk";
import GameMap from "../game/gameMap";
import Edge from "./edge";
import Graph from "./graph";
import PathNode from "./pathNode";

class PathAi {
    nodes: PathNode[] = [];
    margin: number;
    path: PathNode[] = [];
    walls: any[] = [];
    edges: Edge[] = [];
    graph: Graph;

    constructor(private gameMap: GameMap, margin: number = 100) {
        this.margin = margin;
    }

    calculatePath(startX: number, startY: number, endX: number, endY: number) {
        this.nodes = [];
        this.path = [];
        this.walls = [];
        this.edges = [];

        this.nodes.push(new PathNode(startX, startY));
        this.nodes.push(new PathNode(endX, endY));

        //update walls from chunks
        this.updateWallsFromChunks(startX, startY, endX, endY);

        if(!this.lineIntersectsWalls(startX, startY, endX, endY)){
            this.path = this.nodes;
            return;
        }

        //generate node cloud
        this.generateNodeCloud();

        this.connectNodes(1000);

        // Create a graph from the edges
        this.graph = new Graph(this.edges);

        // Find the shortest path
        this.path = this.dijkstra(this.graph, this.nodes[0], this.nodes[1]) || [];
    }

    updateWallsFromChunks(x1: number, y1: number, x2: number, y2: number) {
        // Get walls from chunks of interest
        // Calculate wall positions from chunk positions:
        //
        // let posX = wall.x + x * this.gameMap.chunkSize;
        // let posY = wall.y + y * this.gameMap.chunkSize;
        // this.walls.push({x: posX, y: posY, w: wall.w, h: wall.h});

        let cx1 = Math.floor(x1 / Chunk.CHUNK_SIZE);
        let cy1 = Math.floor(y1 / Chunk.CHUNK_SIZE);
        let cx2 = Math.floor(x2 / Chunk.CHUNK_SIZE);
        let cy2 = Math.floor(y2 / Chunk.CHUNK_SIZE);

        //make sure that the cx1 and cy2 is smaller than cx2 and cy2
        if(cx1 > cx2){
            let tmp = cx1;
            cx1 = cx2;
            cx2 = tmp;
        }
        if(cy1 > cy2){
            let tmp = cy1;
            cy1 = cy2;
            cy2 = tmp;
        }


        this.walls = [];
        for(let x = cx1-1; x <= cx2+1; x++){
            for(let y = cy1-1; y <= cy2+1; y++){
                let chunk = this.gameMap.getChunk(x, y); 

                if(!chunk){
                    console.log('chunk not found');
                    continue;
                }

                for(let wall of chunk.data.walls){
                    let posX = wall.x + x * Chunk.CHUNK_SIZE;
                    let posY = wall.y + y * Chunk.CHUNK_SIZE;
                    this.walls.push({x: posX, y: posY, w: wall.w, h: wall.h});
                }
            }
        }
    }

    generateNodeCloud() {
        //add nodes on corners of the walls (expanded by margin)
        for (let wall of this.walls) {
            this.addNode(wall.x - this.margin, wall.y - this.margin);
            this.addNode(wall.x + wall.w + this.margin, wall.y - this.margin);
            this.addNode(wall.x - this.margin, wall.y + wall.h + this.margin);
            this.addNode(wall.x + wall.w + this.margin, wall.y + wall.h + this.margin);
            //this.addNode(wall.x - this.margin, wall.y - this.margin);
        }
    }

    isNodeValid(x: number, y: number){
        for (let wall of this.walls) {
            if (x >= wall.x && x <= wall.x + wall.w && y >= wall.y && y <= wall.y + wall.h) {
                return false;
            }
        }

        //return if node already exists
        for (let node of this.nodes) {
            if (node.x == x && node.y == y) {
                return false;
            }
        }

        return true;
    }

    addNode(x: number, y: number) {
        //return if inside wall, walls are rectangles {x, y, w, h} in gameMap.walls
        if(!this.isNodeValid(x, y)){
            return;
        }
        
        this.nodes.push(new PathNode(x, y));
    }

    lineIntersectsLine(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number): boolean {
        const denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
    
        // If denom is 0, lines are parallel or coincident
        if (denom === 0) {
            // Check if the lines are collinear
            const crossProduct = (x1 - x3) * (y2 - y1) - (y1 - y3) * (x2 - x1);
            if (crossProduct !== 0) {
                return false; // Lines are parallel but not collinear
            }
    
            // Check if the segments overlap
            const overlapping = (Math.min(x1, x2) <= Math.max(x3, x4) && Math.min(x3, x4) <= Math.max(x1, x2)) &&
                                (Math.min(y1, y2) <= Math.max(y3, y4) && Math.min(y3, y4) <= Math.max(y1, y2));
            return overlapping;
        }
    
        const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
        const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;
    
        // Return true if the intersection point is within both line segments
        return ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1;
    }

    lineIntersectsRect(x1: number, y1: number, x2: number, y2: number, wall: any): boolean {
        // Check if the line (x1, y1) -> (x2, y2) intersects with the rectangle defined by the wall
        // Simplified version, a full implementation would need to check all edges of the rectangle
        return (
            this.lineIntersectsLine(x1, y1, x2, y2, wall.x, wall.y, wall.x + wall.w, wall.y) || 
            this.lineIntersectsLine(x1, y1, x2, y2, wall.x + wall.w, wall.y, wall.x + wall.w, wall.y + wall.h) || 
            this.lineIntersectsLine(x1, y1, x2, y2, wall.x + wall.w, wall.y + wall.h, wall.x, wall.y + wall.h) || 
            this.lineIntersectsLine(x1, y1, x2, y2, wall.x, wall.y + wall.h, wall.x, wall.y) 
        );
    }

    lineIntersectsWalls(x1: number, y1: number, x2: number, y2: number): boolean {
        // Check if the line (x1, y1) -> (x2, y2) intersects with any walls
        for (let wall of this.walls) {
            if (this.lineIntersectsRect(x1, y1, x2, y2, wall)) {
                return true;
            }
        }
        return false;
    }
q
    connectNodes(maxNodeConnectionDistance: number) {
        this.edges = [];
        for (let i = 0; i < this.nodes.length; i++) {
            for (let j = i + 1; j < this.nodes.length; j++) {
                const nodeA = this.nodes[i];
                const nodeB = this.nodes[j];
                let d = p5js.dist(nodeA.x, nodeA.y, nodeB.x, nodeB.y);
                if (d <= maxNodeConnectionDistance) {
                    let validConnection = true;
                    for (let wall of this.walls) {
                        if (this.lineIntersectsRect(nodeA.x, nodeA.y, nodeB.x, nodeB.y, wall)) {
                            validConnection = false;
                            break;
                        }
                    }
                    if (validConnection) {
                        this.edges.push(new Edge(nodeA, nodeB, d));
                    }
                }
            }
        }
    }

    dijkstra(graph: Graph, startNode: PathNode, targetNode: PathNode): PathNode[] | null {
        const distances: Map<PathNode, number> = new Map();
        const previousNodes: Map<PathNode, PathNode | null> = new Map();
        const priorityQueue: { node: PathNode, priority: number }[] = [];
    
        // Initialize distances and priority queue
        this.graph.adjacencyList.forEach((_, node) => {
            distances.set(node, Infinity);
            previousNodes.set(node, null);
            priorityQueue.push({ node, priority: Infinity });
        });
    
        // Set the distance for the start node to 0
        distances.set(startNode, 0);
        priorityQueue.push({ node: startNode, priority: 0 });
    
        while (priorityQueue.length > 0) {
            // Get the node with the lowest priority (shortest distance)
            priorityQueue.sort((a, b) => a.priority - b.priority);
            const { node: currentNode } = priorityQueue.shift()!;
    
            // If we reached the target node, reconstruct the path
            if (currentNode === targetNode) {
                const path: PathNode[] = [];
                let current = targetNode;
                while (current !== null) {
                    path.unshift(current);
                    current = previousNodes.get(current)!;
                }
                return path;
            }
    
            // Update the distances and previous nodes for the neighbors
            for (const { neighbor, weight } of graph.getNeighbors(currentNode)) {
                const alt = distances.get(currentNode)! + weight;
                if (alt < distances.get(neighbor)!) {
                    distances.set(neighbor, alt);
                    previousNodes.set(neighbor, currentNode);
    
                    // Update the priority queue
                    priorityQueue.push({ node: neighbor, priority: alt });
                }
            }
        }
    
        // If the target node is unreachable
        return null;
    }

    renderDebug() {
        //calculate player map offset
        
        //draw walls
        for (let wall of this.walls) {
            let posOnScreen = {x: wall.x-player.x, y: wall.y-player.y};
            buffer.noFill();
            buffer.stroke(255);
            buffer.strokeWeight(1);
            buffer.rect(posOnScreen.x, posOnScreen.y, wall.w, wall.h);
        }

        //draw edges
        // for (let edge of this.edges) {
        //     let posOnScreenA = {x: edge.nodeA.x-player.x, y: edge.nodeA.y-player.y};
        //     let posOnScreenB = {x: edge.nodeB.x-player.x, y: edge.nodeB.y-player.y};
        //     buffer.stroke(255, 50);
        //     buffer.strokeWeight(1);
        //     buffer.line(posOnScreenA.x, posOnScreenA.y, posOnScreenB.x, posOnScreenB.y);
        // }

        //draw path
        for (let i = 0; i < this.path.length - 1; i++) {
            let posOnScreenA = {x: this.path[i].x-player.x, y: this.path[i].y-player.y};
            let posOnScreenB = {x: this.path[i+1].x-player.x, y: this.path[i+1].y-player.y};
            buffer.stroke(0, 255, 0);
            buffer.strokeWeight(2);
            buffer.line(posOnScreenA.x, posOnScreenA.y, posOnScreenB.x, posOnScreenB.y);
        }

        // //draw nodes
        for (let node of this.path) {
            let posOnScreen = {x: node.x-player.x, y: node.y-player.y};

            if(node.explored){
                buffer.fill(0, 255, 0);
            }else{
                buffer.fill(255);
            }
            buffer.stroke(0);
            buffer.strokeWeight(2);
            buffer.ellipse(posOnScreen.x, posOnScreen.y, 6, 6);
        }

        
    }

    // drawLine45(x1: number, y1: number, x2: number, y2: number) {
    //     let minDist = this.findMidPoint45(x1, y1, x2, y2);      

    //     // buffer.line(x1, y1, mixX, mixY);
    //     // buffer.line(mixX, mixY, x2, y2);

    //     buffer.line(x1, y1, minDist.x, minDist.y);
    //     buffer.line(minDist.x, minDist.y, x2, y2);

    // }

    // findMidPoint45(x1: number, y1: number, x2: number, y2: number) {
    //     let minDist = p5js.min(p5js.abs(x2-x1), p5js.abs(y2-y1));

    //     let mixX = x1 + minDist;
    //     let mixY = y1 + minDist;

    //     if(x1 > x2){
    //         mixX = x1 - minDist;
    //     }
    //     if(y1 > y2){
    //         mixY = y1 - minDist;
    //     }

    //     return {x: mixX, y: mixY};
    // }
}

export default PathAi;