import UIElement from "./uiElement";

class VerticalSeparator implements UIElement {
    x: number;
    y: number;
    w: number;
    h: number;
    action: Function;

    constructor(h: number) {
        this.x = 0;
        this.y = 0;
        this.w = 10;
        this.h = h;
        this.updateLayout();
    }

    updateLayout(): void {
       
    }

    render(): void {
        //draw separator line 
        buffer.stroke(100);
        buffer.strokeWeight(1);
        buffer.line(this.x + this.w/2, this.y + 5, this.x + this.w/2, this.y + this.h - 5);
    }

    renderTooltip(): boolean {
        return false;
    }

    mousePressed(): boolean {
        return false;
    }

    mouseReleased(): boolean {
        return false;
    }

    keyPressed(): boolean {
        return false;
    }

    keyReleased(): boolean {
        return false;
    }

    scroll(event: WheelEvent): boolean {
        return false;
    }
}

export default VerticalSeparator;