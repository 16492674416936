import P5 from 'p5';
import Label from "./label";
import UIElement from "./uiElement";
import Button from './button';

class PopupWindow implements UIElement {
    x: number;
    y: number;
    w: number;
    h: number;

    action: Function; 
    cancelAction: Function;
    elements: UIElement[] = [];
    show: boolean = true;
    image: P5.Image | null = null;

    title = "Popup Window";
    message = "This is a popup window";
    cancelButton = new Button(50, 20, "Cancel");
    okButton = new Button(50, 20, "OK");

    constructor(title: string, message: string) {
        this.x = -100;
        this.y = -50;
        this.w = 200;
        this.h = 100;

        this.title = title;
        this.message = message;

        this.cancelButton.action = () => {
            if(this.cancelAction){
                this.cancelAction();
            }
            this.show = false;
        }
        this.addElement(this.cancelButton);
        this.okButton.action = () => {
            if(this.action){
                this.action();
            }
            this.show = false;
        }
        this.addElement(this.okButton);
        this.updateLayout();
        this.setImage("/assets/ui/editor/warning");
    }

    private addElement(element: UIElement): void {
        this.elements.push(element);
    }

    updateLayout(): void {
        this.okButton.x = this.x + this.w - this.okButton.w - 5;
        this.okButton.y = this.y + this.h - 25;

        this.cancelButton.x = this.okButton.x - 55;
        this.cancelButton.y = this.y + this.h - 25;

        for(let element of this.elements){
            element.updateLayout();
        }
    }

    setImage(asset: string){
        let path = `${staticServer}${asset}.png`;
        p5js.loadImage(path, (img) => {
            this.image = img;
        });
    }
    
    render(): void {
        if(!this.show){
            return;
        }
        buffer.fill(200);
        buffer.stroke(0);
        buffer.strokeWeight(1);
        buffer.rect(this.x, this.y, this.w, this.h);

        buffer.noStroke();
        buffer.fill(0);
        buffer.textSize(12);
        buffer.textAlign(buffer.LEFT, buffer.TOP);
        buffer.text(this.title, this.x + 5, this.y + 5);

        if(this.image){
            buffer.image(this.image, this.x + 5, this.y + 25, 24, 24);
        }

        buffer.textSize(10);
        buffer.text(this.message, this.x + 35, this.y + 25, this.w - 40, this.h - 50);

        for(let element of this.elements){
            element.render();
        }
    }

    renderTooltip(): boolean {
        if(!this.show){
            return false;
        }
        for(let element of this.elements){
            if(element.renderTooltip()){
                return true;
            }
        }
        return false;
    }

    mouseInArea(): boolean{
        let mx = p5js.mouseX/pixelSize - buffer.width/2;
        let my = p5js.mouseY/pixelSize - buffer.height/2;

        if(mx > this.x && mx < this.x + this.w && my > this.y && my < this.y + this.h){
            return true;
        }

        return false;
    }

    mousePressed(): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.mousePressed()){
                return true;
            }
        }

        if(this.mouseInArea()){
            return true;
        }

        return false;
    }

    mouseReleased(): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.mouseReleased()){
                return true;
            }
        }

        if(this.mouseInArea()){
            return true;
        }

        return false;
    }

    keyPressed(): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.keyPressed()){
                return true;
            }
        }
        return false;
    }

    keyReleased(): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.keyReleased()){
                return true;
            }
        }
        return false;
    }

    scroll(event: any): boolean {
        if(!this.show){
            return false;
        }

        for(let element of this.elements){
            if(element.scroll(event)){
                return true;
            }
        }

        return false;
    }
}

export default PopupWindow;