import Edge from "./edge";
import PathNode from "./pathNode";

class Graph {
    adjacencyList: Map<PathNode, { neighbor: PathNode, weight: number }[]> = new Map();

    constructor(edges: Edge[]) {
        for (const edge of edges) {
            this.addEdge(edge.nodeA, edge.nodeB, edge.weight);
            this.addEdge(edge.nodeB, edge.nodeA, edge.weight);
        }
    }

    addEdge(nodeA: PathNode, nodeB: PathNode, weight: number): void {
        if (!this.adjacencyList.has(nodeA)) {
            this.adjacencyList.set(nodeA, []);
        }
        this.adjacencyList.get(nodeA)?.push({ neighbor: nodeB, weight });
    }

    getNeighbors(node: PathNode): { neighbor: PathNode, weight: number }[] {
        return this.adjacencyList.get(node) || [];
    }
}

export default Graph;