import P5 from "p5";
import MainMenuScreen from "./mainMenuScreen";
import GameScreen from "./gameScreen";
import Player from "./game/player";
import MapEditorScreen from "./mapEditorScreen";
import OptionsScreen from "./optionsScreen";
import io from "socket.io-client";
import OtherPlayer from "./entities/otherPlayer";
import Entity from "./entities/entity";
import TestScreen from "./testScreen";

globalThis.pixelSize = globalThis.pixelSize || 2;
globalThis.isMobile = globalThis.isMobile || false;
globalThis.showDebug = globalThis.showDebug || false;
globalThis.renderTime = globalThis.renderTime || 0;
globalThis.renderTimeLast = globalThis.renderTimeLast || 0;
globalThis.entities = globalThis.entities || [];
globalThis.particles = globalThis.particles || [];
globalThis.currentScreen = globalThis.currentScreen || "game";
globalThis.screens = globalThis.screens || {};
globalThis.iScreen = globalThis.iScreen || null;
globalThis.buffer = globalThis.buffer || null;
globalThis.p5js = globalThis.p5js || null;
globalThis.player = globalThis.player || null;
//globalThis.staticServer = globalThis.staticServer || "http://192.168.1.120:5000";
globalThis.staticServer = globalThis.staticServer || "";

const socket = io("https://game-api.xpiti.com");

function isOtherPlayer(entity: Entity): entity is OtherPlayer {
    return entity instanceof OtherPlayer;
}

const sketch = (p5 : P5) => {
    p5.preload = () => {
        p5js = p5;
        player = new Player();

        screens["mainMenu"] = new MainMenuScreen();
        screens["game"] = new GameScreen();
        screens["mapEditor"] = new MapEditorScreen();
        screens["options"] = new OptionsScreen();
        screens["test"] = new TestScreen();
        iScreen = screens[currentScreen];
    }

    p5.setup = () => {
        p5.createCanvas(p5.windowWidth, p5.windowHeight);
        isMobile = detectMobile();

        //use window resize event to resize canvas
        p5.windowResized();

        //setup for all screens
        for(let key in screens){
            screens[key].setup();
        }

        // Emit player position at regular intervals
        setInterval(() => {
            socket.emit('update_position', { player_id: player.hash, position: {
                    x: player.x, 
                    y: player.y,
                    dir: player.currentDirection,
                    isRunning: player.isRunning,
                } });
        }, 100);

        // Emit heartbeat to keep the player active
        setInterval(() => {
            socket.emit('heartbeat', { player_id: player.hash });
        }, 1000);

        // Listen for updates from the server
        socket.on('update_positions', (data: any) => {
            // Keep track of active player hashes
            const activeHashes = new Set<string>();
        
            for (let hash in data) {
                if (player.hash !== hash) {
                    let updatedPosition = data[hash];
                    //console.log(`Updated position for player ${hash}`);
                
                    // Find the entity with the matching hash
                    let entity = entities.find(entity => isOtherPlayer(entity) && entity.hash === hash);
            
                    if (entity) {
                        // If the entity exists, update its position
                        (entity as OtherPlayer).gx = updatedPosition.x;
                        (entity as OtherPlayer).gy = updatedPosition.y;
                        (entity as OtherPlayer).currentDirection = updatedPosition.dir;
                        (entity as OtherPlayer).isRunning = updatedPosition.isRunning;
                    } else {
                        // If the entity does not exist, create a new one and add it to the entities array
                        let newEntity = new OtherPlayer(updatedPosition.x, updatedPosition.y, hash);
                        entities.push(newEntity);
                    }
        
                    // Mark this player as active
                    activeHashes.add(hash);
                }
            }
        
            // Remove entities that are no longer active
            entities = entities.filter(entity => {
                if (isOtherPlayer(entity)) {
                    return activeHashes.has(entity.hash);
                }
                return true; // Keep other entities
            });
        });
        
        
        
    }

    p5.draw = () => {
        renderTime = p5.millis();

        // Update screen
        iScreen.draw();

        //draw cursor
        if(!isMobile){
            drawCursor();
        }

        // Draw buffer to the main canvas, scaled up
        p5.noSmooth();
        p5.image(buffer, 0, 0, p5.width, p5.height);
        renderTime = p5.millis() - renderTime;

        renderTimeLast = renderTime;
    }

    p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
        let min = p5.min(p5.windowWidth, p5.windowHeight);
        if(min < 960){
            pixelSize = 1;
        }else if (min < 2000){
            pixelSize = 2;
        }else if (min < 4000){
            pixelSize = 3;
        }else{
            pixelSize = 4;
        }
        let cols = p5.floor(p5.width / pixelSize);
        let rows = p5.floor(p5.height / pixelSize);
        globalThis.buffer = p5.createGraphics(cols, rows);
        buffer.translate(p5.int(buffer.width / 2), p5.int(buffer.height / 2));

        for(let key in screens){
            screens[key].windowResized();
        }
    }

    p5.keyPressed = () => {
        iScreen.keyPressed();
    }
        

    p5.keyReleased = () => {
        iScreen.keyReleased();
    }

    p5.mousePressed = () => {
        iScreen.mousePressed();
    }

    p5.mouseReleased = () => {
        iScreen.mouseReleased();
    }

    p5.touchStarted = () => {
        iScreen.touchStarted();
    }

    p5.touchEnded = () => {
        iScreen.touchEnded();
    }

    p5.touchMoved = () => {
        iScreen.touchMoved();
        return false;
    }

    p5.mouseWheel = (event) => {
        iScreen.mouseWheel(event);
    }

    function drawCursor(){
        let cursorSize = 8;
        let posX = p5.int((p5.mouseX/pixelSize) - (buffer.width/2));
        let posY = p5.int((p5.mouseY/pixelSize) - (buffer.height/2));
        buffer.stroke(255);
        buffer.strokeWeight(2);
        buffer.line(posX-cursorSize, posY, posX+cursorSize, posY);
        buffer.line(posX, posY-cursorSize, posX, posY+cursorSize);
    }

    function detectMobile() : boolean{
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);
        return isMobileDevice;
    }
}

new P5(sketch);