import seedrandom from 'seedrandom';
import p5 from 'p5';

export function randomRNG(rng: seedrandom.PRNG, min: number, max: number) {
    return (rng() * (max - min)) + min;
}

export function randomIntRNG(rng: seedrandom.PRNG, min: number, max: number) {
    return Math.floor(randomRNG(rng, min, max));
}

//helper function to get random object from an array
export function randomArrayElementRNG(rng: seedrandom.PRNG, array: any[]) {
    return array[randomIntRNG(rng, 0, array.length)];
}

export function sliceImageToArray(image, frameWidth, frameHeight) {
    let frames: p5.Image[] = [];
    for (let y = 0; y < image.height; y += frameHeight) {
        for (let x = 0; x < image.width; x += frameWidth) {
            let frame = image.get(x, y, frameWidth, frameHeight);
            frames.push(frame);
        }
    }
    return frames;
}

export function setCookie(name: string, value: string, days: number) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + encodeURIComponent(value) + expires + "; path=/";
}


export function getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

export function angleToDirection(angle:number, directions:number = 8):number{
    let halfStep = p5js.TWO_PI / directions / 2;
    return p5js.int(p5js.map(angle, p5js.PI, -p5js.PI, directions, 0) + halfStep) +2 % directions;
}

export function directionToAngle(direction:number, directions:number = 8):number{
    return p5js.map(direction, 0, directions, -p5js.PI, p5js.PI) - p5js.HALF_PI;
}

export function saveJSON(json: any, path: string){
    //use POST request to upload the JSON to the server
    let xhr = new XMLHttpRequest();
    xhr.open("POST", `${staticServer}${path}`, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(json));
}

export function mouseInArea(x: number, y: number, w: number, h: number): boolean{
    let mx = p5js.mouseX/pixelSize - buffer.width/2;
    let my = p5js.mouseY/pixelSize - buffer.height/2;

    if(mx > x && mx < x + w && my > y && my < y + h){
        return true;
    }
    return false;
}