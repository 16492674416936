import GameMap from "../game/gameMap";
import MapEditorScreen from "../mapEditorScreen";
import Button from "../ui/button";
import DropDownList from "../ui/dropDownList";
import HorizontalSeparator from "../ui/horizontalSeparator";
import HorizontalView from "../ui/horizontalView";
import Label from "../ui/label";
import TextInput from "../ui/textInput";
import UIElement from "../ui/uiElement";
import VerticalView from "../ui/verticalView";

class WorldSelectTool implements UIElement {
    x: number = 0;
    y: number = 0;
    w: number = 80;
    h: number = 20;
    action: Function;

    
    // ------ UI ------
    ui: VerticalView = new VerticalView(0, 0, 10, 10);

    constructor(private gameMap : GameMap, private editor: MapEditorScreen) {
        // ------ UI ------
        this.ui.horizontalAlignment = "LEFT";
        this.ui.verticalAlignment = "TOP";

        
        this.ui.addElement(new Label("Select World"));
        let selectWorldView = new HorizontalView(0, 0, 190, 30);
        selectWorldView.background = false;
        let drp_world = new DropDownList(110, ["world", "world2", "test"], "Select a world to load");
        selectWorldView.addElement(drp_world);
        let btn_load = new Button(65, 20, "Load World");
        btn_load.action = () => {
            this.gameMap.loadWorld(drp_world.selectedOption, 256, 256);
            this.editor.posX = 256;
            this.editor.posY = 256;
            this.editor.selectedTool = null;
        }
        selectWorldView.addElement(btn_load);
        this.ui.addElement(selectWorldView);

        // ---------------------------------------------------------
        this.ui.addElement(new HorizontalSeparator(190));
        this.ui.addElement(new Label("Add New World"));
        let addWorldView = new HorizontalView(0, 0, 190, 30);
        addWorldView.background = false;
        let txt_worldName = new TextInput(110, 20, "world_name", "Enter a world name");
        txt_worldName.ALLOWED_CHARS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_";
        addWorldView.addElement(txt_worldName);
        let btn_add = new Button(65, 20, "Add World");
        btn_add.action = () => {
            //this.gameMap.createWorld(txt_worldName.text);
            //this.editor.selectedTool = null;
            this.gameMap.loadWorld(txt_worldName.text, 256, 256);
            this.editor.posX = 256;
            this.editor.posY = 256;
            this.editor.selectedTool = null;
        }
        addWorldView.addElement(btn_add);
        this.ui.addElement(addWorldView);

        //this.ui.addElement(new TextInput(100, 20, "World Name", "Enter a world name"));
    }

    updateLayout(): void {
        this.ui.w = 200;
        this.ui.h = 150;
        this.ui.x = -this.ui.w/2;
        this.ui.y = -this.ui.h/2;
        this.ui.updateLayout();
    }

    render() {
        this.ui.render();
    }

    renderTooltip(): boolean {
        this.ui.renderTooltip();
        return false;
    }

    mousePressed(): boolean {
        if(this.ui.mousePressed()){
            return true;
        }

        return false;
    }

    mouseReleased(): boolean {
        if(this.ui.mouseReleased()){
            return true;
        }

        return false;
    }

    keyPressed(): boolean {
        if(this.ui.keyPressed()){
            return true;
        }

        return false;
    }

    keyReleased(): boolean {
        if(this.ui.keyReleased()){
            return true;
        }

        return false;
    }

    scroll(event: WheelEvent): boolean {
        if(this.ui.scroll(event)){
            return true;
        }

        return true;
    }
}

export default WorldSelectTool;