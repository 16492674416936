import { ObjectToDraw } from "../types";
import Particle from "./Particle";

class TextPartilce implements Particle {

    x: number;
    y: number;
    text: string;

    timeToDie: number;

    isDead: boolean = false;

    constructor(sx: number, sy: number, text: string){
        this.x = sx;
        this.y = sy;
        this.timeToDie = p5js.millis() + 1000;
        this.text = text;
    }

    update(){
        if(p5js.millis() > this.timeToDie){
            this.kill();
            return;
        }

        this.y -= 1;
        // if(this.y < this.player.y-100){
        //     this.kill();
        // }
    }

    render(objectsToDraw: ObjectToDraw[]){
        
    }

    renderOver(){
        let posOnScreen = {x: this.x-player.x, y: this.y-player.y};
        
        buffer.fill(255);
        //buffer.ellipse(posOnScreen.x, posOnScreen.y, 10, 10);

        buffer.textAlign(buffer.CENTER, buffer.CENTER);
        buffer.noStroke();
        buffer.textSize(13);
        buffer.text(this.text, posOnScreen.x, posOnScreen.y);
    }

    isToBeRemoved(): boolean {
        return this.isDead;
    }

    kill(){
        this.isDead = true;
    }
}

export default TextPartilce;