import { IScreen } from './iScreen';

class MainMenuScreen implements IScreen{
    option : number = 0;
    options : string[] = ["Start", "Options", "Map Editor"];

    constructor(){

    }

    setup(){
        
    }

    windowResized(): void {
        
    }

    draw(){
        buffer.background(0);

        if(!isMobile){
            //display options:
            buffer.textAlign(buffer.CENTER, buffer.CENTER);
            buffer.fill(255);
            buffer.textSize(24);
            buffer.noStroke();
            for(let i = 0; i < this.options.length; i++){
                if(i == this.option){
                    buffer.fill(205 + (p5js.sin(p5js.frameCount / 5.0) * 25), 0, 0);
                    buffer.text("> " + this.options[i] + " <", 0, (i - 1) * 32);
                }else{
                    buffer.fill(255);
                    buffer.text(this.options[i], 0, (i - 1) * 32);
                }
            }
        }else{
            buffer.textAlign(buffer.CENTER, buffer.CENTER);
            buffer.fill(255);
            buffer.textSize(15);
            buffer.noStroke();
            buffer.text("Touch is not supported.\nPress any key to dimmis", 0, 0);
        }
    }

    keyPressed(){
        if(isMobile){
            isMobile = false;
            return;
        }
        if(p5js.keyCode == p5js.UP_ARROW || p5js.key.toLowerCase() === 'w'){
            this.option--;
            if(this.option < 0){
                this.option = this.options.length - 1;
            }

        }else if(p5js.keyCode == p5js.DOWN_ARROW || p5js.key.toLowerCase() === 's'){
            this.option++;
            if(this.option >= this.options.length){
                this.option = 0;
            }
        }else if(p5js.keyCode == p5js.ENTER){
            if(this.option == 0){
                console.log("Start Game");
                currentScreen = "game";
                iScreen = screens[currentScreen];
            }else if(this.option == 1){
                console.log("Options");
                currentScreen = "options";
                iScreen = screens[currentScreen];
                //iScreen = new OptionsScreen();
            }else if(this.option == 2){
                console.log("Map Editor");
                currentScreen = "mapEditor";
                iScreen = screens[currentScreen];
            }
        }else if(p5js.keyCode === p5js.ESCAPE){
            currentScreen = "game";
            iScreen = screens[currentScreen];
            //iScreen = new MainMenuScreen(p5js);
            return;
        }
    }

    keyReleased(){
        
    }

    mousePressed(){
        
    }

    mouseReleased(){
        
    }

    touchStarted(){
        
    }

    touchEnded(){
        
    }

    touchMoved(): void {
        
    }

    mouseWheel(event: any): void {
        
    }

}

export default MainMenuScreen;