import { ObjectToDraw } from '../types';
import Entity from './entity';
import SpriteAnimationRenderer from '../spriteAnimationRenderer';
import Smoke from '../particles/smoke';
import TextPartilce from '../particles/textParticle';
import PathAi from '../pathFinding/pathAi';
import GameMap from '../game/gameMap';
import { angleToDirection, directionToAngle } from '../helpers';

class TestEntity implements Entity {
    NAME = 'testEntity';
    hash: string;
    x: number;
    y: number;
    vx: number = 0;
    vy: number = 0;
    gx: number;
    gy: number;
    speed: number;
    currentDirection: number;
    state: string;
    startWalkingTime: number;
    endRunningTime: number;
    isDead: boolean;
    animIdle: SpriteAnimationRenderer;
    animWalking: SpriteAnimationRenderer;
    health: number;
    pathAi: PathAi;

    constructor(private gameMap: GameMap, sx: number, sy: number) {
        this.hash = `testEntity_${sx}_${sy}`;
        this.x = sx;
        this.y = sy;
        this.health = 100;
        this.gx = 0;
        this.gy = 0;
        this.speed = 2;
        this.currentDirection = 7;
        this.state = 'idle';
        this.startWalkingTime = p5js.millis();
        this.isDead = false;
        
        this.animIdle = new SpriteAnimationRenderer();
        this.animIdle.load('/assets/entities/testEntity/idle');

        this.animWalking = new SpriteAnimationRenderer();
        this.animWalking.load('/assets/entities/testEntity/walking');

        this.pathAi = new PathAi(this.gameMap, 8);
    }

    init() {
        console.log('Chicken initialized');
    }

    update() {
        let tmpX = this.x;
        let tmpY = this.y;


        if(this.state == 'walking'){
            let distanceToGoal = p5js.dist(this.x, this.y, this.gx, this.gy);

            if (distanceToGoal > 10) {
                // Reset velocities
                this.vx = 0;
                this.vy = 0;

                let ngx = this.gx;
                let ngy = this.gy;

                if(this.pathAi.path.length > 1){
                    for(let node of this.pathAi.path){
                        if(!node.explored){

                            if(p5js.dist(this.x, this.y, node.x, node.y) < 5){
                                node.explored = true;
                            }else{
                                ngx = node.x;
                                ngy = node.y;
                                break;
                            }
                        }
                    }
                }

                let angle = p5js.atan2(ngy - this.y, ngx - this.x);
                this.vx = p5js.cos(angle) * this.speed;
                this.vy = p5js.sin(angle) * this.speed;

                // Calculate the current direction
                //this.currentDirection = (p5js.int(p5js.map(p5js.atan2(this.vy, this.vx), -p5js.PI, p5js.PI, 0, 7)) + 3) % 8;
                this.currentDirection = angleToDirection(angle, 8);

                // Update position
                this.x += this.vx;
                this.y += this.vy;
            }else{
                this.startWalkingTime = p5js.int(p5js.millis() + p5js.random(5000, 10000));
                this.state = 'idle';
                this.vx = 0;
                this.vy = 0;
            }
        }else if (this.state == 'idle'){
            this.vx = 0;
            this.vy = 0;

            if(p5js.millis() > this.startWalkingTime){
                let pathFound = false;
                this.state = 'walking';
                while(!pathFound){
                    this.gx = this.x + p5js.int(p5js.random(-200, 200));
                    this.gy = this.y + p5js.int(p5js.random(-200, 200));

                    this.pathAi.calculatePath(this.x, this.y, this.gx, this.gy);
                    pathFound = this.pathAi.path.length > 1;
                }
            }
        }

        let posX = p5js.int((p5js.mouseX/pixelSize) - (buffer.width/2));
        let posY = p5js.int((p5js.mouseY/pixelSize) - (buffer.height/2));

        if(p5js.mouseIsPressed){
            //go to this position
            this.state = 'walking';
            this.gx = posX + player.x;
            this.gy = posY + player.y;
            this.pathAi.calculatePath(this.x, this.y, this.gx, this.gy);
        }


        tmpX += this.vx;
        tmpY += this.vy;
    }

    render(objectsToDraw: ObjectToDraw[]) {
        let rX = p5js.floor(this.x);
        let rY = p5js.floor(this.y);
        let posOnScreen = {x: rX-player.x, y: rY-player.y};

        // if(posOnScreen.x < -buffer.width/2-64 || posOnScreen.x > buffer.width/2+64 || posOnScreen.y < -buffer.height/2-64 || posOnScreen.y > buffer.height/2+64){
        //     return;
        // }
        

        if(showDebug){
            buffer.stroke(255);
            buffer.strokeWeight(1);
            buffer.line(rX-player.x, rY-player.y, this.gx-player.x, this.gy-player.y);

            buffer.noStroke();
            buffer.fill(255, 0, 0);
            buffer.ellipse(this.gx-player.x, this.gy-player.y, 10, 10);

            buffer.fill(255);
            buffer.textAlign(p5js.CENTER, p5js.TOP);
            let time = p5js.int((this.startWalkingTime-p5js.millis())/1000);
            if(time < 0){
                time = 0;
            }
            buffer.text(this.state + ": " + time + "s", rX-player.x, rY-player.y+10);

            buffer.stroke(0, 0, 255);
            buffer.strokeWeight(2);
            let angle = directionToAngle(this.currentDirection, 8);
            buffer.line(rX-player.x, rY-player.y, rX-player.x+p5js.cos(angle)*50, rY-player.y+p5js.sin(angle)*50);

            this.pathAi.renderDebug();
        }

        buffer.noStroke();
        buffer.fill(0, 100);
        buffer.ellipse(rX-player.x, rY-player.y, 30, 15);

        
        if(this.state == 'walking'){
            this.animWalking.render(objectsToDraw, rX-player.x, rY-player.y, this.currentDirection);
        }else if (this.state == 'idle'){
            this.animIdle.render(objectsToDraw, rX-player.x, rY-player.y, this.currentDirection);
        }

        

        
    }

    isToBeRemoved() {
        return this.isDead;
    }

    kill() {
        this.isDead = true;
    }

    hit(){
        this.health -= 50;
        particles.push(new TextPartilce(this.x, this.y, '50hp'));
        if(this.health <= 0){
            this.health = 0;
            particles.push(new Smoke(this.x, this.y, 10));
            this.kill();
        }else{
            particles.push(new Smoke(this.x, this.y, 3));
        }
    }

    getHash(): string {
        return this.hash;
    }
}

export default TestEntity;