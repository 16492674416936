import { ObjectToDraw } from '../types';
import SpriteAnimationRenderer from "../spriteAnimationRenderer";
import GameMap from './gameMap';
import Bullet from '../entities/bullet';
import Chunk from './chunk';
import TextPartilce from '../particles/textParticle';

class Player{
    x: number;
    y: number;
    vx: number;
    vy: number;
    speed: number;

    keyW: boolean;
    keyA: boolean;
    keyS: boolean;
    keyD: boolean;
    touchStartX: number = 0;
    touchStartY: number = 0;
    isTouching: boolean = false;
    joystickX: number = 0;
    joystickY: number = 0;

    currentDirection: number;
    isRunning: boolean;
    isShooting: boolean;
    enteredPortal: boolean;

    health: number;
    score: number;

    animIdle: SpriteAnimationRenderer;
    animRunning: SpriteAnimationRenderer;
    animShooting: SpriteAnimationRenderer;
    animShootingHold: SpriteAnimationRenderer;
    animRunningAndShooting: SpriteAnimationRenderer;
    animRunningAndShootingHold: SpriteAnimationRenderer;

    shootHoldStartTime: number;

    waypointX : number;
    waypointY : number;
    waypointShow : boolean;

    skin = "piti";
    hash: string;

    damageTimer: number = 0;
    damageEffectTime: number = 0;

    constructor(){
        this.hash = this.generateUUID();
        this.x = 256;
        this.y = 256;
        this.vx = 0;
        this.vy = 0;
        this.speed = 4;
        this.health = 100;
        this.score = 0;
        this.currentDirection = 0;
        this.isRunning = false;
        this.isShooting = false;
        this.enteredPortal = false;

        this.waypointX = 5376;
        this.waypointY = 2816;
        this.waypointShow = false;

        this.keyW = false;
        this.keyA = false;
        this.keyS = false;
        this.keyD = false;

        this.animIdle = new SpriteAnimationRenderer();
        this.animIdle.load(`/assets/player/${this.skin}/idle`);

        this.animRunning = new SpriteAnimationRenderer();
        this.animRunning.load(`/assets/player/${this.skin}/running`);

        this.animShooting = new SpriteAnimationRenderer();
        this.animShooting.load(`/assets/player/${this.skin}/shooting`);
        this.animShooting.repeats = false;

        this.animShootingHold = new SpriteAnimationRenderer();
        this.animShootingHold.load(`/assets/player/${this.skin}/shootingHold`);

        this.animRunningAndShooting = new SpriteAnimationRenderer();
        this.animRunningAndShooting.load(`/assets/player/${this.skin}/runningAndShooting`);
        this.animRunningAndShooting.repeats = false;

        this.animRunningAndShootingHold = new SpriteAnimationRenderer();
        this.animRunningAndShootingHold.load(`/assets/player/${this.skin}/runningAndHoldingShot`);
    }

    windowResized(): void {
        this.joystickX = -buffer.width/2 + 100;
        this.joystickY = buffer.height/2 - 200;
    }

    goToDirection(dir: number) {
        // Reset all keys to false initially
        this.keyW = false;
        this.keyA = false;
        this.keyS = false;
        this.keyD = false;
    
        // Handle direction using a switch statement
        switch (dir) {
            case -1:
                // No need to change anything, all keys are already false
                break;
            case 0:
                this.keyS = true;
                break;
            case 2:
                this.keyA = true;
                break;
            case 4:
                this.keyW = true;
                break;
            case 6:
                this.keyD = true;
                break;
            case 1:
                this.keyS = true;
                this.keyA = true;
                break;
            case 3:
                this.keyA = true;
                this.keyW = true;
                break;
            case 5:
                this.keyW = true;
                this.keyD = true;
                break;
            case 7:
                this.keyD = true;
                this.keyS = true;
                break;
            default:
                console.warn(`Unknown direction: ${dir}`);
                break;
        }
    }

    generateUUID(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    update(gameMap: GameMap){
        let tmpX = this.x;
        let tmpY = this.y;

        if(this.keyA && this.keyW){
            this.currentDirection = 3;
            this.vx = -this.speed;
            this.vy = -this.speed;
        }else if(this.keyA && this.keyS){
            this.currentDirection = 1;
            this.vx = -this.speed;
            this.vy = this.speed;
        }else if(this.keyD && this.keyW){
            this.currentDirection = 5;
            this.vx = this.speed;
            this.vy = -this.speed;
        }else if(this.keyD && this.keyS){
            this.currentDirection = 7;
            this.vx = this.speed;
            this.vy = this.speed;
        }else if(this.keyW){
            this.currentDirection = 4;
            this.vx = 0;
            this.vy = -this.speed;
        }else if(this.keyA){
            this.currentDirection = 2;
            this.vx = -this.speed;
            this.vy = 0;
        }else if(this.keyS){
            this.currentDirection = 0;
            this.vx = 0;
            this.vy = this.speed;
        }else if(this.keyD){
            this.currentDirection = 6;
            this.vx = this.speed;
            this.vy = 0;
        }else{
            this.vx = 0;
            this.vy = 0;
        }

        tmpX += this.vx;
        tmpY += this.vy;
        
        if(this.vx != 0 || this.vy != 0){
            this.isRunning = true;
        }else{
            this.isRunning = false;
        }

        let collisionOffset = 5; //must be grater than player speed

        let chunk = gameMap.getCurrentChunk(); //todo, get walls from serrounding chunks as well (9 chunks in total)
        if (chunk && chunk.data.walls) {
            for(let wall of chunk.data.walls){
                let wallX = (chunk.tileX * Chunk.CHUNK_SIZE) - tmpX + wall.x;
                let wallY = (chunk.tileY * Chunk.CHUNK_SIZE) - tmpY + wall.y;

                let wallX2 = wallX - collisionOffset;
                let wallY2 = wallY - collisionOffset;
                let wallW2 = wall.w + collisionOffset*2;
                let wallH2 = wall.h + collisionOffset*2;

                if(0 > wallX2 && 0 < wallX2 + wallW2 && 0 > wallY2 && 0 < wallY2 + wallH2){
                    if(wall.type == "portal"){
                        console.log(wall);
                    }else if(wall.type == "damage"){
                        
                        if(this.damageTimer < p5js.millis()){
                            this.damageTimer = p5js.millis() + 500;
                            this.damageEffectTime = p5js.millis() + 150;
                            if(wall.damageData){
                                this.health -= wall.damageData.damage;
                                particles.push(new TextPartilce(this.x, this.y-25, `-${wall.damageData.damage}hp`));
                            }else{
                                this.health -= 5;
                                particles.push(new TextPartilce(this.x, this.y-25, "-5hp"));
                            }
                        }
                    }else if(wall.type == "trigger"){
                        this.waypointShow = !this.waypointShow;
                    }else if(wall.type == "door"){

                    }else{ //"wall" or not specified (backwards compatibility)
                        // Calculate distances to each side
                        let leftDist = Math.abs(wallX2);
                        let rightDist = Math.abs(wallX2 + wallW2);
                        let topDist = Math.abs(wallY2);
                        let bottomDist = Math.abs(wallY2 + wallH2);

                        // Find the closest side
                        let minDist = Math.min(leftDist, rightDist, topDist, bottomDist);

                        if (minDist === leftDist) {
                            // Move player to the left side of the wall
                            tmpX = chunk.tileX * Chunk.CHUNK_SIZE + wall.x - collisionOffset;
                        } else if (minDist === rightDist) {
                            // Move player to the right side of the wall
                            tmpX = chunk.tileX * Chunk.CHUNK_SIZE + wall.x + wall.w + collisionOffset;
                        } else if (minDist === topDist) {
                            // Move player to the top side of the wall
                            tmpY = chunk.tileY * Chunk.CHUNK_SIZE + wall.y - collisionOffset;
                        } else if (minDist === bottomDist) {
                            // Move player to the bottom side of the wall
                            tmpY = chunk.tileY * Chunk.CHUNK_SIZE + wall.y + wall.h + collisionOffset;
                        }
                    }
                }
            }

            // let isPlayerInPortal = false;

            // for(let portal of chunk.data.portals){
            //     let portalX = (chunk.tileX * Chunk.CHUNK_SIZE) - tmpX + portal.x;
            //     let portalY = (chunk.tileY * Chunk.CHUNK_SIZE) - tmpY + portal.y;

            //     if(0 > portalX && 0 < portalX + portal.w && 0 > portalY && 0 < portalY + portal.h){
            //         isPlayerInPortal = true;
            //         if(!this.enteredPortal){
            //             tmpX = portal.targetX;
            //             tmpY = portal.targetY;
            //             console.log("Entered portal", portal.targetX, portal.targetY);
            //             console.log("Player position", this.x, this.y);
            //             gameMap.loadWorld(portal.world, portal.targetX, portal.targetY);
            //         }
            //     }
            // }
            // this.enteredPortal = isPlayerInPortal;
        }


        if(true){ //TODO, check if the player is colliding with obstacle
            this.x = tmpX;
            this.y = tmpY;

            gameMap.posX = this.x;
            gameMap.posY = this.y;
        }
    }

    render(objectsToDraw: ObjectToDraw[]){
        if(showDebug){
            buffer.noStroke();
            buffer.fill(255, 0, 0);
            buffer.ellipse(0-this.x, 0-this.y, 4, 4); // Draw center of the map

            buffer.stroke(255);
            buffer.line(-20, 0, 20, 0);
            buffer.line(0, -20, 0, 20);
        }

        buffer.noStroke();
        buffer.fill(0, 100);
        buffer.ellipse(0, 0, 50, 30);

        //this.isShooting = p5js.mouseIsPressed;

        if(this.isShooting){
            let angle = 0;
            if(this.isRunning){
                angle = this.currentDirection * p5js.PI/4 + p5js.PI/2; 
            }else{
                angle = p5js.atan2((p5js.mouseY/pixelSize)+75 - buffer.height/2, p5js.mouseX/pixelSize - buffer.width/2);
                this.currentDirection = p5js.int(p5js.map(angle + 0.3, 0, p5js.TWO_PI, 0, 8) + 6) % 8;
            }


            if(!this.isRunning){
                if(this.animShooting.playing){
                    this.animShooting.render(objectsToDraw, 0, 0, this.currentDirection);
                    this.animRunningAndShooting.frame = this.animShooting.frame;
                }else{
                    this.animShootingHold.render(objectsToDraw, 0, 0, this.currentDirection);
                }
            }else{
                if(this.animRunningAndShooting.playing){
                    this.animRunningAndShooting.render(objectsToDraw, 0, 0, this.currentDirection);
                    this.animShooting.frame = this.animRunningAndShooting.frame;
                }else{
                    this.animRunningAndShootingHold.render(objectsToDraw, 0, 0, this.currentDirection);
                }
            }
        }else{
            if(this.isRunning){
                this.animRunning.render(objectsToDraw, 0, 0, this.currentDirection);
            }else{
                this.animIdle.render(objectsToDraw, 0, 0, this.currentDirection);
            }
        }
    }

    renderUI(){

        buffer.fill(255);
        buffer.noStroke();
        buffer.textSize(20);
        buffer.textAlign(buffer.CENTER, buffer.TOP);
        buffer.text("Health: " + this.health, 0, -buffer.height/2+10);

        if(this.waypointShow){
            let xOnScreen = this.waypointX - this.x;
            let yOnScreen = this.waypointY - this.y;

            //check if it is on the screen render area
            if(xOnScreen > -buffer.width/2 && xOnScreen < buffer.width/2 && yOnScreen > -buffer.height/2 && yOnScreen < buffer.height/2){
                buffer.stroke(255);
                buffer.strokeWeight(2);
                buffer.noFill();
                buffer.ellipse(xOnScreen, yOnScreen, 10, 10);

                buffer.noStroke();
                buffer.fill(255);
                buffer.textSize(10);
                buffer.textAlign(buffer.CENTER, buffer.BOTTOM);
                buffer.text("Waypoint", xOnScreen, yOnScreen - 10);
            }else{
                // position for off-screen indicator
                let halfWidth = buffer.width / 2;
                let halfHeight = buffer.height / 2;
                let offset = 15;

                let xBoundary = halfWidth - offset;
                let yBoundary = halfHeight - offset;

                let intersectX, intersectY;

                // Check intersections with screen bounds with offset
                if (Math.abs(xOnScreen / yOnScreen) > Math.abs(xBoundary / yBoundary)) {
                    intersectX = xBoundary * Math.sign(xOnScreen);
                    intersectY = intersectX * yOnScreen / xOnScreen;
                } else {
                    intersectY = yBoundary * Math.sign(yOnScreen);
                    intersectX = intersectY * xOnScreen / yOnScreen;
                }

                buffer.stroke(255);
                buffer.strokeWeight(2);
                buffer.noFill();
                buffer.ellipse(intersectX, intersectY, 10, 10); // Display off-screen indicator
            }
        }

        // draw touch control indicator
        if(isMobile){
            buffer.noStroke();
            if(this.isTouching){
                buffer.fill(255, 100);
            }else{
            buffer.fill(255, 50);
            }
            buffer.ellipse(this.joystickX, this.joystickY, 100, 100);

            if(this.isTouching){
                const touch = p5js.touches[0] as any;
                let endPosX = touch.x/pixelSize-buffer.width/2;
                let endPosY = touch.y/pixelSize-buffer.height/2;

                let angle = Math.atan2(endPosY - this.joystickY, endPosX - this.joystickX);
                let distance = p5js.dist(this.joystickX, this.joystickY, endPosX, endPosY)/4;
                if(distance > 35){
                    distance = 35;
                }
                
                buffer.ellipse(this.joystickX + p5js.cos(angle) * distance, this.joystickY + p5js.sin(angle) * distance, 30, 30);
            }else{
                buffer.ellipse(this.joystickX, this.joystickY, 30, 30);
            }
        }

        if(this.damageEffectTime > p5js.millis()){ //damage effect
            let val = this.damageEffectTime - p5js.millis();
            if(val > 100){
                val = 100;
            }
            buffer.fill(255, 0, 0, val);
            buffer.noStroke();
            buffer.rect(-buffer.width/2, -buffer.height/2, buffer.width, buffer.height);
        }
    }

    getDebugText(){
        return "Player position: " + this.x + ", " + this.y + "\n";
    }

    startShooting(){
        this.isShooting = true;
        this.animShooting.start(0);
        this.animRunningAndShooting.start(0);
        this.shootHoldStartTime = p5js.millis();
    }

    endShooting(){
        if(this.isShooting === true){
            this.isShooting = false;
            if((p5js.millis() - this.shootHoldStartTime - 400)/25.0 > 0){
                let angle = p5js.atan2((p5js.mouseY/pixelSize)+75 - buffer.height/2, p5js.mouseX/pixelSize - buffer.width/2);
                entities.push(new Bullet(this.x, this.y-75, angle, 20));
            }
        }
    }

    keyPressed(){
        console.log("Key pressed");
        // Use WASD to move the player
        let lowerKey = p5js.key.toLowerCase();
        if(lowerKey == 'w'){
            this.keyW = true;
        }
        if(lowerKey == 'a'){
            this.keyA = true;
        }
        if(lowerKey == 's'){
            this.keyS = true;
        }
        if(lowerKey == 'd'){
            this.keyD = true;
        }
    }

    keyReleased(){
        console.log("Key released");
        // Use WASD to move the player
        let lowerKey = p5js.key.toLowerCase();
        if(lowerKey == 'w'){
            this.keyW = false;
        }
        if(lowerKey == 'a'){
            this.keyA = false;
        }
        if(lowerKey == 's'){
            this.keyS = false;
        }
        if(lowerKey == 'd'){
            this.keyD = false;
        }
    }

    mousePressed(){
        console.log("Mouse pressed");
        this.startShooting();
    }

    mouseReleased(){
        console.log("Mouse released");
        this.endShooting();
    }

    touchStarted(){
        console.log("Touch started");
        const touch = p5js.touches[0] as any;

        this.touchStartX = touch.x/pixelSize-buffer.width/2;
        this.touchStartY = touch.y/pixelSize-buffer.height/2;

        if(p5js.dist(this.touchStartX, this.touchStartY, this.joystickX, this.joystickY) < 50){
            this.isTouching = true;
            return;
        }

        this.startShooting();
    }

    touchEnded(){
        console.log("Touch ended");
        if(this.isTouching){
            player.goToDirection(-1);
            this.isTouching = false;
        }

        if(p5js.dist(this.touchStartX, this.touchStartY, this.joystickX, this.joystickY) < 50){
            //this.isTouching = true;
            return;
        }

        this.endShooting();
    }

    touchMoved(){
        // control the player with touch
        const touch = p5js.touches[0] as any;
        
        if(touch && this.isTouching){
            let endPosX = touch.x/pixelSize-buffer.width/2;
            let endPosY = touch.y/pixelSize-buffer.height/2;
            // calculate the angle between the start and current touch position
            const angle = Math.atan2(endPosY - this.joystickY, endPosX - this.joystickX);

            //determine the direction based on the angle (8 directions)
            let direction = p5js.int(p5js.map(angle + 0.3, 0, p5js.TWO_PI, 0, 8) + 6) % 8;

            //move the player
            player.goToDirection(direction);
        }
    }

    mouseWheel(event: any){

    }
}

export default Player;